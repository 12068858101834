import { Button, ButtonProps } from '@chakra-ui/react';
import * as React from 'react';

import { ButtonSpinner } from '../LoadingSpinner';

export interface IAlertButton extends ButtonProps {
  isLoading?: boolean;
}

export const AlertButton = ({ isLoading = false, children, ...props }: IAlertButton) => {
  return (
    <Button colorScheme={'orange'} {...props}>
      {isLoading ? <ButtonSpinner /> : <>{children}</>}
    </Button>
  );
};
