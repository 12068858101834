import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import useAccountNotificationMutation from 'hooks/private/mutations/useAccountNotificationMutation';
import { delay, noop } from 'lodash';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { getI18n, Trans, useTranslation } from 'react-i18next';

import { ButtonSpinner } from '../LoadingSpinner';

export interface IAlertDialog {
  onSubmit: (data: any) => void;
  onCancel?: MouseEventHandler;
  onClose?: () => void;
  isLoading?: boolean;
  closeOnOverlayClick?: boolean;
  accountRegistrationNotification?: boolean;
  notifyWhenReady?: boolean;
  accountBlockedDeletedInProgress: boolean;
  accountRejected: boolean;
  content?: {
    hide?: boolean;
    icon?: string | React.ReactElement;
    header?: string | React.ReactElement;
    footer?: {
      buttons: {
        hide?: boolean;
        cancelCaption?: string;
        actionCaption?: string;
      };
    };
  };
  styleConfig?: {
    variant?: string;
    minW?: string;
    maxW?: string;
    motionPreset?: any;
    w?: string;
  };
  modal?: any;
  children: any;
  childrenTransI18n?: any;
  isUsedInPolling?: boolean;
  preventRedirectionOnSubmit?: boolean;
  accountId?: string;
}

export const AlertScaffolding = (props: IAlertDialog) => {
  const {
    styleConfig = {},
    content = {},
    isLoading = false,
    onSubmit,
    onCancel,
    accountRegistrationNotification,
    notifyWhenReady,
    onClose: onModalClose,
    children,
    childrenTransI18n,
    closeOnOverlayClick = true,
    isUsedInPolling = false,
    modal,
    accountBlockedDeletedInProgress,
    accountRejected,
    preventRedirectionOnSubmit,
    accountId = '',
  } = props;
  const { t } = useTranslation();
  const { w = 'full', maxW = '50rem', minW, variant = false, motionPreset = 'slideInBottom' } = styleConfig;

  const { hide = false, icon = false, header = false, footer = { buttons: {} } } = content;

  const {
    buttons: { hide: hideButtons = false, cancelCaption = false, actionCaption = getI18n().t('common:submit') },
  } = footer;

  const [disableNotificationBttn, setDisableNotificationBttn] = useState(true);
  const [accountNotificationSuccess, setAccountNotificationSuccess] = useState(false);
  const [disableClick, setDisableClick] = useState(false);
  useEffect(() => {
    if (disableClick) {
      delay(() => {
        setDisableClick(false);
      }, 3000);
    }
  }, [disableClick]);

  const [errors, setErrors] = useState(false);

  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const onClose = () => {
    //sometimes only on modal close we want the user to be redirected
    if (onModalClose) {
      onModalClose();
    }
    if (!isUsedInPolling) {
      modal.hide();
      modal.remove();
    }
  };

  const { mutate } = useAccountNotificationMutation({ accountId });
  const accountNotificationRequestSubmission = () => {
    mutate(
      {},
      {
        onSuccess: () => {
          setAccountNotificationSuccess(true);
        },
      },
    );
  };

  return (
    <AlertDialog
      onClose={onClose}
      isOpen={modal.visible}
      leastDestructiveRef={cancelRef}
      closeOnOverlayClick={closeOnOverlayClick}
      motionPreset={motionPreset}
    >
      <AlertDialogOverlay sx={{ backdropFilter: 'blur(2rem)' }}>
        <AlertDialogContent data-test-id="alert-dialog" minW={minW} w={w} maxW={maxW}>
          {!hide ? (
            <AlertDialogHeader>
              <HStack>
                {icon}
                {header ? (
                  <Box px={3} fontWeight={'bold'} fontSize="md">
                    {header}
                  </Box>
                ) : null}
              </HStack>
            </AlertDialogHeader>
          ) : null}

          {closeOnOverlayClick && !isUsedInPolling ? <ModalCloseButton data-test-id="alert-dialog-button-close" /> : null}
          {!hide ? <Divider /> : null}
          <AlertDialogBody ml={'12px'}>
            {childrenTransI18n ? <Trans i18nKey={childrenTransI18n} /> : children}

            {accountRegistrationNotification && (
              <>
                {!notifyWhenReady && !accountNotificationSuccess && (
                  <>
                    <Text my={9}>{t('service_record:account-registration-notification.text')}</Text>

                    <FormControl>
                      <FormLabel display="flex" alignItems="center">
                        <Text display="inline" mr={3} fontSize={'lg'} color={'blue.500'}>
                          {t('service_record:account-registration-notification.label')}
                        </Text>
                        <Checkbox size="lg" onChange={() => setDisableNotificationBttn((prevState) => !prevState)} />
                      </FormLabel>
                    </FormControl>
                    <Grid templateColumns={`repeat(auto-fit, minmax(10rem, 15rem))`} justifyContent={'flex-end'} gap={3}>
                      <Button
                        disabled={disableNotificationBttn}
                        variant="primary"
                        data-test-id="alert-dialog-cancel-button"
                        my={6}
                        onClick={() => {
                          accountNotificationRequestSubmission();
                        }}
                      >
                        {t('common:save')}
                      </Button>
                    </Grid>
                  </>
                )}

                {!accountRejected && (accountBlockedDeletedInProgress || notifyWhenReady || accountNotificationSuccess) ? (
                  <Text my={9} color="green.500" fontSize={'lg'}>
                    {t('service_record:account-registration-notification.success-message')}
                  </Text>
                ) : null}
              </>
            )}
            <Grid>
              <Box fontSize={'xs'}>
                <Text fontSize="sm" color={'error'}>
                  {errors}
                </Text>
              </Box>
            </Grid>
          </AlertDialogBody>
          {!hideButtons && !hide ? (
            <AlertDialogFooter>
              <Grid templateColumns={`repeat(${cancelCaption ? '2' : '1'}, 1fr)`} gap={3}>
                {cancelCaption && cancelCaption === t('pages:subscription.manage.update.addons.keep') ? (
                  <Button
                    variant={'primary'}
                    data-test-id="alert-dialog-cancel-button"
                    ref={cancelRef}
                    onClick={(e) => {
                      onClose();
                      if (onCancel) {
                        onCancel(e);
                      }
                    }}
                    disabled={isLoading || disableClick}
                  >
                    {cancelCaption}
                  </Button>
                ) : cancelCaption && cancelCaption === t('workshop:registration.without_vat.modal.buttons.cancel') ? (
                  <Button
                    color="error"
                    borderColor="error"
                    border={'1px'}
                    variant="outline"
                    _hover={{ bgColor: 'error', color: 'white' }}
                    data-test-id="alert-dialog-cancel-button"
                    ref={cancelRef}
                    onClick={(e) => {
                      onClose();
                      if (onCancel) {
                        onCancel(e);
                      }
                    }}
                    disabled={isLoading || disableClick}
                  >
                    {cancelCaption}
                  </Button>
                ) : cancelCaption ? (
                  <Button
                    variant={variant ? `outline-${variant}` : 'outline'}
                    data-test-id="alert-dialog-cancel-button"
                    ref={cancelRef}
                    onClick={(e) => {
                      onClose();
                      if (onCancel) {
                        onCancel(e);
                      }
                    }}
                    disabled={isLoading || disableClick}
                  >
                    {cancelCaption}
                  </Button>
                ) : null}

                {actionCaption && actionCaption === t('pages:subscription.manage.update.addons.remove') ? (
                  <Button
                    color="error"
                    borderColor="error"
                    border={'1px'}
                    variant="variant"
                    _hover={{ bgColor: 'error', color: 'white' }}
                    data-test-id="alert-dialog-action-button"
                    onClick={(e) => {
                      setDisableClick(true);
                      Promise.resolve(onSubmit(e))
                        .catch((error) => {
                          setErrors(error);
                        })
                        .then(() => (!errors && preventRedirectionOnSubmit ? modal.close() : !errors ? onClose() : noop));
                    }}
                    disabled={isLoading || disableClick}
                  >
                    {actionCaption} {isLoading ? <ButtonSpinner /> : null}
                  </Button>
                ) : actionCaption ? (
                  <Button
                    variant={actionCaption === t('common:close') ? 'outline' : 'primary'}
                    data-test-id="alert-dialog-action-button"
                    onClick={(e) => {
                      if (actionCaption === t('common:close')) {
                        onClose();
                      } else {
                        setDisableClick(true);
                        Promise.resolve(onSubmit(e))
                          .catch((error) => {
                            setErrors(error);
                          })
                          .then(() => (!errors && preventRedirectionOnSubmit ? modal.hide() : !errors ? onClose() : noop));
                      }
                    }}
                    disabled={isLoading || disableClick}
                  >
                    {actionCaption} {isLoading ? <ButtonSpinner /> : null}
                  </Button>
                ) : null}
              </Grid>
            </AlertDialogFooter>
          ) : null}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default NiceModal.create((props: IAlertDialog) => {
  // Use a hook to manage the modal state
  const modal = useModal();
  return <AlertScaffolding {...props} modal={modal} />;
});
