import { Button, Flex, Icon, Text, useToast } from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import config from 'config';
import { useAxios } from 'context/AxiosContextProvider';
import { downloadFileFromUrl } from 'helpers/downloadFiles';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import useServiceHistoryQuery from 'hooks/queries/service/useServiceHistoryQuery';
import { get, isNil } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MdArrowDownward } from 'react-icons/md';
import { useQueryClient } from 'react-query';

import { PartialStateCode, ServiceActivity, StatusCodes } from '../../../../api/types';
import { ServiceHistoryRequestIcon } from '../../../../components/Icons';
import BaseServiceTypeTimelineElement from '../../../../components/StandardTimeline/BaseServiceTypeTimelineElement';
import { StatusReason } from '../../../../components/StatusReason';
import { StatusTag } from '../../../../components/StatusTag';
import { STATUS_COMPLETED } from '../../../../context/UserContextProvider';
import { dateTimeTransformer } from '../../../../helpers/dateTransformer';
import { getStatus } from '../../../../helpers/general';
import getColourSchemeBasedOnStatus from '../../../../helpers/getColourSchemeBasedOnStatus';
import useDeleteServiceHistoryRequestMutation from '../../../../hooks/private/mutations/useDeleteServiceHistoryRequestMutation';
import useVehicleQuery from '../../../../hooks/queries/useVehicleQuery';
import { WorkshopData } from '../../../../hooks/queries/workshop/useWorkshopQuery';
import CancelModal from '../CancelModal';

export const ServiceHistoryRequestActivityCard = (props: {
  vin: string;
  activity: ServiceActivity;
  noActionsAllowed: boolean;
  statusCodes: StatusCodes;
  isLoading: boolean;
  workshop: WorkshopData | undefined;
}) => {
  const { activity, statusCodes, workshop, vin, noActionsAllowed } = props;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toast = useToast();
  const axios = useAxios();
  const serviceHistoryRequestId = activity?.service_history_request?.id;

  const serviceHistoryQuery = useServiceHistoryQuery(serviceHistoryRequestId);
  const serviceHistoryData = serviceHistoryQuery.data?.data;
  const status = serviceHistoryData?.status;

  const deleteServiceHistoryRequestMutation = useDeleteServiceHistoryRequestMutation(serviceHistoryData?.id, vin);

  const handleCancel = () => {
    deleteServiceHistoryRequestMutation.mutate(null, {
      onSuccess: () => {
        queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
      },
    });
  };

  const isPayPerUse = workshop?.subscription?.plan?.is_pay_per_use;
  const codes: PartialStateCode<string> = get(statusCodes, 'service_history_request');
  const reasons: PartialStateCode<string> = get(statusCodes, 'service_history_request_reasons', {});

  const showCancel = !isNil(status) && [getStatus(codes, 'created'), getStatus(codes, 'submitted')].includes(status);

  const statusForMessage = get(codes, serviceHistoryData?.status);

  return (
    <>
      {serviceHistoryQuery?.isFetching ? (
        <Skeleton height="200px" />
      ) : (
        <BaseServiceTypeTimelineElement
          id={`service_history_request_${serviceHistoryData?.id}`}
          dataTestId={'service_history_request_timeline_element'}
          date={dateTimeTransformer(activity?.date)}
          title={`${t('components:service_process_card.service_history_request.label')}, ${serviceHistoryData?.user_readable_id}`}
          statusReason={
            <StatusReason
              statusCodes={codes}
              reasons={statusCodes['service_history_request_reasons']}
              resourceKey={'service_history_request'}
              currentStatus={serviceHistoryData?.status}
              currentStatusReason={serviceHistoryData?.status_reason}
            />
          }
          status={
            <StatusTag
              statusCodes={codes}
              reasons={reasons}
              resourceKey={'service_history_request'}
              currentStatus={serviceHistoryData?.status}
              hideDescription={true}
            />
          }
          iconBgColor={getColourSchemeBasedOnStatus(get(codes, status))}
          icon={<Icon as={ServiceHistoryRequestIcon} />}
        >
          <Text fontSize="sm">{statusForMessage ? t(`status:service_history_request.${statusForMessage}.timeline.message`) : null}</Text>
          <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }} gap={4}>
            {status === STATUS_COMPLETED && (
              <Button
                leftIcon={<MdArrowDownward />}
                variant="primary"
                size="sm"
                ml={2}
                onClick={() =>
                  downloadFileFromUrl(
                    axios,
                    `${config.apiBaseUrl}/workshop/service-history-requests/${serviceHistoryData?.id}/history-download`,
                    'Service History.pdf',
                  )
                }
              >
                {t('common:download')}
              </Button>
            )}
            {showCancel && !noActionsAllowed && !isPayPerUse && <CancelModal activity={activity} handleCancel={handleCancel} />}
          </Flex>
        </BaseServiceTypeTimelineElement>
      )}
    </>
  );
};
