import { Button } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { find, get } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ADDON_KEYS, SubscriptionAddon } from '../../api/types';
import AlertModal from '../../components/modals/AlertModal';
import getAddonModalData from '../../components/modals/GetAddonModalData';
import { Subscription } from '../../hooks/queries/workshop/useWorkshopQuery';

export function ConvertModal(props: {
  subscription: Subscription;
  handleModal: () => void;
  addons: SubscriptionAddon[];
  costControlDisabled?: boolean;
}) {
  const { t } = useTranslation();
  const { subscription, addons = [], handleModal, costControlDisabled = false } = props;

  const activeAddons = get(subscription, 'active_addons', {});
  const hasDSBAddon = get(activeAddons, ADDON_KEYS.PLUS_DSB, false);

  let modalData = getAddonModalData({
    addon: find(addons, { id: ADDON_KEYS.PLUS_DSB }),
    activeAddon: get(subscription?.active_addons, ADDON_KEYS.PLUS_DSB),
    subscription,
    costControlDisabled,
    isTrial: true,
    displaySpecific: true,
  });

  if (hasDSBAddon) {
    modalData = {
      modalComponent: AlertModal,
      onSubmit: handleModal,
      content: {
        header: t('pages:service_record.detail.convert.modal.header'),
        footer: {
          // @ts-ignore
          buttons: {
            cancelCaption: t('common:abort'),
            actionCaption: t('common:convert'),
          },
        },
      },
      children: t('pages:service_record.detail.convert.modal.description'),
    };
  }

  return (
    <>
      <Button
        ml={2}
        variant="primary"
        onClick={() => NiceModal.show(modalData?.modalComponent, modalData)}
        data-test-id="service-record-convert-button"
      >
        {t('pages:service_record.detail.convert.action')}
      </Button>
    </>
  );
}

export default ConvertModal;
