import { Box, Button, Checkbox, Flex, Icon, Text, Textarea, useBreakpointValue } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import useFeedbackMutation from 'hooks/private/mutations/useFeedbackMutation';
import useUpdateWorkshopBaseDataMutation from 'hooks/private/mutations/useUpdateWorkshopBaseDataMutation';
import { ContentCard } from 'layout/ContentCard';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { BsLightbulb } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const FeedbackPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const updateWorkshopBaseDataMutation = useUpdateWorkshopBaseDataMutation();
  const feedbackMutation = useFeedbackMutation();

  const schema = yup.object().shape({
    message: yup.string().trim().required(t('pages:feedback.required_text')).min(1).max(5000, t('pages:feedback.text_max_length')),
    consent_contact: yup.boolean(),
  });
  const {
    handleSubmit,
    formState: { errors, submitCount },
    control,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: { consent_contact: false },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<any> = (data) => {
    feedbackMutation.mutate(data, {
      onSuccess: () => {
        navigate('/app/w/dashboard');
      },
    });
  };

  const consentWatch = watch('consent_contact');
  // @ts-ignore
  const messageWatch = watch('message');

  const handleCheckboxChange = (value: boolean) => {
    setValue('consent_contact', !value);
  };

  const isSubmitDisabled = !messageWatch && submitCount > 0;

  return (
    <>
      <ContentCard header={t('pages:feedback.title')} icon={<Icon as={BsLightbulb} boxSize={'8'} />}>
        <Box px={10} as="form" onSubmit={handleSubmit(onSubmit)}>
          {/* @ts-ignore */}
          <Text mb={6}>
            <Trans i18nKey="pages:feedback.description" />
          </Text>
          <Box mt={4}>
            <Controller
              // @ts-ignore
              name="message"
              control={control}
              render={({ field }) => (
                // @ts-ignore
                <Textarea
                  minHeight={'60'}
                  size="xl"
                  {...field}
                  paddingLeft={3}
                  placeholder={t('pages:feedback.placeholder')}
                  _placeholder={{ opacity: 0.5 }}
                />
              )}
            />
            {/* @ts-ignore */}
            {errors.message && <Text color="red.500">{errors.message.message}</Text>}

            <Controller
              name="consent_contact"
              control={control}
              render={({ field }) => (
                <Flex align="center" mt={6}>
                  {/* @ts-ignore */}
                  <Checkbox {...field} onChange={() => handleCheckboxChange(consentWatch)} size="xl">
                    <Text pl={3} fontSize={'sm'}>
                      {t('pages:feedback.checkbox')}
                    </Text>
                  </Checkbox>
                </Flex>
              )}
            />
          </Box>

          <Flex justifyContent="flex-end" p={4}>
            <Button
              px={8}
              variant={'primary'}
              disabled={updateWorkshopBaseDataMutation.isLoading || isSubmitDisabled}
              isLoading={updateWorkshopBaseDataMutation.isLoading}
              width={useBreakpointValue({ base: '100%', md: 'inherit' })}
              type="submit"
            >
              {t('pages:feedback.submit')}
            </Button>
          </Flex>
        </Box>
      </ContentCard>
    </>
  );
};

export default FeedbackPage;
