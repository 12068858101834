import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import config from 'config';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { TourMetaData } from '../../../api/types';
import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';
import useWorkshopUserQuery from '../../queries/workshop/useWorkshopUserQuery';

async function update(axios: AxiosInstance, tourMetaData: TourMetaData | null): Promise<object> {
  const { data } = await axios.put(`${config.apiBaseUrl}/workshop/user`, tourMetaData);
  return data;
}

export default function useUpdateUserTourMetaDataMutation() {
  const queryClient = useQueryClient();
  const axios = useAxios();
  const toast = useToast();
  const t = useTranslation();

  return useMutation((tourMetaData: TourMetaData | null) => update(axios, tourMetaData), {
    onSuccess: () => {
      return queryClient.invalidateQueries(useWorkshopUserQuery.getKey());
    },
    onError: (err: any) => {
      manageErrorResponse({ toastInstance: toast, error: err.response, t });
    },
  });
}
