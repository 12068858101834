import { useIsWorkshopBlocked } from 'helpers/general';
import PageMetatags from 'layout/PageMetatags';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { LoadingSpinner } from '../../components/LoadingSpinner';
import { useUserContext } from '../../context/UserContextProvider';
import useVehicleQuery from '../../hooks/queries/useVehicleQuery';
import NeedsVerificationModal from './NeedsVerificationModal';
import ServiceRecordForm from './ServiceRecordForm';

const CreateServiceRecordPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const userContext = useUserContext();
  const urlParams = new URLSearchParams(window.location.search);
  const vin = urlParams.get('vin') || '';

  useEffect(() => {
    if (vin === '' || vin === 'undefined') {
      throw new Error(t('errors:invalid_url'));
    }
  }, [vin]);

  const vehicleQuery = useVehicleQuery(vin);
  const vehicleData = vehicleQuery.data?.data;
  // @ts-ignore

  let initialValues = {
    date: false,
    mileage: undefined,
    vehicle_id: vehicleData?.id,
    workshop_reference: undefined,
    consent: false,
    vin: vehicleData?.vin,
    registration_date: vehicleData?.initial_registration_date,
    max_age_in_days: vehicleData?.max_age_in_days,
    additional_notes: '',
  };
  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }

  if (vehicleQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <PageMetatags title={`${t('pages:service_record.form.label')}`} />
      <NeedsVerificationModal vehicle={vehicleData} workshop={userContext.workshop} />
      <ServiceRecordForm
        title={t('pages:service_record.form.label')}
        initialValues={{ ...initialValues }}
        // @ts-ignore
        formVersionId={vehicleData?.form_version?.id}
        // @ts-ignore
        vehicle={vehicleData}
        apiEndpointUrl={'/workshop/service-records'}
        navigateUrl={'/app/w/vehicle?vin='}
      />
    </>
  );
};

export default CreateServiceRecordPage;
