import { Icon, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ServiceActivity } from '../../../../api/types';
import { SendDocuments } from '../../../../components/Icons';
import BaseServiceTypeTimelineElement from '../../../../components/StandardTimeline/BaseServiceTypeTimelineElement';
import { dateTimeTransformer } from '../../../../helpers/dateTransformer';
import getColourSchemeBasedOnStatus from '../../../../helpers/getColourSchemeBasedOnStatus';

export const SendVehicleDocumentsActivityCard = (props: {
  isLoading: boolean;
  activity: ServiceActivity;
}) => {
  const { isLoading, activity } = props;
  const { t } = useTranslation();

  return isLoading ? (
    <Skeleton height="200px" />
  ) : (
    <BaseServiceTypeTimelineElement
      id={`sent_documents_${activity.id}`}
      dataTestId="sent_documents_timeline_element"
      date={dateTimeTransformer(activity?.date)}
      title={t('components:service_process_card.sent_documents.label')}
      iconBgColor={getColourSchemeBasedOnStatus('completed')}
      icon={<Icon as={SendDocuments} />}
    >
      <Text as="div" fontSize="sm">
        {t(`components:service_process_card.sent_documents.timeline.message`, {
          email: activity?.meta_data?.email ?? '',
        })}
        <UnorderedList spacing={2} textAlign="left">
          {activity?.meta_data?.documents?.map((document, index) => {
            return (
              <ListItem key={index}>
                <Text fontSize="sm">{document.name}</Text>
              </ListItem>
            );
          })}
        </UnorderedList>
      </Text>
    </BaseServiceTypeTimelineElement>
  );
};
