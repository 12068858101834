import { Button } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ServiceActivity } from '../../../api/types';
import AlertModal from '../../../components/modals/AlertModal';

const CancelModal = (props: {
  activity?: ServiceActivity;
  type?: string;
  handleCancel(x: any): void;
}) => {
  const { t } = useTranslation();
  const { handleCancel } = props;

  return (
    <>
      <Button
        color="error"
        borderColor="error"
        variant="outline"
        _hover={{
          bg: 'error',
          color: 'white',
        }}
        _active={{
          bg: 'error',
          color: 'white',
        }}
        onClick={() =>
          NiceModal.show(AlertModal, {
            onSubmit: () => handleCancel(),
            children: t('pages:service_activity.cancel.modal.description'),
            content: {
              header: t('pages:service_activity.cancel.modal.header'),
              footer: {
                buttons: {
                  cancelCaption: t('common:abort'),
                  actionCaption: t('common:cancel_order'),
                },
              },
            },
          })
        }
      >
        {t('common:cancel_order')}
      </Button>
    </>
  );
};

export default CancelModal;
