import { useToast } from '@chakra-ui/react';
import { useAxios } from 'context/AxiosContextProvider';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import config from '../../../config';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';
import useWorkshopQuery from '../../queries/workshop/useWorkshopQuery';

export default function useDeleteWorkshopUpdateRequestMutation(updateRequestId: number) {
  const axios = useAxios();
  const t = useTranslation();
  const queryClient = useQueryClient();
  const toast = useToast;

  return useMutation({
    mutationFn: () => {
      return axios.delete(`${config.apiBaseUrl}/workshop/update-requests/${updateRequestId}`);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(useWorkshopQuery.getKey());
    },
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}
