import { Divider, Stack, useBreakpointValue, VStack } from '@chakra-ui/react';
import * as React from 'react';

import { cardColors } from './displayHelper';
import { ServiceStepCircle } from './ServiceStepCircle';

export const ServiceStep = (props: any) => {
  const {
    step,
    subContent,
    color,
    isActive,
    isCompleted,
    isCanceled,
    isInClarification,
    isProcessing,
    isRequestedDeletion,
    isLastStep,
    isFirstStep,
  } = props;

  const orientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'horizontal',
    md: 'horizontal',
  });

  return (
    <VStack w="full" m={'0 !important'}>
      <Stack spacing="0" align="center" direction="row" w={'full'}>
        <Divider
          orientation={orientation}
          borderWidth=".2rem"
          backgroundColor={isFirstStep ? 'transparent' : cardColors.inactive}
          borderColor={isFirstStep ? 'transparent' : cardColors.inactive}
        />
        <ServiceStepCircle
          isActive={isActive}
          isProcessing={isProcessing}
          isCanceled={isCanceled}
          isInClarification={isInClarification}
          isRequestedDeletion={isRequestedDeletion}
          color={color}
          isCompleted={isCompleted}
          caption={step}
        />
        <Divider
          orientation={orientation}
          borderWidth=".2rem"
          backgroundColor={isLastStep ? 'transparent' : cardColors.inactive}
          borderColor={isLastStep ? 'transparent' : cardColors.inactive}
        />
      </Stack>
      <VStack>{subContent}</VStack>
    </VStack>
  );
};
