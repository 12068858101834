import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { getI18n } from 'react-i18next';

import Testimonials, { ITestimonial } from './Testimonials';
import TestimonialsCarousel from './TestimonialsCarousel';
export function TestimonialsOverviewCard(props: {
  testimonials: ITestimonial[];
  abTestNewDesign?: boolean;
}) {
  const { testimonials, abTestNewDesign = false } = props;
  return (
    <Box mt={35} mb={10}>
      <TestimonialsCarousel title={getI18n().t(`components:testimonials.headline`)}>
        {testimonials.map((testimonial, index) => (
          <Testimonials
            testimonial={testimonial}
            key={index}
            abTestNewDesign={abTestNewDesign}
          />
        ))}
      </TestimonialsCarousel>
    </Box>
  );
}
export default TestimonialsOverviewCard;
