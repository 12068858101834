import { useAuth0 } from '@auth0/auth0-react';
import { AxiosInstance } from 'axios';
import axios from 'axios';
import useTokenQuery from 'hooks/queries/useTokenQuery';
import React, { useContext, useMemo } from 'react';

import { LoadingSpinner } from '../components/LoadingSpinner';
import config from '../config';

type ProviderValue = AxiosInstance;
type DefaultValue = AxiosInstance;
type ContextValue = DefaultValue | ProviderValue;

export const AxiosContext = React.createContext<ContextValue>(
  // eslint-disable-next-line no-undef
  new Proxy(axios.create(), {
    apply: () => {
      throw new Error(
        'You are trying to use the authenticated http client outside of workshop context',
      );
    },
    get: () => {
      throw new Error(
        'You are trying to use the authenticated http client outside of workshop contextT',
      );
    },
  }),
);

const AxiosContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const tokenQuery = useTokenQuery();
  const { logout } = useAuth0();

  const httpClient = useMemo(() => {
    const instance = axios.create({
      baseURL: config.apiBaseUrl,
    });

    instance.defaults.headers.common['Authorization'] = `Bearer ${tokenQuery.data}`;
    instance.defaults.headers.common['Accept'] = 'application/json';
    instance.defaults.headers.post['Content-Type'] = 'application/json';
    instance.defaults.headers.put['Content-Type'] = 'application/json';

    return instance;
  }, [tokenQuery.data]);

  if (tokenQuery.isLoading) {
    return <LoadingSpinner />;
  }

  if (tokenQuery.isError) {
    logout({ returnTo: config.baseUrl.toString() });
  }

  return <AxiosContext.Provider value={httpClient} {...props} />;
};

function useAxios() {
  return useContext(AxiosContext);
}

export { AxiosContextProvider, useAxios };
