import { Box, Button, Divider, Grid, GridItem, HStack, Icon, Select, Stack } from '@chakra-ui/react';
import LogoutButton from 'components/LogoutButton';
import { useIsWorkshopBlocked } from 'helpers/general';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillQuestionCircleFill, BsLightbulb } from 'react-icons/bs';
import { FaRegAddressCard } from 'react-icons/fa';
import { FiGlobe } from 'react-icons/fi';
import { MdCreditCard, MdList, MdManageAccounts, MdManageSearch } from 'react-icons/md';
import { NavLink, useLocation } from 'react-router-dom';

import Snowfall from 'react-snowfall';
import { highlightVinInput } from '../../animations/specials';
import OeLogo from '../../components/OeLogo';
import localConfig from '../../config';
import { useUserContext } from '../../context/UserContextProvider';
import { Environments, isTestingEnvironment } from '../../helpers/environments';
import { NavButton } from './NavButton';
import { NavSectionTitle } from './NavSectionTitle';
import { UserProfile } from './UserProfile';

const sectionSpacings = {
  base: 2,
  lg: 8,
};

const linkSpacings = {
  base: 0,
  lg: 3,
};

export const Sidebar = () => {
  const { t, i18n } = useTranslation();
  const { workshop, user, config } = useUserContext();
  const location = useLocation();
  const isWorkshopBlocked = useIsWorkshopBlocked();
  const [nonProductionLangSelect, setNonProductionLangSelect] = useState<string>('');

  const languageSelectHandler = (lang: string) => i18n.changeLanguage(lang.toLowerCase());
  const languagesWithDuplicates = config?.languages ? Object.values(config?.languages)?.flat() : [];
  const dropdownLanguages = [...new Set(languagesWithDuplicates)];

  const [ showSnow, setShowSnow ] = useState<boolean>(true);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setShowSnow(false)
    } else {
      setShowSnow(true)
    }
  }, [window.innerWidth]);

  return (
    <Grid
      bg="accent"
      color="on-accent"
      templateAreas={`"nav-logo"
                  "nav-main"
                  "nav-footer"`}
      templateRows={'4rem 1fr auto'}
      h={'full'}
      maxW={{ base: 'full', sm: 'full' }}
      overflow={'auto'}
      gap={{ base: '6', sm: '8' }}
      py={{ base: '6', sm: '8' }}
      px={{ base: '4', sm: '6' }}
      overflowX={'hidden'}
      pointerEvents={isWorkshopBlocked ? 'none' : 'inherit'}
    >
      { showSnow ? <Snowfall 
        snowflakeCount={50}
        color="white"
        style={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
        }}
        /> : null}
      <GridItem area={'nav-logo'}>
        <NavLink to={'/app/w/dashboard'}>
          <OeLogo maxWidth="80%" />
        </NavLink>
      </GridItem>
      <GridItem area={'nav-main'}>
        <Stack w={'full'} spacing={sectionSpacings}>
          <Stack>
            <NavSectionTitle label={t('sidebar:general.title')} mb={'2'} />
            <Box paddingInlineStart={linkSpacings}>
              <NavButton
                to={'/app/w/dashboard'}
                label={t('sidebar:general.dashboard')}
                icon={MdManageSearch}
                onClick={async (event) => {
                  if (location.pathname === '/app/w/dashboard') {
                    event.preventDefault();
                    await highlightVinInput().catch((error) => console.log(error));
                  }
                  return;
                }}
              />
              <NavButton
                label={t('sidebar:general.serviceActivities')}
                to="/app/w/service-activities"
                className={'tour-service-activity'}
                icon={MdList}
              />
            </Box>
          </Stack>
          <Stack spacing="1">
            <NavSectionTitle label={t('sidebar:account.title')} />
            <Box paddingInlineStart={linkSpacings}>
              {!workshop?.disable_cost_control ? (
                <NavButton
                  label={t('sidebar:account.subscription')}
                  icon={MdManageAccounts}
                  className={'tour-manage-subscription'}
                  to={'/app/w/account/subscription'}
                />
              ) : null}

              {!workshop?.disable_cost_control ? (
                <NavButton label={t('sidebar:account.costs')} icon={MdCreditCard} to={'/app/w/account/subscription/cost-overview'} />
              ) : null}

              <NavButton label={t('sidebar:account.update')} icon={FaRegAddressCard} to={'/app/w/account/data'} />
            </Box>
          </Stack>
        </Stack>
      </GridItem>
      <GridItem area={'nav-footer'}>
        <Grid gap="4">
          <NavButton
            to="/app/w/support"
            className={'hint-help tour-support'}
            label={t('sidebar:support')}
            icon={BsFillQuestionCircleFill}
            marginBlockStart={'0 !important'}
          />
          <div style={{ marginTop: -10 }}>
            <NavButton to="/app/w/feedback" label={t('sidebar:feedback')} icon={BsLightbulb} marginBlockStart={'0 !important'} />
          </div>
          <Divider borderColor="on-accent-subtle" />
          <UserProfile name={workshop?.user_readable_id} email={user?.email} />
          <Stack spacing={2} paddingInlineStart={linkSpacings}>
            {(isTestingEnvironment() && localConfig.appEnv !== Environments.STAGE && localConfig.appEnv !== Environments.QA) ||
            user?.type === 'support' ? (
              <HStack>
                <Icon as={FiGlobe} color={'white'} boxSize="5" />
                <Select
                  border={'none'}
                  data-test-id="table-show-items"
                  onChange={(evt) => {
                    languageSelectHandler(evt.target.value);
                    setNonProductionLangSelect(evt.target.value?.toLowerCase() ?? '');
                  }}
                >
                  {dropdownLanguages.map((lang: string) => (
                    <option key={lang} value={lang}>
                      {t(`location:languages.${lang.toUpperCase()}`)}
                    </option>
                  ))}
                </Select>
                <Button
                  variant="link"
                  color={i18n.language === 'cimode' ? 'black' : 'white'}
                  border={'white'}
                  onClick={() => {
                    if (i18n.language !== 'cimode') {
                      setNonProductionLangSelect(i18n.language);
                      i18n.changeLanguage('cimode');
                    } else {
                      languageSelectHandler(nonProductionLangSelect);
                    }
                  }}
                >
                  i18n
                </Button>
              </HStack>
            ) : null}
            <LogoutButton />
          </Stack>
        </Grid>
      </GridItem>
    </Grid>
  );
};
