import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Config, Results } from '../../api/types';
import config from '../../config';
import { DURATION } from '../../constants';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';

async function getConfig(token?: string): Promise<Results<Config>> {
  const url = token ? '/workshop/config' : '/utilities/config';

  const options = token
    ? {
        headers: { Authorization: `Bearer ${token}` },
      }
    : {};

  const { data } = await axios.get(config.apiBaseUrl + url, options);

  return data;
}

export default function useConfigQuery(token?: string) {
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return useQuery(getKey(), () => getConfig(token), {
    enabled: true,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    refetchOnWindowFocus: false,
    cacheTime: DURATION.Hour * 5,
    staleTime: DURATION.Hour,
    onError: (error: any) => {
      if (error?.response?.status !== 503) {
        manageErrorResponse({ toastInstance: toast, error: JSON.stringify(error), t });
      }
      if (error?.response?.status >= 500 && error?.response?.status !== 503) {
        navigate('/app/error', { replace: true });
      }
    },
  });
}

function getKey(): QueryKey {
  return ['Config'];
}

useConfigQuery.getKey = getKey;
