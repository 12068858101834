import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import config from 'config';
import { queryClient } from 'configs/queryClient';
import useVehicleQuery from 'hooks/queries/useVehicleQuery';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

export interface IInspectionForm {
  mileage: number;
  maintenance_system_id: number;
  maintenance_period_ids: Array<number>;
  maintenance_data_strings: string;
  detailed?: boolean;
}

async function store(axios: AxiosInstance, vin: string, formData: IInspectionForm): Promise<object> {
  const { data } = await axios.post(`${config.apiBaseUrl}/workshop/vehicles/${vin}/inspection-plans`, { ...formData });
  return data;
}

export default function useStoreInspectionPlanMutation(vin: string) {
  const axios = useAxios();
  const toast = useToast();
  const t = useTranslation();

  return useMutation((formData: IInspectionForm) => store(axios, vin, formData), {
    onSuccess: () => {
      queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
    },
    onError: (err: any) => {
      manageErrorResponse({ toastInstance: toast, error: err.response, t });
    },
  });
}
