import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import config from '../../../config';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';
import useTokenQuery from '../../queries/useTokenQuery';

async function update(token: any, accountId: string): Promise<object> {
  return await axios.put(
    `${config.apiBaseUrl}/workshop/accounts/${accountId}/enable-notification`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
}

export default function useAccountNotificationMutation({
  accountId,
}: {
  accountId: string;
}) {
  const queryClient = useQueryClient();
  const t = useTranslation();
  const toast = useToast();
  const token = useTokenQuery().data;

  return useMutation(() => update(token, accountId), {
    onSuccess: () => queryClient.invalidateQueries(['Vehicle']),
    onError: (err: any) => {
      if (err.response.data.message === 'no changes detected') {
        manageErrorResponse({
          toastInstance: toast,
          error: err.response,
          // @ts-ignore
          customMessage: t('errors:no_changes'),
          t,
        });
      } else {
        manageErrorResponse({ toastInstance: toast, error: err.response, t });
      }
      return Promise.reject(err);
    },
  });
}
