import PageMetatags from 'layout/PageMetatags';
import { PublicPageLayout } from 'layout/PublicPageLayout';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import VerificationForm from './VerificationForm';

export const VerificationPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <PublicPageLayout>
      <PageMetatags title={t('pages:workshop_verification_request.label')} />
      <VerificationForm showSkip={true} skipUrl={'/app/w/dashboard'} />
    </PublicPageLayout>
  );
};

export default VerificationPage;
