import { Button, Flex, Icon, ListItem, Text, Tooltip, UnorderedList } from '@chakra-ui/react';
import config from 'config';
import { useAxios } from 'context/AxiosContextProvider';
import { downloadFileFromUrl } from 'helpers/downloadFiles';
import { Period } from 'hooks/queries/service/useMaintenanceSystemQuery';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BsCardChecklist } from 'react-icons/bs';
import { MdArrowDownward } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { ServiceActivity } from '../../../../api/types';
import { Skeleton } from '../../../../components/Skeleton';
import BaseServiceTypeTimelineElement from '../../../../components/StandardTimeline/BaseServiceTypeTimelineElement';
import { useUserContext } from '../../../../context/UserContextProvider';
import { dateTimeTransformer } from '../../../../helpers/dateTransformer';
import getColourSchemeBasedOnStatus from '../../../../helpers/getColourSchemeBasedOnStatus';
import useShowInspectionPlanQuery from '../../../../hooks/queries/inspection_plan/useShowInspectionPlanQuery';

export const InspectionPlanActivityCard = (props: {
  activity: ServiceActivity;
  isLoading: boolean;
  noActionsAllowed: boolean;
  isServiceCompleted;
}) => {
  const { t } = useTranslation();
  const { activity, isLoading, isServiceCompleted } = props;
  const axios = useAxios();
  const urlParams = new URLSearchParams(window.location.search);
  const vin = urlParams.get('vin') || '';
  const navigate = useNavigate();
  const inspectionPlanId = activity?.inspection_plan?.id;

  const inspectionPlanQuery = useShowInspectionPlanQuery(vin, inspectionPlanId);
  const inspectionPlanData = inspectionPlanQuery?.data?.data;

  const downloadType = inspectionPlanQuery?.data?.data?.data ? 'prefilled-download' : 'blank-download';
  const isLegacyInspectioPlan = activity?.inspection_plan?.status === 999;

  const maintenanceSystemId = inspectionPlanData?.maintenance_system_id;

  const maintenanceDataPeriods =
    // @ts-ignore
    inspectionPlanData?.maintenance_data_strings[maintenanceSystemId]?.periods;
  const userContext = useUserContext();
  const units = userContext.workshop?.units;

  return isLoading ? (
    <Skeleton height="200px" />
  ) : (
    <BaseServiceTypeTimelineElement
      id={`inspection_plan-${inspectionPlanData?.id}`}
      dataTestId={'inspection_plan_timeline_element'}
      date={dateTimeTransformer(activity?.date)}
      title={t('components:service_process_card.inspection_plan.label')}
      iconBgColor={getColourSchemeBasedOnStatus('completed')}
      icon={<Icon as={BsCardChecklist} />}
    >
      <Text fontSize="sm">
        <strong>{t('forms:mileage.label')}:</strong> {`${inspectionPlanData?.mileage} ${units === 'metric' ? 'km' : 'mi'}`}
      </Text>

      <Text as="div" fontSize="sm">
        {t(`components:service_process_card.inspection_plan.timeline.message`)}
        <UnorderedList spacing={2} textAlign="left">
          {maintenanceDataPeriods?.map((period: Period, index: number) => {
            return (
              <ListItem key={index}>
                <Text fontSize="sm">{Object.values(period)?.[0]?.name}</Text>
              </ListItem>
            );
          })}
        </UnorderedList>
      </Text>
      <Flex py="4" px={{ base: '4', md: '6' }} gap={4} justify="flex-end">
        <Tooltip label={t('forms:ip.legacy_ip_tooltip')} isDisabled={!isLegacyInspectioPlan} placement={'left'} shouldWrapChildren>
          <Button
            isDisabled={isLegacyInspectioPlan}
            variant="outline"
            colorScheme="blue.500"
            size="md"
            ml={2}
            onClick={() => {
              navigate(`/app/w/inspection-plan/${inspectionPlanId}?vin=${vin}&isServiceCompleted=${isServiceCompleted}`);
            }}
          >
            {t('common:details')}
          </Button>
        </Tooltip>

        <Button
          leftIcon={<MdArrowDownward />}
          variant="primary"
          size="md"
          ml={2}
          data-test-id={`inspection_plan_${inspectionPlanId}_timeline_download_button`}
          onClick={() =>
            downloadFileFromUrl(
              axios,
              `${config.apiBaseUrl}/workshop/vehicles/${vin}/inspection-plans/${inspectionPlanId}/${downloadType}`,
              'Inspection Plan.pdf',
            )
          }
        >
          {t('common:download')}
        </Button>
      </Flex>
    </BaseServiceTypeTimelineElement>
  );
};
