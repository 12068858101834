import { useToast } from '@chakra-ui/react';
import { useAxios } from 'context/AxiosContextProvider';
import useVehicleQuery from 'hooks/queries/useVehicleQuery';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import config from '../../../config';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

export default function useDeleteServiceHistoryRequestMutation(
  serviceHistoryRequestId: number,
  vin: string,
) {
  const axios = useAxios();
  const t = useTranslation();
  const toast = useToast;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return axios.delete(
        `${config.apiBaseUrl}/workshop/service-history-requests/${serviceHistoryRequestId}`,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
    },
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}
