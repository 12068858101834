import { useToast } from '@chakra-ui/react';
import { useAxios } from 'context/AxiosContextProvider';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import config from '../../../config';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

export default function useDestroyInspectionPlanMutation() {
  const axios = useAxios();
  const t = useTranslation();
  const toast = useToast();

  return useMutation({
    mutationFn: ({ vin, id }: { vin: string; id: string }) => {
      return axios.delete(
        `${config.apiBaseUrl}/workshop/vehicles/${vin}/inspection-plans/${id}`,
      );
    },
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}
