import { Button, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useUserContext } from '../context/UserContextProvider';
import { FONT_WEIGHTS } from '../global/Fonts';
import { Card } from '../layout/Card';
import { ExternalLink } from '../layout/Link';
import { AlexHandTel } from './illustrations';

export function GetInTouchCard(props: { abTestNewDesign?: boolean }) {
  const { abTestNewDesign = false } = props;
  const userContext = useUserContext();
  const { t } = useTranslation();
  return (
    <Card
      p="8"
      textAlign={'left'}
      width={'full'}
      bgColor={abTestNewDesign ? 'white' : 'gray.200'}
    >
      <Flex align="center" direction={{ base: 'column', md: 'row' }}>
        <Stack w="full" align="center" direction="row" spacing="8">
          <AlexHandTel
            w={{ base: '6rem', md: '8rem' }}
            h={{ base: '6rem', md: '8rem' }}
          />
          <Stack spacing={4} maxW="80ch">
            <Heading
              as={'h2'}
              size="sm"
              fontWeight={FONT_WEIGHTS.bold}
              style={{ userSelect: 'none' }}
            >
              {t('components:get_in_touch_card.title')}
            </Heading>
            <Text as="section" fontSize={'lg'}>
              <p style={{ userSelect: 'none', display: 'inline' }}>
                {t('components:get_in_touch_card.contact_us_by_call')}{' '}
              </p>
              <ExternalLink
                isExternal
                href={`tel:${userContext.config?.support_telephone}`}
              >
                {userContext.config?.support_telephone}
              </ExternalLink>{' '}
              <p style={{ userSelect: 'none', display: 'inline' }}>
                {' '}
                {t('components:get_in_touch_card.or_send_email')}{' '}
              </p>
              <ExternalLink
                isExternal
                href={`mailto:${userContext.config?.support_email}`}
              >
                {userContext.config?.support_email}
              </ExternalLink>
            </Text>
          </Stack>
        </Stack>
        <Stack spacing="4">
          <ExternalLink isExternal href={`tel:${userContext.config?.support_telephone}`}>
            <Button
              variant={'primary'}
              size="lg"
              mt={{ base: '6', md: '0' }}
              w={{ base: 'full', md: 'auto' }}
              minW="11rem"
              flexShrink={0}
              fontSize="md"
            >
              {userContext.config?.support_telephone}
            </Button>
          </ExternalLink>
          <ExternalLink isExternal href={`mailto:${userContext.config?.support_email}`}>
            <Button
              variant={'outline'}
              size="lg"
              mt={{ base: '6', md: '0' }}
              w={{ base: 'full', md: 'auto' }}
              minW="11rem"
              flexShrink={0}
              fontSize="md"
            >
              {t('components:get_in_touch_card.email_us')}
            </Button>
          </ExternalLink>
        </Stack>
      </Flex>
    </Card>
  );
}

export default GetInTouchCard;
