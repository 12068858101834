import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Results, User } from '../../../api/types';
import config from '../../../config';
import { DURATION } from '../../../constants';
import { useAxios } from '../../../context/./AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';
import useTokenQuery from '../useTokenQuery';

async function getWorkshopUser(axios: AxiosInstance): Promise<Results<User>> {
  const { data } = await axios.get(`${config.apiBaseUrl}/workshop/user`);

  return data;
}

export default function useWorkshopUserQuery() {
  const axios = useAxios();
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const tokenQuery = useTokenQuery();
  return useQuery(getKey(), () => getWorkshopUser(axios), {
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    refetchOnWindowFocus: false,
    cacheTime: DURATION.Hour,
    staleTime: DURATION.Minute * 5,
    enabled: !!tokenQuery.data,
    onError: (error: any) => {
      if (error?.response?.status !== 503) {
        return manageErrorResponse({
          toastInstance: toast,
          error: JSON.stringify(error),
          t,
        });
      }
      if (error?.response?.status >= 500 && error?.response?.status !== 503) {
        navigate('/app/error', { replace: true });
      }
    },
  });
}

function getKey(): QueryKey {
  return ['WorkshopUser'];
}

useWorkshopUserQuery.getKey = getKey;
