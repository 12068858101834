import { HStack, Stack, StackProps } from '@chakra-ui/react';
import React from 'react';

import { Skeleton } from '../Skeleton';

const iconSize = `3.1rem`;
export const CardHeaderSkeleton: React.FC<StackProps> = (props) => {
  return (
    <HStack spacing="4" py={4} pb={1} px={4} {...props} alignItems={'flex-start'}>
      <Skeleton height={iconSize} width={iconSize} />
      <Stack width={'full'} ml={2} spacing={1}>
        <Skeleton height="2rem" duration={2} />
        <Skeleton height=".6rem" duration={2} />
      </Stack>
    </HStack>
  );
};

export default CardHeaderSkeleton;
