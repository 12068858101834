import * as React from 'react';
import { default as LoadingSkeleton, SkeletonStyleProps } from 'react-loading-skeleton';

import { brandColors } from '../../foundations/theme';

export const Skeleton = (props: SkeletonStyleProps) => {
  return (
    <LoadingSkeleton
      baseColor={brandColors.gray.dimmed}
      highlightColor={brandColors.gray.default}
      borderRadius={'.3rem'}
      duration={2}
      {...props}
    />
  );
};

export default Skeleton;
