import { useToast } from '@chakra-ui/react';
import { Results } from 'api/types';
import axios from 'axios';
import config from 'config';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

export interface UploadedFileInfo {
  id: null | number;
  uuid: null | string;
  name: null | string;
  mime_type: null | string;
  extension: null | string;
  original_name: null | string;
  size: null | string;
}

async function upload(verification_key: string, file: FormData): Promise<Results<UploadedFileInfo>> {
  const { data } = await axios.post(`${config.cpSpfApiBaseUrl}/verifications/${verification_key}/file-upload`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export default function useSpfFileUploadMutation(verification_key: string) {
  const { t } = useTranslation();
  const toast = useToast();

  return useMutation<Results<UploadedFileInfo>, any, FormData>((file) => upload(verification_key, file), {
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error.response?.data?.data || error.message || error),
        t,
      });
    },
  });
}
