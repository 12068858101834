import { Box, Button, Flex, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { simpleInput } from 'helpers/makeFormFields';
import useUpdateBillingEmailAddressMutation from 'hooks/private/mutations/useUpdateBillingEmailAddressMutation';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheckCircle, VscError } from 'react-icons/all';
import * as yup from 'yup';

import { UpdateBillingEmailForm } from '../../api/types';
import { ButtonSpinner } from '../../components/LoadingSpinner';
import { useUserContext } from '../../context/UserContextProvider';
import { ContentCard } from '../../layout/ContentCard';

const MyDataBillingEmailCard: React.FC = () => {
  const userContext = useUserContext();

  const { t } = useTranslation();

  const updateBillingEmailAddressMutation = useUpdateBillingEmailAddressMutation();

  const schema = yup
    .object({
      billing_email: yup
        .string()
        .email()
        .matches(/^[^äÄöÖüÜ]*$/, { message: t('forms:email.special_characters') })
        .label(t('forms:email.label'))
        .required(),
    })
    .required();

  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<UpdateBillingEmailForm>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { billing_email: userContext.workshop?.billing_email },
  });
  const onSubmit: SubmitHandler<UpdateBillingEmailForm> = (data) => {
    return updateBillingEmailAddressMutation.mutate(data.billing_email, {
      onSuccess: () => {
        setSuccessMessage(true);
        setTimeout(() => {
          setSuccessMessage(false);
        }, 3000);
      },
      onError: () => {
        setErrorMessage(true);
        setTimeout(() => {
          setErrorMessage(false);
        }, 8000);
      },
    });
  };

  return (
    <ContentCard
      header={t('workshop:billing.header')}
      contentDescription={t('workshop:billing.description')}
      data-test-id="billing-change-card"
    >
      <HStack>
        <Stack width={'full'}>
          <Box as="form" onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <HStack>
                {simpleInput({
                  name: 'billing_email',
                  label: t('workshop:billing.label'),
                  placeholder: t('forms:email.placeholder'),
                  register,
                  errors,
                })}
                <Box>
                  <Text color={'green.500'}>
                    <Box width={'20px'} mt={`15px`}>
                      {successMessage ? (
                        <Icon as={AiOutlineCheckCircle} boxSize="5" color={'green.500'} />
                      ) : null}
                      {errorMessage ? (
                        <Icon as={VscError} boxSize="5" color={'orange.500'} />
                      ) : null}
                    </Box>
                  </Text>
                </Box>
              </HStack>

              <Flex direction="row-reverse">
                <HStack>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={updateBillingEmailAddressMutation.isLoading}
                    mr={7}
                  >
                    {t('common:save')}{' '}
                    {updateBillingEmailAddressMutation.isLoading ? (
                      <ButtonSpinner />
                    ) : null}
                  </Button>
                </HStack>
              </Flex>
            </Stack>
          </Box>
        </Stack>
      </HStack>
    </ContentCard>
  );
};

export default MyDataBillingEmailCard;
