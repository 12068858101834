import { Box, Center, Stack, Text, Tooltip } from '@chakra-ui/react';
import { isNil, noop } from 'lodash';
import * as React from 'react';
import { getI18n, useTranslation } from 'react-i18next';

import { Skeleton } from '../../../components/Skeleton';
import animationConfig from '../../../configs/animations';
import { simulateMouseClick } from '../../../helpers/general';
import { SERVICE_STATE } from '../../../hooks/queries/useVehicleQuery';

export const cardColors = {
  inactive: 'gray.300',
  inactiveText: 'gray.500',
  active: 'accent',
  activeText: 'inverted',
  processing: 'black',
  submitted: 'black',
  clarification: 'orange.500',
  canceled: 'red.500',
  complete: 'green',
};

export const getCardColor = (props: {
  isActive: boolean;
  isCompleted: boolean;
  isProcessing?: boolean;
  isInClarification?: boolean;
  isCanceled?: boolean;
  isRequestedDeletion?: boolean;
  isDisabled?: boolean;
}) => {
  const {
    isActive,
    isCompleted,
    isProcessing = false,
    isInClarification = false,
    isCanceled = false,
    isRequestedDeletion = false,
    isDisabled = false,
  } = props;

  if (isDisabled && !isCanceled && !isRequestedDeletion) {
    return cardColors.inactive;
  }
  if (isProcessing || isRequestedDeletion) {
    return cardColors.processing;
  }
  if (isInClarification) {
    return cardColors.clarification;
  }
  if (isCompleted) {
    return cardColors.complete;
  }
  if (isActive) {
    return cardColors.active;
  }
  if (isCanceled) {
    return cardColors.canceled;
  }
  return cardColors.inactive;
};

export const getInteractionContentBasedOnState = (props: {
  state?: string;
  isCompleted: boolean;
  isDisabled?: boolean;
  isProcessing?: boolean;
  isInClarification?: boolean;
  noDsbAvailable?: boolean;
  isUnique?: boolean;
  defaultMessage?: string;
  messageOpen?: string;
  messageProcessing?: string;
  messageCompleted?: string;
  messageInClarification?: string;
  messageDisabled?: string;
  upgradeContent?: any;
  handler: any;
}) => {
  const {
    isCompleted,
    isProcessing,
    isInClarification = false,
    isDisabled = false,
    isUnique = false,
    state = false,
    defaultMessage = undefined,
    messageCompleted = undefined,
    messageInClarification = undefined,
    messageProcessing = undefined,
    messageOpen = undefined,
    messageDisabled = getI18n().t('components:service_process_card.disabled.tooltip'),
    upgradeContent = false,
    handler,
    noDsbAvailable,
  } = props;

  return {
    isDisabled,
    handler: isDisabled || isUnique || state === SERVICE_STATE.OPEN ? noop : handler,
    tooltipMessage: isNil(defaultMessage)
      ? isCompleted
        ? messageCompleted
        : isProcessing
          ? messageProcessing
          : isInClarification
            ? messageInClarification
            : isDisabled
              ? messageDisabled
              : messageOpen
      : defaultMessage,
    upgradeContent,
    blockClick: isUnique || isDisabled,
  };
};

export const tool = (props: {
  icon: any;
  label: string | object;
  testId: string;
  tourId?: string;
  color: string;
  isLoading?: boolean;
  isInClarification?: boolean;
  requiresIdentification?: boolean;
  needsIdsbAddon?: boolean;
  noDsbAvailable?: boolean;
  noIpBlocksFillout?: boolean;
  unlockFeatureRef?: any;
  vehicleTypesExist: boolean;
  requiresVehicleType?: boolean;
  interact: {
    isDisabled?: boolean;
    upgradeContent?: any;
    tooltipMessage?: string;
    handler(): void;
    blockClick: boolean;
  };
}) => {
  const {
    icon,
    label,
    color,
    isLoading = false,
    testId,
    tourId,
    requiresIdentification = false,
    isInClarification = false,
    unlockFeatureRef,
    vehicleTypesExist,
    requiresVehicleType = false,
    needsIdsbAddon,
    noDsbAvailable,
    noIpBlocksFillout,
    interact: { isDisabled = false, upgradeContent = undefined, tooltipMessage = undefined, handler, blockClick },
  } = props;
  const additionalProps = upgradeContent
    ? {
        onClick: () => {
          return unlockFeatureRef.current && simulateMouseClick(unlockFeatureRef.current);
        },
      }
    : blockClick || isLoading
      ? {
          onClick: () => null,
        }
      : {
          onClick: handler,
          onKeyDown: handler,
        };
  const { t } = useTranslation();
  const content = (
    <Stack
      color={isDisabled ? 'disabled' : 'accent'}
      p={2}
      role="button"
      data-test-id={testId + (isDisabled ? '-disabled' : '-active')}
      className={'tour-process-card-' + tourId}
      textAlign={'center'}
      transition={`transform ${animationConfig.transition.duration.short.milliseconds} ${animationConfig.easing.easeIn}`}
      cursor={isDisabled || Boolean(blockClick) ? 'initial' : ''}
      willChange={'transform'}
      _hover={
        !isDisabled && !isInClarification && !blockClick
          ? {
              transform: 'scale(.97)',
            }
          : {}
      }
      {...additionalProps}
    >
      {isLoading ? <Skeleton height="8rem" width="8rem" /> : icon}

      <Text size={'sm'} as="div" color={color}>
        {' '}
        {isLoading ? <Skeleton height="1.3rem" width="8rem" /> : label}
      </Text>
    </Stack>
  );

  if (tooltipMessage || requiresIdentification || needsIdsbAddon || requiresVehicleType || noDsbAvailable || noIpBlocksFillout) {
    return (
      <Box>
        <Tooltip
          label={
            noIpBlocksFillout
              ? t(`components:service_process_card.fill_inspection_plan.blocked_by_ip_tooltip`)
              : requiresIdentification
                ? t(`components:service_process_card.requires_identification.tooltip`)
                : requiresVehicleType && !vehicleTypesExist
                  ? t(`components:service_process_card.inspection_plan.no_vehicle_types.tooltip`)
                  : noDsbAvailable
                    ? t(`components:service_process_card.service_history_request.no_dsb.tooltip`)
                    : noIpBlocksFillout
                      ? t(`components:service_process_card.fill_inspection_plan.blocked_by_ip_tooltip`)
                      : needsIdsbAddon
                        ? t(`components:service_process_card.inspection_plan.need_addon.tooltip`)
                        : tooltipMessage
          }
          textAlign={'center'}
        >
          {content}
        </Tooltip>
        {upgradeContent ? <Center>{upgradeContent}</Center> : null}
      </Box>
    );
  }

  return (
    <Box>
      {content}
      {upgradeContent ? <Center>{upgradeContent}</Center> : null}
    </Box>
  );
};
