import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useAxios } from 'context/AxiosContextProvider';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { ArrayResults, Promotion } from '../../api/types';
import config from '../../config';
import { DURATION } from '../../constants';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';
async function getPromotion(axios: AxiosInstance): Promise<ArrayResults<Promotion>> {
  const { data } = await axios.get(`${config.apiBaseUrl}/promotions`);
  return data;
}

export default function usePromotionsQuery() {
  const toast = useToast();
  const { t } = useTranslation();
  const axios = useAxios();

  return useQuery(getKey(), () => getPromotion(axios), {
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    cacheTime: DURATION.Hour,
    staleTime: DURATION.Minute * 5,
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}
function getKey(): QueryKey {
  return ['Promotions'];
}

usePromotionsQuery.getKey = getKey;
