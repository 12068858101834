import { Box, Button, Container, Flex, Grid, Stack, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { IdentificationResultIcon } from 'components/Icons';
import config from 'config';
import { downloadFileFromUrl } from 'helpers/downloadFiles';
import { dropzone } from 'helpers/makeFormFields';
import useSpfFileUploadMutation, { UploadedFileInfo } from 'hooks/queries/spf/useSpfFileUploadMutation';
import useSpfShowVerificationQuery from 'hooks/queries/spf/useSpfShowVerificationQuery';
import useUpdateSpfVerificationMutation from 'hooks/queries/spf/useUpdateSpfVerificationMutation';
import { FlexCard } from 'layout/Card';
import { ContentCard } from 'layout/ContentCard';
import { PublicPageLayout } from 'layout/PublicPageLayout';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { MdArrowDownward } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

export interface IAccountVerificationForm {
  power_of_attorney: any; //file
  business_licence: any; //file
  identification: any; //file
}

const VerificationFormSpf = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { verification_key } = useParams();

  const spfShowVerificationQuery = useSpfShowVerificationQuery(verification_key);
  const updateSpfVerificationMutation = useUpdateSpfVerificationMutation(verification_key ?? '');

  const verificationSchema = yup
    .object({
      business_licence: yup.mixed().required(t('workshop_verification_request:business_licence.error_message')),
      identification: yup.mixed().required(t('workshop_verification_request:identification.error_message')),
      power_of_attorney: yup.mixed().required(t('workshop_verification_request:power_of_attorney.error_message')),
    })
    .required();

  const queryClient = useQueryClient();
  const {
    formState: { errors },
    control,
    watch,
    register,
  } = useForm<IAccountVerificationForm>({
    resolver: yupResolver(verificationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const [identificationFile, setIdentificationFile] = useState<UploadedFileInfo | null>(null);
  const [powerOfAttorneyFile, setPowerOfAttorneyFile] = useState<UploadedFileInfo | null>(null);
  const [businessLicenceFile, setBusinessLicenceFile] = useState<Array<UploadedFileInfo> | null>(null);

  const fileUploadMutation = useSpfFileUploadMutation(verification_key ?? '');

  const verificationSubmission = () => {
    const uploadedFiles = {
      power_of_attorney_uuid: powerOfAttorneyFile?.uuid,
      business_licence_uuids: businessLicenceFile?.map((file: UploadedFileInfo) => file.uuid),
      identification_uuid: identificationFile?.uuid,
    };

    return updateSpfVerificationMutation.mutate(uploadedFiles, {
      onSuccess: () => {
        queryClient.invalidateQueries(useSpfShowVerificationQuery.getKey(verification_key));
      },
    });
  };

  const identificationHookForm = watch('identification');
  const powerOfAttorneyHookForm = watch('power_of_attorney');
  const businessLicenceHookForm = watch('business_licence');

  useEffect(() => {
    if (businessLicenceHookForm) {
      if (Array.isArray(businessLicenceHookForm)) {
        const formData = new FormData();
        formData.append('file', businessLicenceHookForm[businessLicenceHookForm.length - 1].file as File);

        (async () => {
          const fileData = await fileUploadMutation.mutateAsync(formData);
          setBusinessLicenceFile((prevState: Array<UploadedFileInfo>) => [...prevState, fileData?.data as UploadedFileInfo]);
        })();
      } else {
        const formData = new FormData();
        formData.append('file', businessLicenceHookForm.file as File);

        (async () => {
          const fileData = await fileUploadMutation.mutateAsync(formData);
          setBusinessLicenceFile([fileData?.data as UploadedFileInfo]);
        })();
      }
    } else {
      setBusinessLicenceFile(null);
    }
  }, [businessLicenceHookForm]);

  useEffect(() => {
    if (powerOfAttorneyHookForm) {
      const formData = new FormData();
      formData.append('file', powerOfAttorneyHookForm.file as File);

      (async () => {
        const fileData = await fileUploadMutation.mutateAsync(formData);
        setPowerOfAttorneyFile(fileData?.data as UploadedFileInfo);
      })();
    } else {
      setPowerOfAttorneyFile(null);
    }
  }, [powerOfAttorneyHookForm]);

  useEffect(() => {
    if (identificationHookForm) {
      const formData = new FormData();
      formData.append('file', identificationHookForm.file as File);

      (async () => {
        const fileData = await fileUploadMutation.mutateAsync(formData as any);
        setIdentificationFile(fileData?.data as UploadedFileInfo);
      })();
    } else {
      setIdentificationFile(null);
    }
  }, [identificationHookForm]);

  // const isVerificationSubmitted = true;
  const isVerificationSubmitted = spfShowVerificationQuery.data?.data?.status !== 0;

  if (!verification_key) {
    navigate('/complete-registration/error', { replace: true });
    return null;
  }

  return (
    <Box bgColor={'gray.200'} minHeight={'100vh'}>
      <PublicPageLayout overWriteDesign systemPartner={spfShowVerificationQuery.data?.data?.systempartner}>
        {isVerificationSubmitted && !spfShowVerificationQuery.isLoading && spfShowVerificationQuery.isSuccess ? (
          <Grid placeItems="center" mt={150}>
            <FlexCard width={'80%'} minWidth={'320'}>
              <Text fontSize="lg" textAlign="center">
                {t('pages:workshop_verification_request.confirmation_message')}
              </Text>
            </FlexCard>
          </Grid>
        ) : (
          <ContentCard
            icon={<IdentificationResultIcon boxSize={16} />}
            minH="fit-content"
            header={t('pages:workshop_verification_request.form.header')}
            contentDescription={t('pages:workshop_verification_request.form.sub_header')}
            isLoading={spfShowVerificationQuery.isLoading}
            mt={70}
          >
            <Grid gap={{ base: 4, xl: 8 }} px={{ base: 2, xl: 8 }}>
              <Grid gridTemplateColumns={{ base: '1fr', xl: '1.5fr 1fr' }} gap={{ base: 4, xl: 8 }} alignItems={'start'}>
                <Stack spacing={{ base: 4, xl: 8 }}></Stack>
              </Grid>
              <Stack w="full">
                <Container opacity={updateSpfVerificationMutation.isLoading ? 0.5 : 1}>
                  <Stack px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                    <Stack direction={['column']}>
                      <Box mb={'20px'}>
                        {dropzone({
                          name: 'business_licence',
                          fontSize: '16px',
                          required: true,
                          // @ts-ignore
                          label: <Trans whiteSpace={'pre-wrap'} i18nKey="forms:business_licence.label" />,
                          description: t('forms:business_licence.description'),
                          control,
                          register,
                          errors,
                          maxFiles: 10,
                        })}
                      </Box>
                      <Box mb={'20px'}>
                        {dropzone({
                          name: 'identification',
                          fontSize: '16px',
                          required: true,
                          // @ts-ignore
                          label: <Trans whiteSpace={'pre-wrap'} i18nKey="forms:identification.label" />,
                          description: <Trans whiteSpace={'pre-wrap'} i18nKey="forms:identification.description" />,
                          control,
                          register,
                          errors,
                        })}
                      </Box>
                      <Box height={'20px'} />
                      {dropzone({
                        name: 'power_of_attorney',
                        fontSize: '16px',
                        required: true,
                        // @ts-ignore
                        label: <Trans whiteSpace={'pre-wrap'} i18nKey="forms:power_of_attorney.label" />,
                        register,
                        description: (
                          <VStack alignItems={'left'}>
                            <Text fontSize="sm" textAlign="left">
                              {t('forms:power_of_attorney.description.one')}
                            </Text>
                            <Button
                              width={'134px'}
                              leftIcon={<MdArrowDownward />}
                              colorScheme="blue"
                              variant="solid"
                              float={'left'}
                              size="sm"
                              my="2"
                              onClick={() =>
                                downloadFileFromUrl(
                                  axios,
                                  `${config.cpSpfApiBaseUrl}/verifications/${verification_key}/poa-download`,
                                  'Power of Attorney.pdf',
                                )
                              }
                            >
                              {t('common:download')}
                            </Button>

                            <Text fontSize="sm" textAlign="left">
                              {t('forms:power_of_attorney.description.two')}
                            </Text>
                          </VStack>
                        ),
                        control,
                        errors,
                      })}
                    </Stack>
                    <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                      <Button
                        variant="primary"
                        ml={2}
                        disabled={!(identificationFile && businessLicenceFile && powerOfAttorneyFile) || updateSpfVerificationMutation.isLoading}
                        onClick={verificationSubmission}
                        isLoading={updateSpfVerificationMutation.isLoading || fileUploadMutation.isLoading}
                      >
                        {t('pages:workshop_verification_request.form.button.request')}
                      </Button>
                    </Flex>
                  </Stack>
                </Container>
              </Stack>
            </Grid>
          </ContentCard>
        )}
      </PublicPageLayout>
    </Box>
  );
};

export default VerificationFormSpf;
