import { Box, Center, Spinner } from '@chakra-ui/react';
import React from 'react';

export const InlineLoadingSpinner = (spinnerProps: any) => {
  return (
    <Center>
      <Spinner
        {...spinnerProps}
        size="xl"
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="accent"
      />
    </Center>
  );
};

export const ButtonSpinner = (spinnerProps: any) => {
  return (
    <Center>
      <Spinner
        {...spinnerProps}
        size="sm"
        thickness="2px"
        speed="0.65s"
        color="gray.200"
        emptyColor="accent"
      />
    </Center>
  );
};

export const LoadingSpinner = () => {
  return (
    <Center>
      <Spinner
        size="xl"
        m={200}
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="accent"
      />
    </Center>
  );
};

export const LoadingOverlay = () => {
  return (
    <Box
      position="fixed"
      zIndex="999"
      left="0"
      top="0"
      w="full"
      h="full"
      overflow="auto"
      opacity={0.9}
      mt={'0 !important'}
      bg={'whitesmoke'}
      p={20}
    >
      <LoadingSpinner />
    </Box>
  );
};
