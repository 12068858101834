import { HStack, Icon, Square, Text } from '@chakra-ui/react';
import * as React from 'react';
import { IoIosWarning } from 'react-icons/io';

import { InfoCard } from '../components/InfoCard';
import VersionHint from '../features/VersionHint';

interface DebuggingBannerProps {
  type?: string;
}

export const DebuggingBanner = (props: DebuggingBannerProps) => {
  const { type } = props;
  return (
    <InfoCard>
      <HStack>
        <Square size="10" borderRadius="md" display={'inline'}>
          <Icon color={'orange'} as={IoIosWarning} boxSize="8" />
        </Square>
        <Text>
          {type === 'support'
            ? 'You are logged in as a support user'
            : 'You are in a test environment'}
        </Text>
      </HStack>
      <VersionHint />
    </InfoCard>
  );
};
