import { getI18n } from 'react-i18next';

import { ITestimonial } from '../components/Testimonials/Testimonials';

export const testimonials: ITestimonial[] = [
  {
    author: getI18n().t(`components:testimonials.luckycar.author`),
    company: getI18n().t(`components:testimonials.luckycar.company`),
    position: getI18n().t(`components:testimonials.luckycar.position`),
    img: '/public/images/luckycar_logo.png',
    text: getI18n().t(`components:testimonials.luckycar.text`),
  },
  {
    author: getI18n().t(`components:testimonials.meta_car.author`),
    company: getI18n().t(`components:testimonials.meta_car.company`),
    position: getI18n().t(`components:testimonials.meta_car.position`),
    img: '/public/images/drive_solutions.jpg',
    text: getI18n().t(`components:testimonials.meta_car.text`),
  },
  {
    author: getI18n().t(`components:testimonials.wuermseer.author`),
    company: getI18n().t(`components:testimonials.wuermseer.company`),
    position: getI18n().t(`components:testimonials.wuermseer.position`),
    img: '/public/images/autofit_logo.png',
    text: getI18n().t(`components:testimonials.wuermseer.text`),
  },
];
