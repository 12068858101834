import { Box, Grid, Heading, Text } from '@chakra-ui/react';
import { PublicPageLayout } from 'layout/PublicPageLayout';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Confetti from '../../../components/Confetti';
import { Card } from '../../../layout/Card';

export const PreregistrationCompletedPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <PublicPageLayout showLogo={false}>
      <Grid w="full" h="full" placeItems="center">
        <Card width={'60%'} textAlign="center">
          <Box data-test-id="setup-completed-content" p={10}>
            <Heading size={'md'}>{t('workshop:preregistration.completed.title')}</Heading>
            <Text p={10} color="muted" fontSize="sm">
              {t('workshop:preregistration.completed.text')}
            </Text>
          </Box>
        </Card>
      </Grid>
      <Confetti pos="fixed" bottom="0" left="15%" w={'70%'} h={'100%'} />
    </PublicPageLayout>
  );
};

export default PreregistrationCompletedPage;
