import { Box, BoxProps, Collapse, Divider, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import { CardHeaderSkeleton } from 'components/SkeletonTemplates';
import * as React from 'react';

import { Card } from './Card';

interface ContentCardProps extends BoxProps {
  header?: string | React.ReactElement;
  footer?: string | React.ReactElement | false;
  contentDescription?: string;
  icon?: React.ReactElement;
  isLoading?: boolean;
  isLoadingHeader?: boolean;
  noDivider?: boolean;
  preregistration?: boolean;
}

export const ContentCard: React.FC<ContentCardProps> = ({
  header,
  footer,
  contentDescription,
  children,
  icon,
  isLoading,
  isLoadingHeader = false,
  noDivider,
  preregistration,
  ...boxProps
}) => {
  return (
    <Card {...boxProps}>
      {Boolean(header) && (
        <Box>
          {isLoading || isLoadingHeader ? (
            <CardHeaderSkeleton />
          ) : (
            <HStack spacing="4" py={preregistration ? 0 : 4} px={preregistration ? 14 : 4}>
              {icon}
              <Box width={'full'} ml={2}>
                <Heading size="xs">{header}</Heading>
                {contentDescription && (
                  <Text color="muted" fontSize="sm">
                    {contentDescription}
                  </Text>
                )}
              </Box>
            </HStack>
          )}
          {!noDivider && <Divider borderColor="grey" />}
        </Box>
      )}

      <Stack direction={['column']} p={6}>
        {isLoading ? (
          <Skeleton height="10rem" />
        ) : (
          <Collapse in={!isLoading} animateOpacity>
            {children}
          </Collapse>
        )}
      </Stack>

      {Boolean(footer) && (
        <Box>
          <Divider />
          {footer}
        </Box>
      )}
    </Card>
  );
};
