import { FlexboxProps, Stack, StackProps } from '@chakra-ui/react';
import * as React from 'react';

import { PartialStateCode } from '../../api/types';

export enum INDENTION_BOX_SIZES {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export const IntentionBoxIndentions: PartialStateCode<string> = {
  [INDENTION_BOX_SIZES.SM]: 8,
  [INDENTION_BOX_SIZES.MD]: 10,
  [INDENTION_BOX_SIZES.LG]: '54px',
};

export interface IIndentionBoxProps extends StackProps {
  size?: string;
}

export const IndentionBox: React.FC<StackProps & IIndentionBoxProps & FlexboxProps> = (
  props,
) => {
  const { size = 'md', spacing = 2 } = props;

  return <Stack pl={IntentionBoxIndentions[size]} ml={1} {...props} spacing={spacing} />;
};

export default IndentionBox;
