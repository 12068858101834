import { As, Button, ButtonProps, HStack, Icon, Text } from '@chakra-ui/react';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { FONT_WEIGHTS } from '../../global/Fonts';

interface NavButtonProps extends ButtonProps {
  icon?: As;
  to: string;
  label: string;
  isLoading?: boolean;
}

export const NavButton = (props: NavButtonProps) => {
  // eslint-disable-next-line
  const { icon, to, label, isLoading = false, ...buttonProps } = props;

  return (
    <Button
      as={NavLink}
      variant="unstyled"
      w={'full'}
      display={'block'}
      py={2}
      to={to}
      justifyContent="start"
      fontFamily="body"
      fontWeight={FONT_WEIGHTS.normal}
      size={'md'}
      role="group"
      _activeLink={{
        fontWeight: FONT_WEIGHTS.bold,
        '& svg': {
          stroke: 'white',
          fill: 'white',
        },
      }}
      _hover={{
        transform: 'translateX(.3em)',
        fontWeight: FONT_WEIGHTS.bold,
      }}
      {...buttonProps}
    >
      <HStack spacing="3" w={'full'}>
        <Icon
          as={icon}
          boxSize="6"
          sx={{
            transition: 'all .2s ease-in',
          }}
          _groupHover={{ color: 'inverted' }}
        />
        <Text as={'span'}>{label}</Text>
      </HStack>
    </Button>
  );
};
