import { BoxProps, Divider, Stack, useBreakpointValue } from '@chakra-ui/react';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { StepCircle } from './StepCircle';

interface StepProps extends BoxProps {
  title: string;
  isCompleted: boolean;
  isActive: boolean;
  isLastStep: boolean;
  isFirstStep: boolean;
}

export const stepperColors = {
  abTestInactive: 'gray.400',
  inactive: 'gray.300',
  inactiveText: 'gray.500',
  active: 'accent',
  activeText: 'inverted',
};

export const Step = (props: StepProps) => {
  const { isActive, isCompleted, isLastStep, isFirstStep, title } = props;
  const posthog = usePostHog();
  const location = useLocation();
  const pagepathIncludesSignup = location.pathname.includes('signup');
  const abTestNewDesign =
    posthog.getFeatureFlag('signupForm2') === 'SignupFormLongNewDesign' ||
    (posthog.getFeatureFlag('signupForm2') === 'SignupFormSplitNewDesign' &&
      pagepathIncludesSignup);
  const orientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'horizontal',
    md: 'horizontal',
  });

  return (
    <Stack spacing="0" align="center" direction="row" w={'full'}>
      <Divider
        orientation={orientation}
        borderWidth=".2rem"
        display={isFirstStep ? 'none' : 'initial'}
        backgroundColor={
          isCompleted || isActive
            ? stepperColors.active
            : abTestNewDesign
            ? stepperColors.abTestInactive
            : stepperColors.inactive
        }
        borderColor={
          isCompleted || isActive
            ? stepperColors.active
            : abTestNewDesign
            ? stepperColors.abTestInactive
            : stepperColors.inactive
        }
      />
      <StepCircle
        isActive={isActive}
        isCompleted={isCompleted}
        caption={title}
        abDesignTest={abTestNewDesign}
      />
      <Divider
        orientation={orientation}
        borderWidth=".2rem"
        display={isLastStep ? 'none' : 'initial'}
        borderColor={
          isCompleted
            ? stepperColors.active
            : abTestNewDesign
            ? stepperColors.abTestInactive
            : stepperColors.inactive
        }
        backgroundColor={
          isCompleted
            ? stepperColors.active
            : abTestNewDesign
            ? stepperColors.abTestInactive
            : stepperColors.inactive
        }
      />
    </Stack>
  );
};
