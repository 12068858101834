import {
  Box,
  Center,
  Flex,
  FlexProps,
  useColorModeValue as mode,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from '@chakra-ui/react';
import { LayoutGroup } from 'framer-motion';
import * as React from 'react';

import animationConfig from '../configs/animations';
import { ActiveIndicator } from './ActiveIndicator';

export const RadioButton = (
  props: UseRadioProps & { children?: React.ReactNode; testId: string },
) => {
  const { getInputProps, getCheckboxProps, getLabelProps, state } = useRadio(props);

  const renderActiveIndicator = ({ isActive = false }) => {
    const indicatorBackgroundColor = mode('white', 'gray.600');

    return isActive ? (
      <ActiveIndicator
        bg={indicatorBackgroundColor}
        shadow="md"
        layoutId="highlight"
        transition={{ duration: '0.1' }}
      />
    ) : null;
  };

  const checkedColor = mode('accent', 'accent');
  return (
    <Box as="label" pos="relative" {...getLabelProps()} data-test-id={props.testId}>
      <input {...getInputProps()} />
      <Center
        {...getCheckboxProps()}
        cursor="pointer"
        pos="relative"
        zIndex={1}
        h="12"
        px="8"
        textAlign="center"
        transition="all 0.2s"
        minW="8rem"
        fontWeight="medium"
        _checked={{
          color: checkedColor,
          fontWeight: 'bold',
        }}
      >
        {props.children}
      </Center>
      {renderActiveIndicator({ isActive: state.isChecked })}
    </Box>
  );
};

export interface IChoice {
  label?: string;
  value: string;
}

export interface IChoiceSwitch extends FlexProps {
  choice1: IChoice;
  choice2: IChoice;
  disabled: boolean;
  defaultValue: string | undefined;
}

export const ChoiceSwitch = (props: IChoiceSwitch) => {
  const { getRadioProps, getRootProps } = useRadioGroup({
    defaultValue: props.defaultValue ?? 'month',
  });
  const choiceBackground = mode('brand.gray.dark', 'brand.gray.default');
  return (
    <LayoutGroup>
      <Flex
        display="inline-flex"
        align="center"
        bg={choiceBackground}
        sx={
          props.disabled
            ? {
                opacity: 0.5,
                filter: 'saturate(0)',
                transition: `opacity ${animationConfig.transition.duration.base.milliseconds} ${animationConfig.easing.easeIn}, filter ${animationConfig.transition.duration.base.milliseconds} ${animationConfig.easing.easeIn}`,
              }
            : {}
        }
        rounded="full"
        {...getRootProps(props)}
      >
        <RadioButton
          {...getRadioProps({ value: props.choice1.value })}
          isDisabled={props.disabled}
          testId="subscription-switch-month"
        >
          {props.choice1.label ?? props.choice1.value}
        </RadioButton>
        <RadioButton
          isDisabled={props.disabled}
          {...getRadioProps({ value: props.choice2.value })}
          testId="subscription-switch-year"
        >
          {props.choice2.label ?? props.choice2.value}
        </RadioButton>
      </Flex>
    </LayoutGroup>
  );
};
