import { Box, Grid, Text } from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import axios from 'axios';
import usePlansQuery from 'hooks/queries/usePlansQuery';
import useTokenQuery from 'hooks/queries/useTokenQuery';
import { isNil } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import config from '../../config';
import PollingCard from '../../features/account-setup/polling/PollingCard';
import useWorkshopQuery from '../../hooks/queries/workshop/useWorkshopQuery';
import { LoadingSpinner } from '../LoadingSpinner';
import { AlertScaffolding } from './AlertModal';
export interface IAlertPollingModal {
  pollingCompleted?: () => void;
  onClose?: () => void;
  pollingCondition: (data: any) => boolean;
  url: string;
  data?: object;
  showSuccessAnimation?: boolean;
  content?: {
    header?: string | React.ReactElement;
    description?: string;
    footer?: {
      buttons: {
        hide?: boolean;
        cancelCaption?: string;
        actionCaption?: string;
      };
    };
  };
  styleConfig?: {
    variant?: string;
    minW?: string;
    maxW?: string;
    motionPreset?: any;
    w?: string;
  };
}

export default NiceModal.create((props: IAlertPollingModal) => {
  const navigate = useNavigate();
  const modal = useModal();
  const [error, setError] = useState(false);
  const {
    pollingCompleted,
    pollingCondition,
    onClose,
    showSuccessAnimation = true,
    content = {},
    data = {},
    url,
    styleConfig = {},
  } = props;
  const tokenQuery = useTokenQuery();
  const token = tokenQuery.data;

  const [isPolling, setIsPolling] = useState(false);
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation<any, Error, any>(async () => {
    return await axios
      .put(`${config.apiBaseUrl}/${url}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .catch((err: { response: { status: number } }) => {
        return Promise.reject(err);
      })
      .then(() => queryClient.invalidateQueries(useWorkshopQuery.getKey()));
  });

  const onSubmit = (data: any) => {
    mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries(useWorkshopQuery.getKey());
        queryClient.invalidateQueries(usePlansQuery.getKey());
        setIsPolling(true);
      },
      onError: (err: any) => {
        const { message } = err.response.data;
        const error500 = err.response.status === 500;

        if (error500) {
          modal.hide();
          navigate('/app/w/error');
        }

        setError(message);
      },
    });
  };

  if (tokenQuery.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <AlertScaffolding
      isLoading={isLoading}
      styleConfig={styleConfig}
      content={{
        ...content,
        hide: isPolling,
      }}
      isUsedInPolling={true}
      onClose={onClose}
      onCancel={() => {
        modal.hide();
        modal.remove();
      }}
      onSubmit={onSubmit}
      closeOnOverlayClick={!isPolling}
      modal={modal}
    >
      {isPolling ? (
        <PollingCard
          noCard
          showSuccessAnimation={showSuccessAnimation}
          pollingCondition={pollingCondition}
          success={() => {
            setIsPolling(false);
            if (!isNil(pollingCompleted)) {
              pollingCompleted();
              modal.hide();
              modal.remove();
              return;
            }
            queryClient.invalidateQueries(useWorkshopQuery.getKey());
            modal.hide();
            modal.remove();
          }}
        />
      ) : (
        <>
          <Grid>
            <Box fontSize={'xs'}>
              <Text>{content?.description}</Text>
              <Text fontSize="sm" color={'error'}>
                {error}
              </Text>
            </Box>
          </Grid>
        </>
      )}
    </AlertScaffolding>
  );
});
