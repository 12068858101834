import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import config from 'config';
import useShowInspectionPlanQuery from 'hooks/queries/inspection_plan/useShowInspectionPlanQuery';
import useVehicleQuery from 'hooks/queries/useVehicleQuery';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

async function updateInspectionPlan(
  axios: AxiosInstance,
  vin: string,
  id: string,
  inspectionData: { [key: string]: string },
): Promise<object> {
  const { data } = await axios.put(
    `${config.apiBaseUrl}/workshop/vehicles/${vin}/inspection-plans/${id}`,
    { data: { ...inspectionData } },
  );
  return data;
}

export default function useUpdateInspectionPlanMutation(vin: string, id: string) {
  const queryClient = useQueryClient();
  const axios = useAxios();
  const toast = useToast();
  const t = useTranslation();

  return useMutation(
    (inspectionData: { [key: string]: string }) =>
      updateInspectionPlan(axios, vin, id, inspectionData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
        queryClient.invalidateQueries(useShowInspectionPlanQuery.getKey(vin, id));
      },
      onError: (err: any) => {
        manageErrorResponse({ toastInstance: toast, error: err.response, t });
      },
    },
  );
}
