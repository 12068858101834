import { Button, Grid, Stack, Text } from '@chakra-ui/react';
import notFoundAnimation from 'assets/animations/404.json';
import PageMetatags from 'layout/PageMetatags';
import { PublicPageLayout } from 'layout/PublicPageLayout';
import Lottie from 'lottie-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '../layout/Card';
import Link from '../layout/Link';

export const Page404: React.FC<{}> = () => {
  const { t } = useTranslation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <PublicPageLayout>
      <PageMetatags title={`${t('errors:404.meta.title')} `} />
      <Grid w="full" h="full" placeItems="center">
        <Card p={{ base: 2, lg: 20 }} textAlign="center" borderWidth={2} minW={'60%'}>
          <Stack spacing={8} alignItems={'center'}>
            <Lottie
              animationData={notFoundAnimation}
              {...defaultOptions}
              height={300}
              width={400}
            />
            <Stack spacing="2" maxW="40ch">
              <Text size={'sm'}>{t('errors:404.description')}</Text>
            </Stack>

            <Button as={Link} variant={'primary'} to="/">
              {t('common:back')}
            </Button>
          </Stack>
        </Card>
      </Grid>
    </PublicPageLayout>
  );
};

export default Page404;
