import { useAuth0 } from '@auth0/auth0-react';
import { AxiosError } from 'axios';
import { get } from 'lodash';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { DURATION } from '../../constants';

export default function useTokenQuery<TData = string>(options?: UseQueryOptions<string, AxiosError, TData>) {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    getKey(),
    // @ts-ignore
    () => {
      let token;
      if (get(window, 'Cypress')) {
        token = localStorage.getItem('token');
      } else {
        token = getAccessTokenSilently();
      }

      return token;
    },
    {
      staleTime: DURATION.Minute * 10,
      notifyOnChangeProps: ['data', 'isFetching', 'error'],
    },
  );
}

function getKey(): QueryKey {
  return ['Token'];
}

useTokenQuery.getKey = getKey;
