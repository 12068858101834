import { Center, Circle, Icon, Text } from '@chakra-ui/react';
import * as React from 'react';
import { IoHourglassSharp, IoIosClose, IoIosWarning } from 'react-icons/all';
import { HiCheck } from 'react-icons/hi';

import { FONT_WEIGHTS, FONTS } from '../../../global/Fonts';
import { cardColors } from './displayHelper';
export const ServiceStepCircle = (props: any) => {
  const {
    isCompleted,
    isCanceled,
    isActive,
    isProcessing,
    isInClarification,
    isRequestedDeletion,
    color,
    caption = 1,
    ...otherProps
  } = props;

  let icon = null;

  if (isCompleted) {
    icon = HiCheck;
  } else if (isProcessing || isRequestedDeletion) {
    icon = IoHourglassSharp;
  } else if (isInClarification) {
    icon = IoIosWarning;
  } else if (isCanceled) {
    icon = IoIosClose;
  }

  return (
    <Circle size="8" bg={color} borderWidth="1px" borderColor={color} {...otherProps}>
      {icon ? (
        <Icon as={icon} color={cardColors.activeText} boxSize="5" />
      ) : (
        <Center
          boxSize="full"
          color={isActive ? cardColors.activeText : cardColors.inactiveText}
          fontFamily={FONTS.soehneBreit}
        >
          <Text w={'full'} textAlign={'center'} fontWeight={FONT_WEIGHTS.bold}>
            {caption}
          </Text>
        </Center>
      )}
    </Circle>
  );
};
