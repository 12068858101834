import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Grid,
  HStack,
  Icon,
  ListItem,
  ModalCloseButton,
  ModalProps,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { noop } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { Controller, useForm } from 'react-hook-form';
import { getI18n, Trans, useTranslation } from 'react-i18next';
import { BsCheck2 } from 'react-icons/all';
import { BiQrScan } from 'react-icons/bi';
import { IoIosWarning } from 'react-icons/io';
import { QrReader } from 'react-qr-reader';
import { useMutation } from 'react-query';

import { simpleInput } from '../../helpers/makeFormFields';
import { ButtonSpinner } from '../LoadingSpinner';
import AlertModal from './AlertModal';
interface IAlertDialog extends ModalProps {
  onFormSuccess?: (data?: any) => any;
  onMutate?: () => any;
  schema: any;
  defaultValues?: any;
  ref?: any;
  showOeDocsElementsOnly?: boolean;
  content: {
    formTestId?: string;
    header?: string | React.ReactElement;
    formFields: (data: object) => React.ReactElement;
    rest?: object;
    footer?: {
      buttons: {
        formButtonTestId?: string;
        hide?: boolean;
        cancelCaption?: string;
        actionCaption?: string;
      };
    };
  };
  styleConfig?: {
    minW?: string;
    maxW?: string;
    motionPreset?: any;
    w?: string;
  };
}

export default NiceModal.create((props: IAlertDialog) => {
  const modal = useModal();
  const refresh = () => window.location.reload();
  const { t } = useTranslation();
  const [showErrorMessageBox, setShowErrorMessageBox] = useState('');
  const onClose = () => {
    modal.hide();
    modal.remove();
  };

  const {
    showOeDocsElementsOnly,
    content,
    styleConfig = {},
    onFormSuccess = onClose,
    onMutate = noop,
    schema,
    defaultValues = {},
  } = props;
  const {
    formFields,
    rest = {},
    header = false,
    footer = { buttons: {} },
    formTestId = 'form-in-modal',
  } = content;

  const {
    buttons: {
      hide: hideButtons = false,
      cancelCaption = false,
      actionCaption = getI18n().t('common:submit'),
      formButtonTestId = 'form-in-modal-button',
    },
  } = footer;

  const {
    w = 'full',
    maxW = '50rem',
    minW,
    motionPreset = 'slideInBottom',
  } = styleConfig;
  const [rerenderQRScanner, setRerenderQRScanner] = useState(true);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const {
    handleSubmit,
    formState: { errors, isValid, submitCount, isDirty },
    control,
    register,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const { mutate, isLoading } = useMutation(async (data) => {
    return onMutate(data);
  });

  let watchedFields = watch();
  const watchQrCode = watch('qr_code_identifier');

  useEffect(() => {
    setRerenderQRScanner(true);
  }, [rerenderQRScanner]);
  //onSubmit to submit the qr code
  const onSubmit = (data: any) => {
    const qrCodeStringSubmitted = data?.qr_code_identifier;
    mutate(data, {
      onSuccess: (response: any) => {
        //Success case
        //NOTE: the qr code reader itself does not submit any data but only returns a string which then has to be submitted
        //NOTE: for the success case the qr code reader will NOT give any response
        if (qrCodeStringSubmitted && response === undefined) {
          NiceModal.show(AlertModal, {
            closeOnOverlayClick: false,
            children: (
              <Box>
                <Box height={330} width={'full'}>
                  <Center>
                    <VStack>
                      <Box>
                        <Icon as={BsCheck2} boxSize="20" color={'green.500'} mt={10} />
                      </Box>

                      <Text align={'center'} mb={10}>
                        <Trans i18nKey="components:scanner_modal_form.input_field.success_message" />
                      </Text>
                      <Button mt={10} variant={'primary'} onClick={refresh}>
                        Close
                      </Button>
                    </VStack>
                  </Center>
                </Box>
              </Box>
            ),
            content: {
              header: (
                <Box>
                  <Center>
                    <Box>
                      <Icon as={BiQrScan} color={'accent'} boxSize="10" mr={5} />
                    </Box>
                    <Box>
                      <Text mb={2}>
                        <Trans i18nKey="components:scanner_modal_form.title" />
                      </Text>
                    </Box>
                  </Center>
                </Box>
              ),
              footer: {
                buttons: {
                  hide: true,
                  cancelCaption: t('common:ok'),
                },
              },
            },
          });
          return;
        }
        //Error case
        //If the qr code was not successfully submitted there will be a response
        if (response) {
          if (response === 'qrCodeAlreadyUsed') {
            //comment in if you want to rerender the QR reader after
            /*  setShowErrorMessageBox(response);
            setRerenderQRScanner(false);
            return;*/
            NiceModal.show(AlertModal, {
              closeOnOverlayClick: false,
              children: (
                <Box>
                  <Box height={330} width={'full'}>
                    <Center>
                      <VStack>
                        <Box>
                          <Icon
                            as={IoIosWarning}
                            boxSize="20"
                            color={'red.500'}
                            mt={10}
                          />
                        </Box>

                        <Text align={'center'} mb={10}>
                          <Trans i18nKey="components:scanner_modal_form.input_field.error_message.code_used" />
                        </Text>
                        <Button mt={10} variant={'primary'} onClick={refresh}>
                          Close
                        </Button>
                      </VStack>
                    </Center>
                  </Box>
                </Box>
              ),
              content: {
                header: (
                  <Box>
                    <Center>
                      <Box>
                        <Icon as={BiQrScan} color={'accent'} boxSize="10" mr={5} />
                      </Box>
                      <Box>
                        <Text mb={2}>
                          <Trans i18nKey="components:scanner_modal_form.title" />
                        </Text>
                      </Box>
                    </Center>
                  </Box>
                ),
                footer: {
                  buttons: {
                    hide: true,
                    cancelCaption: t('common:ok'),
                  },
                },
              },
            });
            return;
          }
          onFormSuccess(data);
        } else {
          onFormSuccess();
        }
        if (response === undefined) {
          onClose();
        }
      },
      //This onError handles errors only when the QR code reader fails. This does not apply if the qr code was not submitted
      onError: (error: any) => {
        //not triggered
        const { errors, message } = error.response.data;
        setError('file', {
          type: 'server',
          message: message ?? errors['file']!.join('. '),
        });
      },
    });
  };

  return (
    <AlertDialog
      isOpen={modal.visible}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      motionPreset={motionPreset}
    >
      <AlertDialogOverlay sx={{ backdropFilter: 'blur(2rem)' }}>
        <AlertDialogContent
          minW={minW}
          w={w}
          maxW={maxW}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          data-test-id={formTestId}
        >
          {header && <AlertDialogHeader>{header}</AlertDialogHeader>}
          <ModalCloseButton data-test-id="alert-dialog-button-close" />
          {header && <Divider />}
          <AlertDialogBody>
            {showErrorMessageBox && !showOeDocsElementsOnly ? (
              <Box>
                <Center>
                  <Text mt={10} color={'error'} align={'center'}>
                    {' '}
                    {t(
                      'components:scanner_modal_form.input_field.error_message.code_used',
                    )}
                  </Text>
                </Center>
              </Box>
            ) : !showOeDocsElementsOnly ? (
              <Box height={88}></Box>
            ) : null}
            {formFields({
              control,
              register,
              setError,
              errors,
              isLoading,
              onSubmit: (val: any) => {
                onMutate(val);
                onClose();
              },
              watchedFields,
              isDirty,
              setValue,
              ...rest,
            })}

            {rerenderQRScanner && !showOeDocsElementsOnly ? (
              <Box>
                <Box>
                  <Center>
                    <Box fontSize={20} fontWeight={'semibold'} mt={5} mb={'2%'}>
                      {t('components:scanner_modal_form.camera.title')}
                    </Box>
                  </Center>
                  <Box>
                    <HStack>
                      <Box w={'50%'}>
                        <Controller
                          name={'qr_code_identifier'}
                          control={control}
                          rules={{ required: true }}
                          render={() => (
                            <QrReader
                              scanDelay={2000}
                              onResult={
                                (result, error) => {
                                  // @ts-ignore
                                  //qr code scanner returns a string
                                  if (result?.text) {
                                    // @ts-ignore
                                    const qrUrlSegments = result?.text?.split('/');
                                    const qrCodeAsString =
                                      qrUrlSegments[qrUrlSegments.length - 1];
                                    //the string from qr code scanner is submitted to the backend
                                    onSubmit({ qr_code_identifier: qrCodeAsString });
                                    // @ts-ignore
                                    //unmount and stop QR scanning
                                    unmountComponentAtNode(QrReader);
                                  }
                                }
                                //probably outdated: if QR reader is crashing try to move ReactDOM.unmountComponentAtNode(QrReader); to this line
                              }
                              constraints={{ facingMode: 'user' }}
                            />
                          )}
                        />
                      </Box>
                      <Box display={'flex'} w={'50%'}>
                        <Box ml={5}>
                          <UnorderedList
                            maxWidth={320}
                            justifyContent="center"
                            alignItems={'flex-start'}
                          >
                            <ListItem mb={3}>
                              {t('components:scanner_modal_form.camera.bullet_point_one')}
                            </ListItem>
                            <ListItem mb={3}>
                              {t('components:scanner_modal_form.camera.bullet_point_two')}
                            </ListItem>
                            <ListItem mb={3}>
                              {t(
                                'components:scanner_modal_form.camera.bullet_point_three',
                              )}
                            </ListItem>
                          </UnorderedList>
                        </Box>
                      </Box>
                    </HStack>
                  </Box>
                  <Box w={'100%'}>
                    <Center>
                      <VStack>
                        <Box fontSize={20} fontWeight={'semibold'} mb={3}>
                          {t('components:scanner_modal_form.input_field.label')}
                        </Box>
                        <HStack pb={10}>
                          <Box w={400}>
                            {simpleInput({
                              name: 'qr_code_identifier',
                              placeholder: t(
                                'components:scanner_modal_form.input_field.placeholder',
                              ),
                              register,
                              errors,
                            })}
                          </Box>
                        </HStack>
                      </VStack>
                    </Center>
                  </Box>
                </Box>
              </Box>
            ) : null}
          </AlertDialogBody>
          {!hideButtons ? <Divider /> : null}
          {!hideButtons ? (
            <AlertDialogFooter>
              <Grid templateColumns={`repeat(${cancelCaption ? '2' : '1'}, 1fr)`} gap={3}>
                {cancelCaption && (
                  <Button
                    variant={'outline'}
                    data-test-id="alert-dialog-cancel-button"
                    ref={cancelRef}
                    onClick={() => {
                      onClose();
                    }}
                    disabled={isLoading}
                  >
                    {cancelCaption}
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="primary"
                  disabled={(submitCount! > 0 && !isValid) || (watchQrCode && !isValid)}
                  data-test-id={formButtonTestId}
                >
                  {actionCaption} {isLoading ? <ButtonSpinner /> : null}
                </Button>
              </Grid>
            </AlertDialogFooter>
          ) : null}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
});
