import { Button, ButtonProps } from '@chakra-ui/react';
import * as React from 'react';

import { ButtonSpinner } from '../LoadingSpinner';

export interface IPortalButton extends ButtonProps {
  isLoading?: boolean;
}

export const PortalButton = ({
  isLoading = false,
  variant = 'primary',
  children,
  ...props
}: IPortalButton) => {
  return (
    <Button variant={variant} {...props}>
      {isLoading ? <ButtonSpinner /> : <>{children}</>}
    </Button>
  );
};
