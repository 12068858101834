import { Button, Flex, Grid, Heading, Icon, Stack, Text } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import GetInTouchCard from 'components/GetInTouchCard';
import PollingAlertModal from 'components/modals/PollingAlertModal';
import { addYears, format } from 'date-fns';
import { eventTrack } from 'helpers/analytics';
import { formatMultiCurrencyIntl, getFormatedCurrency } from 'helpers/formatCurrency';
import { useIsWorkshopBlocked } from 'helpers/general';
import Link from 'layout/Link';
import PageMetatags from 'layout/PageMetatags';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MdManageAccounts } from 'react-icons/md';
import { Navigate } from 'react-router-dom';

import { BILLING_CYCLE, Results, SubscriptionPlan } from '../../../../api/types';
import { InfoCard } from '../../../../components/InfoCard';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import getDisableCostControlData from '../../../../components/modals/GetDisableCostControlModalData';
import { PromotionBanner } from '../../../../components/PromotionBanner';
import { useUserContext } from '../../../../context/UserContextProvider';
import { FONT_WEIGHTS } from '../../../../global/Fonts';
import dateTransformer from '../../../../helpers/dateTransformer';
import useAddonsQuery from '../../../../hooks/queries/useAddonsQuery';
import usePlansQuery from '../../../../hooks/queries/usePlansQuery';
import usePromotionsQuery from '../../../../hooks/queries/usePromotionsQuery';
import {
  SUBSCRIPTION_NON_RENEWAL,
  WorkshopData,
} from '../../../../hooks/queries/workshop/useWorkshopQuery';
import { ContentCard } from '../../../../layout/ContentCard';
import CancelSubscriptionCard from './CancelSubscriptionCard';
import UpdateAvailableAddons from './UpdateAvailableAddons';

export const ManageSubscriptionPage: React.FC = () => {
  const userContext = useUserContext();
  const currency = userContext.workshop?.currency;
  const { t } = useTranslation();
  const plansQuery = usePlansQuery();
  const plansData = plansQuery.data?.data || [];
  const plans = Object.values(plansQuery.data?.data || []);
  const promotionsQuery = usePromotionsQuery();
  const promotionsData = promotionsQuery.data?.data || [];
  const addonsQuery = useAddonsQuery();
  const addonsData = addonsQuery.data?.data;
  const hideUpgradeButton = plans.length === 0;

  const costDisableModalData = getDisableCostControlData();

  const displayPlans: SubscriptionPlan[] = [...plans];
  const ownPlan = userContext.workshop?.subscription?.plan;
  const [billingCycle, setBillingCycle] = React.useState(
    userContext.workshop?.subscription?.billing_cycle || BILLING_CYCLE.MONTHLY,
  );
  const isOE10 = userContext.workshop?.subscription?.plan?.name === 'OE 10';

  const currencySymbol = currency === 'EUR' ? '€' : currency === 'GBP' ? '£' : 'Kč';
  const { decimal, float } = getFormatedCurrency(ownPlan?.yearly_savings);

  const isPartnerWorkshop = !!userContext.workshop?.distributor?.prepaid_plan_id;
  const partnerCreatedAt = userContext.workshop?.distributor?.created_at ?? '';
  const prepaidLicenceAmountMonthly =
    userContext.workshop?.distributor?.prepaid_plan_amount_monthly;
  const distributorName = userContext.workshop?.distributor?.name;

  //@ts-ignore
  const isUserNotPayPerUseWithMonthlyCycle =
    billingCycle === 'month' && ownPlan?.is_pay_per_use === 0 && !isPartnerWorkshop;

  const getUpdateModalData = (slug: string) => {
    //@ts-ignore
    const plan: SubscriptionPlan = displayPlans.find(
      (plan: SubscriptionPlan) => plan.slug === slug,
    );
    return {
      pollingCondition: (data: Results<WorkshopData>) => {
        return (
          data?.data?.subscription?.plan?.id === ownPlan?.id &&
          data?.data?.subscription?.billing_cycle === 'year'
        );
      },
      pollingCompleted: () => {
        eventTrack('plan_upgrade_completed');
        setBillingCycle(BILLING_CYCLE.YEARLY);
      },
      showSuccessAnimation: true,
      data: {
        plan_id: ownPlan?.id,
        billing_cycle: BILLING_CYCLE.YEARLY,
      },
      content: {
        header: t('pages:subscription.manage.yearly_billing.modal.header'),
        description: t('pages:subscription.manage.yearly_billing.modal.text'),
        footer: {
          buttons: {
            cancelCaption: t('common:cancel'),
            actionCaption: t('pages:subscription.manage.yearly_billing.plan'),
          },
        },
      },
      url: 'workshop/subscription/plan',
    };
  };
  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (userContext.workshop?.disable_cost_control) {
    NiceModal.show(costDisableModalData?.modalComponent, costDisableModalData);
    return null;
  }
  if (addonsQuery.isLoading || plansQuery.isLoading || promotionsQuery.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageMetatags title={t('pages:subscription.manage.meta.title')} />
      {promotionsData.map((promotion, index) => {
        if (promotion?.location?.includes('update_subscription')) {
          return (
            <PromotionBanner
              key={index}
              promotion={promotion}
              url={'/app/w/account/subscription/plan'}
            />
          );
        }
        return null;
      })}

      {userContext.workshop?.subscription?.status === SUBSCRIPTION_NON_RENEWAL && (
        <InfoCard>
          <Text fontSize="sm" color="muted" data-test-id="cancelled-subscription-warning">
            {t('pages:subscription.manage.cancel.warning.description')}
            {/*@ts-ignore*/}
            {dateTransformer(
              userContext.workshop?.subscription?.ended_at as string,
              userContext.user?.timezone,
            )}
          </Text>
        </InfoCard>
      )}
      <Grid gap={{ base: 4, lg: 10 }}>
        <ContentCard
          data-test-id={'active-subscription-card'}
          isLoading={isEmpty(userContext.workshop?.subscription) || plansQuery.isFetching}
          header={t('pages:subscription.manage.update.header')}
          contentDescription={t('pages:subscription.manage.update.description')}
          icon={<Icon as={MdManageAccounts} boxSize="10" />}
        >
          <Stack direction={'column'} spacing={4}>
            <Stack direction={'row'} alignItems={'center'} spacing={4}>
              <Text as={'div'} fontSize={18}>
                <strong>{t('pages:subscription.manage.active.header')}</strong>
              </Text>
              <Text
                color="accent"
                as={'div'}
                fontWeight={FONT_WEIGHTS.bold}
                fontSize="xl"
              >
                {t(
                  `plans:${userContext.workshop?.subscription?.plan?.slug}.label`,
                ).toLocaleUpperCase()}
              </Text>
            </Stack>
            {isPartnerWorkshop && plansData.length === 0 && (
              <Stack
                direction={'column'}
                alignItems={'flex-start'}
                justifyContent={'center'}
              >
                <Text fontSize={15.5}>
                  {t('pages:subscription.partner.licenseExplanation.partner', {
                    expirationDate: format(
                      addYears(new Date(partnerCreatedAt), 1),
                      'MM.dd.yyyy',
                    ),
                    partnerName: distributorName,
                  })}
                  {` ${t('pages:subscription.partner.licenseExplanation.maxPlan', {
                    expirationDate: format(
                      addYears(new Date(partnerCreatedAt), 1),
                      'MM.dd.yyyy',
                    ),
                    moneyValue: formatMultiCurrencyIntl(
                      prepaidLicenceAmountMonthly ?? 0,
                      currency,
                    ),
                  })}`}
                </Text>
              </Stack>
            )}

            {isPartnerWorkshop && plansData !== undefined && plansData.length > 0 && (
              <Stack
                direction={'column'}
                alignItems={'flex-start'}
                justifyContent={'center'}
              >
                <Text fontSize={15.5}>
                  {t('pages:subscription.partner.licenseExplanation.partner', {
                    expirationDate: format(
                      addYears(new Date(partnerCreatedAt), 1),
                      'MM.dd.yyyy',
                    ),
                    partnerName: distributorName,
                  })}
                  {` ${t('pages:subscription.partner.licenseExplanation.upgrade', {
                    expirationDate: format(
                      addYears(new Date(partnerCreatedAt), 1),
                      'MM.dd.yyyy',
                    ),
                    moneyValue: formatMultiCurrencyIntl(
                      prepaidLicenceAmountMonthly ?? 0,
                      currency,
                    ),
                  })}`}
                </Text>
              </Stack>
            )}

            <Stack direction={'row'} alignItems={'center'} spacing={4}>
              <Text as={'div'} fontSize={18}>
                <strong>{`${t(
                  'pages:subscription.manage.yearly_billing.label',
                )} :`}</strong>
              </Text>
              <Text
                color="accent"
                as={'div'}
                fontWeight={FONT_WEIGHTS.bold}
                fontSize="lg"
              >
                {billingCycle === 'month'
                  ? t('pages:subscription.manage.yearly_billing.monthly_billing_cycle')
                  : t('pages:subscription.manage.yearly_billing.yearly_billing_cycle')}
              </Text>
            </Stack>

            {isUserNotPayPerUseWithMonthlyCycle && (
              <Flex direction="column" pb={20}>
                <Heading fontSize={20}>
                  {t('pages:subscription.manage.yearly_billing.text_start')}{' '}
                  {currencySymbol} {decimal}
                  {
                    <>
                      ,<sup>{float === 0 ? '00' : float}</sup>
                    </>
                  }{' '}
                  {t('pages:subscription.manage.yearly_billing.text_end')}
                </Heading>
                <Flex direction="row" py="0">
                  <Button
                    px={10}
                    variant="primary"
                    cursor="pointer"
                    onClick={(evt, planSlug: string) =>
                      NiceModal.show(PollingAlertModal, getUpdateModalData(planSlug))
                    }
                  >
                    {t('pages:subscription.manage.yearly_billing.button')}
                  </Button>
                </Flex>
              </Flex>
            )}

            <Text>{!isOE10 && t('pages:subscription.manage.active.description')}</Text>
            {!hideUpgradeButton && (
              <Flex direction="row-reverse" py="4" px={{ base: '4', lg: '6' }}>
                <Button
                  as={Link}
                  variant="primary"
                  to={`/app/w/account/subscription/plan`}
                  _hover={{ textDecoration: 'none' }}
                >
                  {t('pages:subscription.manage.switch.plan')}
                </Button>
              </Flex>
            )}
          </Stack>
        </ContentCard>

        <Heading size={{ base: 'sm', xl: 'md' }} textAlign="center">
          {t('pages:subscription.manage.update.addons.header')}
        </Heading>

        <UpdateAvailableAddons
          currency={currency}
          addons={Object.values(addonsData)}
          subscription={userContext.workshop?.subscription}
          isLoading={isEmpty(userContext.workshop?.subscription) || plansQuery.isFetching}
          costControlDisabled={userContext.workshop?.disable_cost_control}
        />

        <GetInTouchCard />
        <CancelSubscriptionCard subscription={userContext.workshop?.subscription} />
      </Grid>
    </>
  );
};

export default ManageSubscriptionPage;
