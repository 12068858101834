import { Box, BoxProps, Flex, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';

export const Card = (props: BoxProps) => (
  <Box
    minH="16"
    borderRadius="lg"
    boxShadow={useColorModeValue('lg', 'lg-dark')}
    bg="bg-surface"
    {...props}
  />
);

export const FlexCard = (props: BoxProps) => (
  <Flex
    minH="16"
    flexDirection={'column'}
    justifyContent={'center'}
    borderRadius="lg"
    boxShadow={useColorModeValue('lg', 'lg-dark')}
    bg="bg-surface"
    {...props}
  />
);
