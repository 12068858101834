import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { isDate } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { PaginatedArrayResults, ServiceActivity } from '../../../api/types';
import config from '../../../config';
import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

async function getServiceActivity(props: {
  axios: AxiosInstance;
  searchString?: string;
  page?: number;
  itemsPerPage?: number;
  status?: number;
  type?: string;
  startDate?: string;
  endDate?: string;
}): Promise<PaginatedArrayResults<ServiceActivity>> {
  const { axios, searchString, page, itemsPerPage, status, type, startDate, endDate } = props;
  const { data } = await axios.get(`${config.apiBaseUrl}/workshop/service-activities`, {
    params: {
      search_string: searchString || '',
      page,
      items_per_page: itemsPerPage,
      status,
      type,
      start_date: isDate(startDate) ? startDate : null,
      end_date: isDate(endDate) ? endDate : null,
    },
  });
  return data;
}

export default function useServiceActivityQuery({
  searchString,
  page,
  itemsPerPage,
  status,
  type,
  startDate,
  endDate,
}: {
  searchString?: string;
  page?: number;
  itemsPerPage?: number;
  status?: number;
  type?: string;
  startDate?: string;
  endDate?: string;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const axios = useAxios();
  return useQuery(
    getKey({ searchString, page, itemsPerPage, status, type, startDate, endDate }),
    () =>
      getServiceActivity({
        axios,
        searchString,
        page,
        itemsPerPage,
        status,
        type,
        startDate,
        endDate,
      }),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'error'],
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        manageErrorResponse({
          toastInstance: toast,
          error: JSON.stringify(error),
          t,
        });
      },
    },
  );
}
function getKey({
  searchString,
  page,
  itemsPerPage,
  status,
  type,
  startDate,
  endDate,
}: {
  searchString?: string;
  page?: number;
  itemsPerPage?: number;
  status?: number;
  type?: string;
  startDate?: string;
  endDate?: string;
}): QueryKey {
  return ['ServiceActivity', searchString, page, itemsPerPage, status, type, startDate, endDate];
}

useServiceActivityQuery.getKey = getKey;
