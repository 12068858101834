import { Box, Flex, Stack, Text, TextProps } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React from 'react';
import { Trans } from 'react-i18next';

import { BILLING_CYCLE, Discount, LooseObject } from '../api/types';
import { useUserContext } from '../context/UserContextProvider';
import { FONT_WEIGHTS, FONTS } from '../global/Fonts';
import { formatMultiCurrency, formatMultiCurrencyIntl, getFormatedCurrency } from '../helpers/formatCurrency';

interface IPriceProps extends TextProps {
  value: number;
  isTrial?: boolean;
  isPurchased?: boolean;
  showStartingPrice?: boolean;
  discount?: Discount | LooseObject;
  billingCycle?: string;
  t: any;
}

const PriceInCard = (props: IPriceProps) => {
  const {
    t,
    value,
    showStartingPrice = false,
    fontSize = { base: '3xl', lg: '3xl' },
    fontWeight = FONT_WEIGHTS.bold,
    fontFamily = FONTS.soehneBreit,
    isTrial = false,
    isPurchased = false,
    billingCycle,
    discount = {},
    ...rest
  } = props;

  const userContext = useUserContext();
  const currency = userContext.workshop?.currency;
  const isOnPromotion = !isEmpty(discount);
  const currencySymbol = currency === 'EUR' ? '€' : currency === 'GBP' ? '£' : 'Kč';
  const price = isOnPromotion ? discount?.amount : value;
  const computedPrice = price;
  const computedBasePrice = value;

  const permanentPromotion = !discount?.period;
  const { decimal, float } = getFormatedCurrency(computedPrice);

  return (
    <Box>
      <Box>
        <Stack display={'block'}>
          {permanentPromotion && computedBasePrice > computedPrice ? (
            <Text as={'p'} textDecoration={'line-through'} color={'black'}>
              {formatMultiCurrencyIntl(computedBasePrice, currency)}
            </Text>
          ) : null}
          <Flex fontSize={{ base: 'xl', lg: '3xl' }} display={'inline-flex'}>
            <Text as={'div'} lineHeight={1} fontFamily={fontFamily} fontSize={fontSize} fontWeight={fontWeight} {...rest}>
              {currencySymbol} {decimal}
              {float !== 0 && (
                <>
                  ,<sup>{float}</sup>
                </>
              )}
            </Text>
          </Flex>
          {!isPurchased && isOnPromotion ? (
            <Text m={'0 !important'}>
              {permanentPromotion ? (
                <>
                  {showStartingPrice && t('addons:starting_at')} {t('common:per')} {t(`pages:subscription.payment_interval.${billingCycle}`)}
                </>
              ) : billingCycle === 'month' ? (
                <Trans i18nKey="promotions:first_month" count={discount?.period} />
              ) : (
                <Trans i18nKey="promotions:first_year" />
              )}
            </Text>
          ) : null}
          {isOnPromotion && !permanentPromotion ? (
            <Text as={'p'} m={'0 !important'} fontSize="xs" color={'black'}>
              <>
                {t('promotions:instead_of')} {formatMultiCurrency(computedBasePrice, currency)}
                {` ${t('common:per')}`} {t(`pages:subscription.payment_interval.${billingCycle}`)}
              </>
            </Text>
          ) : null}
          {!isOnPromotion ? (
            <Text as={'div'} fontSize="xs">
              {showStartingPrice ? t('addons:starting_at') : ''} {t('common:per')} {t(`pages:subscription.payment_interval.${billingCycle}`)}
            </Text>
          ) : null}
          {isTrial ? (
            <Text fontSize="xs" m={'0 !important'} color={'black'}>
              {t('addons:trial.free')}
            </Text>
          ) : null}
        </Stack>
      </Box>
    </Box>
  );
};
// @ts-ignore
export default PriceInCard;
