import { Box, Container, Flex, HStack, Text } from '@chakra-ui/react';
import config from 'config';
import { motion } from 'framer-motion';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Distributor, SystemPartner } from '../api/types';
import OeLogo from '../components/OeLogo';
import { Environments, isTestingEnvironment } from '../helpers/environments';
import { DebuggingBanner } from './DebuggingBanner';

type PublicPageLayoutProps = {
  children?: React.ReactNode;
  showOELogo?: boolean;
  systemPartner?: SystemPartner | null | undefined;
  distributor?: Distributor | null | undefined;
  showCobrandedSubheadline?: boolean;
  showCobrandedLogo?: boolean;
  backgroundColor?: string;
  abTestNewDesign?: boolean;
  currentStep?: number;
  overWriteDesign?: boolean;
};

export const PublicPageLayout: React.FC<PublicPageLayoutProps> = ({
  showOELogo = true,
  systemPartner,
  distributor,
  showCobrandedSubheadline,
  showCobrandedLogo,
  children,
  backgroundColor,
  abTestNewDesign,
  overWriteDesign,
}) => {
  const { t } = useTranslation();

  // in registration case
  const distributorName = distributor?.name;
  const distributorLogoUrl = distributor?.logo_url;

  // in pre registration case
  const systemPartnerLogo = systemPartner?.logo_url;
  const systemPartnerName = systemPartner?.name;

  return (
    <Box bgColor={abTestNewDesign ? 'gray.200' : undefined}>
      {isTestingEnvironment() && config.appEnv !== Environments.STAGE && config.appEnv !== Environments.QA ? <DebuggingBanner /> : null}
      {abTestNewDesign || overWriteDesign ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          {(abTestNewDesign || overWriteDesign) && (
            <Box zIndex="sticky" position="sticky" top={0} margin={'0'}>
              <Flex pl={9} bgColor={'blue.500'} style={{ height: 40 }} fontSize={'14'} align={'center'}>
                <Text color={'white'}>{t('abtests:registration_page.slogan.label')}</Text>
              </Flex>
              {showOELogo && !showCobrandedLogo && (abTestNewDesign || overWriteDesign) && (
                <HStack justifyContent={'left'} mb={-20} pl={'7'} bgColor={'white'} height={'90'}>
                  <OeLogo width={180} height={40} />
                  {systemPartnerLogo && (
                    <HStack>
                      <Text as="b" mr={5}>
                        {t('pages:pre_paid_welcome_page.in_collaboration_with')}
                      </Text>
                      <img
                        src={systemPartnerLogo}
                        alt={`${distributorName}` + ' Logo'}
                        width={180}
                        height={40}
                        marginInline={'auto'}
                        marginBlockEnd={{ base: 8, lg: 16 }}
                      />
                    </HStack>
                  )}
                </HStack>
              )}
              {showOELogo && showCobrandedLogo && (abTestNewDesign || overWriteDesign) && (
                <HStack justifyContent={'left'} mb={-20} pl={'7'} bgColor={'white'} height={'90'}>
                  <OeLogo width={180} height={40} />
                  <HStack
                    justifyContent={'left'}
                    mb={distributor ? 10 : 50}
                    pl={overWriteDesign ? 0 : '7'}
                    bgColor={abTestNewDesign ? 'white' : undefined}
                    height={abTestNewDesign ? '100' : undefined}
                  >
                    <Text pr={2}>{t('pages:pre_paid_welcome_page.in_collaboration_with')}</Text>
                    <img
                      src={showCobrandedLogo}
                      alt={`${distributorName}` + ' Logo'}
                      width={136}
                      marginInline={'auto'}
                      marginBlockEnd={{ base: 8, lg: 16 }}
                    />
                  </HStack>
                </HStack>
              )}
            </Box>
          )}
          {(!abTestNewDesign && showOELogo && !showCobrandedLogo) ||
            (!overWriteDesign && <OeLogo width={64} marginInline={'auto'} marginBlockEnd={{ base: 8, lg: 16 }} />)}

          {(!abTestNewDesign && showOELogo && !showCobrandedLogo) ||
            (overWriteDesign && showCobrandedLogo && (
              <>
                <OeLogo width={64} marginInline={'auto'} marginBlockEnd={{ base: 8, lg: 16 }} />
                {/*      <HStack
                  justifyContent={'left'}
                  mb={distributor ? 10 : 50}
                  pl={'7'}
                  bgColor={abTestNewDesign ? 'white' : undefined}
                  height={abTestNewDesign ? '100' : undefined}
                >
                  <OeLogo width={64} />
                  <Text pr={2}>
                    {t('pages:pre_paid_welcome_page.in_collaboration_with')}
                  </Text>
                  <img
                    src={svgUrl}
                    alt={`${distributorName}` + ' Logo'}
                    width={136}
                    marginInline={'auto'}
                    marginBlockEnd={{ base: 8, lg: 16 }}
                  />
                </HStack>*/}
              </>
            ))}

          {/*     {showCobrandedLogo && (
            <HStack
              justifyContent={'left'}
              mb={distributor ? 10 : 50}
              pl={'7'}
              bgColor={abTestNewDesign ? 'white' : undefined}
              height={abTestNewDesign ? '100' : undefined}
            >
              <OeLogo width={64} />
              <Text pr={2}>{t('pages:pre_paid_welcome_page.in_collaboration_with')}</Text>
              <img
                src={svgUrl}
                alt={`${distributorName}` + ' Logo'}
                width={136}
                marginInline={'auto'}
                marginBlockEnd={{ base: 8, lg: 16 }}
              />
            </HStack>
          )}*/}
          <Container padding="10" w={'full'} maxW="8xl" backgroundColor={backgroundColor ? backgroundColor : ''}>
            {showCobrandedSubheadline && (
              <Box textAlign={'center'} mb={70}>
                <Trans i18nKey={`pages:pre_paid_welcome_page.sub_headline`} values={{ distributor: distributorName }} />
              </Box>
            )}
            {children}
          </Container>
        </motion.div>
      ) : (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Container padding="10" pt={5} w={'full'} maxW="8xl">
            {showOELogo && !showCobrandedLogo && <OeLogo width={64} marginInline={'auto'} marginBlockEnd={{ base: 8, lg: 16 }} />}
            {showCobrandedLogo && (
              <HStack justifyContent={'center'} mb={distributor ? 10 : 70}>
                <OeLogo width={64} />
                <Text pr={2}>{t('pages:pre_paid_welcome_page.in_collaboration_with')}</Text>
                <img
                  src={distributorLogoUrl}
                  alt={`${distributorName}` + ' Logo'}
                  width={136}
                  marginInline={'auto'}
                  marginBlockEnd={{ base: 8, lg: 16 }}
                />
              </HStack>
            )}
            {showCobrandedSubheadline && (
              <Box textAlign={'center'} mb={70}>
                <Trans i18nKey={`pages:pre_paid_welcome_page.sub_headline`} values={{ distributor: distributorName }} />
              </Box>
            )}
            {children}
          </Container>
        </motion.div>
      )}
    </Box>
  );
};
