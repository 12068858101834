import NiceModal from '@ebay/nice-modal-react';
import { get, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LooseObject, Vehicle } from '../../api/types';
import AlertModal from '../../components/modals/AlertModal';
import { isFunctionalityAvailable } from '../../helpers/makeFormFields';
import { WorkshopData } from '../../hooks/queries/workshop/useWorkshopQuery';

const NeedsVerificationModal = (props: {
  vehicle?: Vehicle | LooseObject;
  workshop: WorkshopData;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { workshop, vehicle = {} } = props;
  const portal = get(vehicle?.manufacturer, 'make', {});
  // @ts-ignore
  const allowAction = isFunctionalityAvailable(workshop, portal);

  if (!isNil(workshop) && !isNil(vehicle) && !allowAction) {
    NiceModal.show(AlertModal, {
      onClose: () => navigate('/app/w/vehicle?vin=' + vehicle?.vin),
      onSubmit: () =>
        workshop?.is_verification_pending
          ? navigate('/app/w/vehicle?vin=' + vehicle?.vin)
          : navigate('/app/w/account/verification'),
      content: {
        header: t(
          `pages:workshop_verification_request.modal.${
            portal?.verification_needed === 'pending'
              ? 'verification_pending_required'
              : 'verification_completed_required'
          }.title`,
        ),
        footer: {
          buttons: {
            actionCaption: t('common:yes'),
          },
        },
      },
      children: t(
        `pages:workshop_verification_request.modal.${
          portal?.verification_needed === 'pending'
            ? 'verification_pending_required'
            : 'verification_completed_required'
        }.description`,
      ),
    });
  }
  return null;
};
export default NeedsVerificationModal;
