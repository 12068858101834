import { Divider, Grid, HStack, Tag, Text, VStack } from '@chakra-ui/react';
import { get } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { PartialStateCode } from '../api/types';
import {
  getDarkStatusColor,
  getTextStatusColor,
} from '../helpers/getColourSchemeBasedOnStatus';

export const StatusTag = (props: {
  resourceKey: string;
  statusCodes: PartialStateCode<number>;
  reasons?: PartialStateCode<number>;
  currentStatus?: number;
  currentStatusReason?: number;
  hideDescription?: boolean;
}) => {
  const { t } = useTranslation();
  const {
    resourceKey,
    statusCodes = {},
    currentStatus,
    currentStatusReason,
    hideDescription = false,
    reasons,
  } = props;

  const serviceRecordInClarification =
    resourceKey === 'service_record' && currentStatus === 500;

  return (
    <Grid
      templateColumns={{ base: '1fr', lg: 'auto 3fr minmax(0, 1fr)' }}
      alignItems={'flex-start'}
      w={'full'}
      gap={{ base: 4, lg: 3 }}
    >
      <VStack alignItems={'start'}>
        <HStack>
          <Tag
            size={'md'}
            variant="solid"
            textTransform={'uppercase'}
            flexShrink={0}
            mt={{ base: 0, lg: 1 }}
            w={'12rem'}
            paddingBlock={2}
            textAlign={'center'}
            display={'block'}
            whiteSpace={'normal'}
            color={getTextStatusColor(get(statusCodes, currentStatus, 'default'))}
            bgColor={getDarkStatusColor(get(statusCodes, currentStatus, 'default'))}
          >
            {t(
              `status:${resourceKey}.${get(statusCodes, currentStatus, 'unknown')}.label`,
            )}
          </Tag>

          {!hideDescription && (
            <Text
              as={'div'}
              maxWidth={'90ch'}
              overflowWrap={'break-word'}
              whiteSpace={'normal'}
            >
              {/*If SR has status 500 show reason text instead of status text*/}
              {serviceRecordInClarification && reasons && currentStatusReason
                ? t(
                    `status:${resourceKey}.${get(
                      statusCodes,
                      currentStatus,
                      'unknown',
                    )}.${get(
                      reasons[currentStatus],
                      currentStatusReason,
                      'unknown',
                    )}.label`,
                  )
                : t(
                    `status:${resourceKey}.${get(
                      statusCodes,
                      currentStatus,
                      'unknown',
                    )}.description`,
                  )}
            </Text>
          )}
        </HStack>
        {reasons && currentStatusReason ? <Divider /> : null}
        {reasons && currentStatusReason ? (
          <Text
            as={'div'}
            textAlign={'start'}
            justifySelf={{ base: 'center', lg: 'end' }}
            pr={12}
          >
            {/*If SR in status 500 show status text instead of reason text*/}
            {serviceRecordInClarification
              ? t(
                  `status:${resourceKey}.${get(
                    statusCodes,
                    currentStatus,
                    'unknown',
                  )}.description`,
                )
              : t(
                  `status:${resourceKey}.${get(
                    statusCodes,
                    currentStatus,
                    'unknown',
                  )}.${get(
                    reasons[currentStatus],
                    currentStatusReason,
                    'unknown',
                  )}.label`,
                )}
          </Text>
        ) : null}
      </VStack>
    </Grid>
  );
};
