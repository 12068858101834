import { Box, BoxProps, Grid, GridProps, Stack, useId, useRadio, useRadioGroup, UseRadioProps } from '@chakra-ui/react';
import React from 'react';

import animationConfig from '../../../../configs/animations';

interface PlanCardGroupProps<T> extends Omit<GridProps, 'onChange'> {
  name?: string;
  value?: T;
  defaultValue?: string;
  onChange?: (value: T) => void;
}

export const PlanCardGroup = <T extends string>(props: PlanCardGroupProps<T>) => {
  const { children, name, defaultValue, value, onChange, ...rest } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });

  const cards = React.useMemo(
    () =>
      React.Children.toArray(children)
        .filter<React.ReactElement<PlanCardProps>>(React.isValidElement)
        .map((card) => {
          return React.cloneElement(card, {
            radioProps: getRadioProps({
              value: card.props.value,
            }),
          });
        }),
    [children, getRadioProps, value, defaultValue],
  );

  return <Grid {...getRootProps(rest)}>{cards}</Grid>;
};

interface PlanCardProps extends BoxProps {
  value: string;
  radioProps?: UseRadioProps;
}

export const PlanCard = (props: PlanCardProps) => {
  const { radioProps, children, ...rest } = props;
  // eslint-disable-next-line
  const { getInputProps, getCheckboxProps, getLabelProps, state } = useRadio(radioProps);
  const id = useId(undefined, 'radio-button');

  // const styles = useStyleConfig('RadioCard', props);
  const styles = {};
  const inputProps = getInputProps();
  const checkboxProps = getCheckboxProps();
  const labelProps = getLabelProps();

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        isSelected: state.isChecked,
        isHovered: state.isHovered,
      });
    }
    return child;
  });

  return (
    <Box
      as="label"
      cursor="pointer"
      {...labelProps}
      textAlign={'center'}
      transition={`all ${animationConfig.transition.duration.base.milliseconds} ${animationConfig.easing.easeIn}`}
    >
      <input {...inputProps} aria-labelledby={id} />
      <Box sx={styles} {...checkboxProps} {...rest} h={'full'}>
        <Stack direction="column" alignItems={'center'} spacing={6} justifyContent={'space-around'} h={'full'}>
          <Box flex="1" w={'full'} h={'full'}>
            {childrenWithProps}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
