import {
  Box,
  HStack,
  Image,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import autofitLogo from '../../../src/assets/images/autofit_logo.png';
import driveSolutionsLogo from '../../../src/assets/images/drive_solutions.jpg';
import luckycarLogo from '../../../src/assets/images/luckycar_logo.png';
export type ITestimonial = {
  img: string;
  author: string;
  text: string;
  position: string;
  company: string;
};
export function Testimonials(props: {
  testimonial: ITestimonial;
  abTestNewDesign?: boolean;
}) {
  const { testimonial, abTestNewDesign = false } = props;
  const { t } = useTranslation();
  // @ts-ignore
  return (
    <Box
      bg={'white'}
      borderRadius={'8px'}
      overflow={'hidden'}
      bgColor={abTestNewDesign ? 'white' : 'gray.200'}
    >
      <Box maxHeight={700} minHeight={180} padding={'10px'}>
        <HStack mt={'10px'}>
          {/*  <Box minWidth={120} height={120}>
            <Image
              borderRadius="full"
              src={
                testimonial?.company === 'testimonials.luckycar.company'
                  ? luckycarLogo
                  : testimonial?.company === 'testimonials.meta_car.company'
                  ? driveSolutionsLogo
                  : autofitLogo
              }
              name={testimonial.author}
              boxSize={'120px'}
              boxShadow={useColorModeValue('lg', 'lg-dark')}
            />
          </Box>*/}
          <Box>
            <VStack>
              <Box>
                <Text
                  //remove mT if logos are displayed
                  mt={7}
                  textStyle={{ base: '3xl', md: '4xl' }}
                  fontWeight="medium"
                  align={'center'}
                >
                  {t('components:' + `${testimonial.text}`)}
                </Text>
                <Stack spacing="1" align={'center'} mt={'10px'}>
                  <Text fontWeight="semibold" textStyle="lg">
                    {t('components:' + `${testimonial.author}`)} -{' '}
                    {t('components:' + `${testimonial.position}`)}{' '}
                    {t('components:testimonials.at')}{' '}
                    {t('components:' + `${testimonial.company}`)}
                  </Text>
                </Stack>
              </Box>
            </VStack>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
}
export default Testimonials;
