import { Box, Button, Container, Flex, Grid, Stack, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { IdentificationResultIcon } from 'components/Icons';
import config from 'config';
import { useAxios } from 'context/AxiosContextProvider';
import { downloadFileFromUrl } from 'helpers/downloadFiles';
import { useIsWorkshopBlocked } from 'helpers/general';
import useFileUploadMutation, { UploadedFileInfo } from 'hooks/private/mutations/useFileUploadMutation';
import useStoreVerificationsMutation from 'hooks/private/mutations/useStoreVerificationsMutation';
import useUpdateStoreVerificationsMutation from 'hooks/private/mutations/useUpdateStoreVerificationMutation';
import { ContentCard } from 'layout/ContentCard';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { MdArrowDownward } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { Navigate, NavLink } from 'react-router-dom';
import * as yup from 'yup';

import { STATUS_CREATED, useUserContext } from '../../../context/UserContextProvider';
import { dropzone } from '../../../helpers/makeFormFields';
import useWorkshopQuery from '../../../hooks/queries/workshop/useWorkshopQuery';
import VerificationPendingCard from './VerificationPendingCard';

export interface IAccountVerificationForm {
  power_of_attorney: any; //file
  business_licence: any; //file
  identification: any; //file
}

const VerificationForm = (props: { showSkip?: boolean; skipUrl?: any }) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const verificationSchema = yup
    .object({
      business_licence: yup.mixed().required(t('workshop_verification_request:business_licence.error_message')),
      identification: yup.mixed().required(t('workshop_verification_request:identification.error_message')),
      power_of_attorney: yup.mixed().required(t('workshop_verification_request:power_of_attorney.error_message')),
    })
    .required();

  const { showSkip, skipUrl = 'app/w/dashboard' } = props;
  const queryClient = useQueryClient();
  const {
    formState: { errors },
    control,
    watch,
    register,
  } = useForm<IAccountVerificationForm>({
    resolver: yupResolver(verificationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const userContext = useUserContext();
  const isReVerify = userContext.workshop?.verifications[0]?.status === STATUS_CREATED;

  const updateVerificationId = userContext.workshop?.verifications?.find((verification) => verification?.status === 0)?.id;

  const [identificationFile, setIdentificationFile] = useState<UploadedFileInfo | null>(null);
  const [powerOfAttorneyFile, setPowerOfAttorneyFile] = useState<UploadedFileInfo | null>(null);
  const [businessLicenceFile, setBusinessLicenceFile] = useState<Array<UploadedFileInfo> | null>(null);

  const fileUploadMutation = useFileUploadMutation();
  const storeVerificationsMutation = useStoreVerificationsMutation();
  const updateStoreVerificationsMutation = useUpdateStoreVerificationsMutation();

  const verificationSubmission = () => {
    const uploadedFiles = {
      power_of_attorney_uuid: powerOfAttorneyFile?.uuid,
      business_licence_uuids: businessLicenceFile?.map((file: UploadedFileInfo) => file.uuid),
      identification_uuid: identificationFile?.uuid,
    };

    return updateVerificationId
      ? updateStoreVerificationsMutation.mutate(
          { id: updateVerificationId, uploadedFiles },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(useWorkshopQuery.getKey());
            },
          },
        )
      : storeVerificationsMutation.mutate(uploadedFiles, {
          onSuccess: () => {
            queryClient.invalidateQueries(useWorkshopQuery.getKey());
          },
        });
  };

  const identificationHookForm = watch('identification');
  const powerOfAttorneyHookForm = watch('power_of_attorney');
  const businessLicenceHookForm = watch('business_licence');

  useEffect(() => {
    if (businessLicenceHookForm) {
      if (Array.isArray(businessLicenceHookForm)) {
        const formData = new FormData();
        formData.append('file', businessLicenceHookForm[businessLicenceHookForm.length - 1].file as File);

        (async () => {
          const fileData = await fileUploadMutation.mutateAsync(formData);
          setBusinessLicenceFile((prevState: Array<UploadedFileInfo>) => [...prevState, fileData?.data as UploadedFileInfo]);
        })();
      } else {
        const formData = new FormData();
        formData.append('file', businessLicenceHookForm.file as File);

        (async () => {
          const fileData = await fileUploadMutation.mutateAsync(formData);
          setBusinessLicenceFile([fileData?.data as UploadedFileInfo]);
        })();
      }
    } else {
      setBusinessLicenceFile(null);
    }
  }, [businessLicenceHookForm]);

  useEffect(() => {
    if (powerOfAttorneyHookForm) {
      const formData = new FormData();
      formData.append('file', powerOfAttorneyHookForm.file as File);

      (async () => {
        const fileData = await fileUploadMutation.mutateAsync(formData);
        setPowerOfAttorneyFile(fileData?.data as UploadedFileInfo);
      })();
    } else {
      setPowerOfAttorneyFile(null);
    }
  }, [powerOfAttorneyHookForm]);

  useEffect(() => {
    if (identificationHookForm) {
      const formData = new FormData();
      formData.append('file', identificationHookForm.file as File);

      (async () => {
        const fileData = await fileUploadMutation.mutateAsync(formData as any);
        setIdentificationFile(fileData?.data as UploadedFileInfo);
      })();
    } else {
      setIdentificationFile(null);
    }
  }, [identificationHookForm]);

  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (userContext.workshop?.is_verification_pending && !isReVerify) {
    return <VerificationPendingCard />;
  }
  return (
    <ContentCard
      icon={<IdentificationResultIcon boxSize={16} />}
      minH="fit-content"
      header={t('pages:workshop_verification_request.form.header')}
      contentDescription={t('pages:workshop_verification_request.form.sub_header')}
      data-test-id="workshop-verification-request-form"
    >
      <Grid gap={{ base: 4, xl: 8 }} px={{ base: 2, xl: 8 }}>
        <Grid gridTemplateColumns={{ base: '1fr', xl: '1.5fr 1fr' }} gap={{ base: 4, xl: 8 }} alignItems={'start'}>
          <Stack spacing={{ base: 4, xl: 8 }}></Stack>
        </Grid>
        <Stack w="full">
          <Container opacity={storeVerificationsMutation.isLoading ? 0.5 : 1}>
            <Stack px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
              <Stack direction={['column']}>
                <Box mb={'20px'}>
                  {dropzone({
                    name: 'business_licence',
                    fontSize: '16px',
                    required: true,
                    // @ts-ignore
                    label: <Trans whiteSpace={'pre-wrap'} i18nKey="forms:business_licence.label" />,
                    description: t('forms:business_licence.description'),
                    control,
                    register,
                    errors,
                    maxFiles: 10,
                  })}
                </Box>
                <Box mb={'20px'}>
                  {dropzone({
                    name: 'identification',
                    fontSize: '16px',
                    required: true,
                    // @ts-ignore
                    label: <Trans whiteSpace={'pre-wrap'} i18nKey="forms:identification.label" />,
                    description: <Trans whiteSpace={'pre-wrap'} i18nKey="forms:identification.description" />,
                    control,
                    register,
                    errors,
                  })}
                </Box>
                <Box height={'20px'} />
                {dropzone({
                  name: 'power_of_attorney',
                  fontSize: '16px',
                  required: true,
                  // @ts-ignore
                  label: <Trans whiteSpace={'pre-wrap'} i18nKey="forms:power_of_attorney.label" />,
                  register,
                  description: (
                    <VStack alignItems={'left'}>
                      <Text fontSize="sm" textAlign="left">
                        {t('forms:power_of_attorney.description.one')}
                      </Text>
                      <Button
                        width={'134px'}
                        data-test-id="download-power-of-attorney-pdf"
                        leftIcon={<MdArrowDownward />}
                        colorScheme="blue"
                        variant="solid"
                        float={'left'}
                        size="sm"
                        my="2"
                        onClick={() => downloadFileFromUrl(axios, `${config.apiBaseUrl}/workshop/poa-download`, 'Power of Attorney.pdf')}
                      >
                        {t('common:download')}
                      </Button>

                      <Text fontSize="sm" textAlign="left">
                        {t('forms:power_of_attorney.description.two')}
                      </Text>
                    </VStack>
                  ),
                  control,
                  errors,
                })}
              </Stack>
              <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                <Button
                  // type="submit"
                  variant="primary"
                  ml={2}
                  data-test-id="account-verification-submit-button"
                  disabled={!(identificationFile && businessLicenceFile && powerOfAttorneyFile) || storeVerificationsMutation.isLoading}
                  onClick={verificationSubmission}
                >
                  {t('pages:workshop_verification_request.form.button.request')}
                </Button>
                {showSkip && (
                  <Button as={NavLink} to={skipUrl} variant="outline" type={'button'} data-test-id="account-verification-skip-button">
                    {t('common:skip')}
                  </Button>
                )}
              </Flex>
            </Stack>
          </Container>
        </Stack>
      </Grid>
    </ContentCard>
  );
};

export default VerificationForm;
