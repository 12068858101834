import { Box, Button, Flex, Icon } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { isNil } from 'lodash';
import * as React from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { BiQrScan } from 'react-icons/bi';
import * as yup from 'yup';

import FormEmbeddedAlertModal from '../../components/modals/FormEmbeddedAlertModal';

const QRScannerForm = (props: {
  qrCodeApplied: string | null | undefined;
  displayError?: boolean;
  sendRequest(data: any, displayError: boolean): void;
  data: any;
  record?: any;
}) => {
  const { sendRequest, data, qrCodeApplied } = props;
  const { t } = useTranslation();
  const schema = yup
    .object({
      qr_code_identifier: yup
        .string()
        .nullable()
        .min(
          10,
          t('components:scanner_modal_form.input_field.error_message.code_too_short'),
        )
        .max(
          10,
          t('components:scanner_modal_form.input_field.error_message.code_too_long'),
        )
        .matches(
          /^[0-9A-Z]+$/,
          t('components:scanner_modal_form.input_field.error_message.only_alphanumeric'),
        )
        .required(
          t('components:scanner_modal_form.input_field.error_message.code_required'),
        ),
    })
    .required();

  return (
    <>
      <Button
        variant="link"
        size="sm"
        isDisabled={!isNil(qrCodeApplied)}
        onClick={() =>
          NiceModal.show(FormEmbeddedAlertModal, {
            content: {
              header: (
                <Box>
                  <Flex verticalAlign={'true'}>
                    <Icon as={BiQrScan} color={'accent'} boxSize="10" mr={5} />{' '}
                    {t('components:scanner_modal_form.title')}
                  </Flex>
                </Box>
              ),
              formTestId: 'scan-form',
              formFields: () => {
                // @ts-ignore
                return <Box />;
              },
              footer: {
                buttons: {
                  hide: false,
                  formButtonTestId: 'scan-submit-button',
                  actionCaption: getI18n().t(
                    'components:scanner_modal_form.button.label',
                  ),
                },
              },
            },
            onMutate: sendRequest,
            schema,
            defaultValues: { qr_code_identifier: data },
          })
        }
      >
        <Icon as={BiQrScan} color={'accent'} boxSize="14" />
      </Button>
    </>
  );
};

export default QRScannerForm;
