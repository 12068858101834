import { FONT_WEIGHTS } from '../global/Fonts';
import fonts from './fonts';

const buttonOutlineProps = (color: string, rest: object) => {
  return {
    color: color,
    borderColor: color,
    borderWidth: '1px',
    backgroundColor: 'transparent',
    _disabled: {
      _hover: {
        color: color,
      },
    },
    _hover: {
      bg: color,
      color: 'white',
    },
    _active: {
      bg: color,
      color: 'white',
    },
    ...rest,
  };
};

export const components = (theme: any) => ({
  Heading: {
    sizes: {
      ...theme.components.Heading.sizes,
      xxs: {
        fontSize: 'md',
        lineHeight: '1.3',
      },
    },
  },

  Radio: {
    sizes: {
      xl: {
        control: {
          w: 8,
          h: 8,
        },
        label: {
          fontSize: 'lg',
        },
      },
    },
  },

  Checkbox: {
    sizes: {
      xl: {
        control: {
          w: 10,
          h: 10,
        },
        label: {
          fontSize: 'lg',
        },
        icon: {
          fontSize: '1.25rem',
        },
      },
    },
    // can be Radio
    baseStyle: {
      label: {
        touchAction: 'none',
      },
    },
  },

  PinInput: {
    sizes: {
      xl: {
        fontSize: '2xl',
        w: 16,
        h: 16,
        borderRadius: 'md',
      },
    },
  },

  Button: {
    baseStyle: {
      borderRadius: '21px',
      fontWeight: FONT_WEIGHTS.bold,
      fontFamily: fonts.body,
    },
    variants: {
      outline: buttonOutlineProps('brand.500', {}),
      'outline-error': buttonOutlineProps('brand.orange.default', {}),
    },
  },
});

export default components;
