import { useToast } from '@chakra-ui/react';
import { Results } from 'api/types';
import { AxiosInstance } from 'axios';
import config from 'config';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useAxios } from '../../../context/AxiosContextProvider';

interface UploadedFileInfo {
  id: null | number;
  uuid: null | string;
  name: null | string;
  mime_type: null | string;
  extension: null | string;
  original_name: null | string;
  size: null | string;
}

async function upload(
  axios: AxiosInstance,
  file: File,
): Promise<Results<UploadedFileInfo>> {
  const { data } = await axios.post(`${config.apiBaseUrl}/file-upload`, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
  return data;
}

export default function useUploadDocumentMutation() {
  const axios = useAxios();
  const { t } = useTranslation(); // Destructure `t` correctly
  const toast = useToast();

  return useMutation<Results<UploadedFileInfo>, any, File>(
    (file: File) => upload(axios, file),
    {
      onError: (error: any) => {
        manageErrorResponse({
          toastInstance: toast,
          error: JSON.stringify(error.response?.data || error.message || error),
          t,
        });
      },
    },
  );
}
