const animationConfig = {
  transition: {
    duration: {
      short: { milliseconds: '150ms', numeric: 150 },
      base: { milliseconds: '300ms', numeric: 300 },
      long: { milliseconds: '500ms', numeric: 500 },
    },
  },
  easing: {
    easeIn: 'ease-in',
  },
};

export const awaitAnimation = (
  duration = animationConfig.transition.duration.base.numeric,
) => {
  return new Promise((resolve) => setTimeout(resolve, duration));
};

export default animationConfig;
