export const faq = [
  {
    title: 'question_1',
    links: [],
  },
  {
    title: 'question_2',
    links: [],
  },
  {
    title: 'question_3',
    links: [],
  },
  {
    title: 'question_4',
    links: [],
  },
  {
    title: 'question_5',
    links: [],
  },
  {
    title: 'question_6',
    links: [],
  },
  {
    title: 'question_7',
    links: [],
  },
  {
    title: 'question_8',
    links: [],
  },
  {
    title: 'question_9',
    links: [],
  },
  {
    title: 'question_10',
    links: [
      'https://www.oeservice.eu/herstelleruebersicht/',
      'https://www.oeservice.eu/digitaler-serviceeintrag#oe-plus/',
    ],
  },
  {
    title: 'question_11',
    links: [],
  },
  {
    title: 'question_12',
    links: [],
  },
];
