import {
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react';
import cuid from 'cuid';
import { replaceBlankByDash, toLower } from 'helpers/string';
import * as React from 'react';
import { FieldErrors } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { HiEye, HiEyeOff } from 'react-icons/hi';

interface PasswordFieldProps {
  label?: string;
  register: FieldValues;
  error?: FieldErrors;
  boxShadow?: string;
}

export const PasswordField = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<PasswordFieldProps>
>((props, ref) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const uniqueId = `password-${cuid()}`;

  const mergeRef = useMergeRefs(inputRef, ref);
  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };

  return (
    <FormControl>
      <FormLabel htmlFor={toLower(replaceBlankByDash(props.label || 'password'))}>
        {props.label || 'Password'}
      </FormLabel>
      <InputGroup>
        <InputRightElement>
          <IconButton
            variant="link"
            aria-label={isOpen ? 'Mask password' : 'Reveal password'}
            icon={isOpen ? <HiEyeOff /> : <HiEye />}
            onClick={onClickReveal}
          />
        </InputRightElement>
        <Input
          id={uniqueId}
          ref={mergeRef}
          name={toLower(replaceBlankByDash(props.label || 'password'))}
          type={isOpen ? 'text' : 'password'}
          autoComplete="current-password"
          required
          boxShadow={props.boxShadow}
          {...props.register}
        />
      </InputGroup>
      {Boolean(props.error) && (
        <Text fontSize="sm" textAlign="left" color={'error'}>
          {props.error?.message}
        </Text>
      )}
    </FormControl>
  );
});

PasswordField.displayName = 'PasswordField';
