import { Icon, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AiFillQuestionCircle } from 'react-icons/ai';

export interface IIconTooltipProps {
  text?: string | React.ReactNode;
  hasArrow?: boolean;
}

export const IconTooltip: React.FC<IIconTooltipProps> = (props) => {
  const { text = '', hasArrow = true } = props;
  return (
    <Tooltip label={text} hasArrow={hasArrow} shouldWrapChildren>
      <Icon as={AiFillQuestionCircle} boxSize="5" color="accent" />
    </Tooltip>
  );
};

export default IconTooltip;
