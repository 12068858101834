import { AxiosInstance } from 'axios';

export const downloadFileFromUrl = async (axios: AxiosInstance, url: string, fallbackFilename: string) => {
  const response = await axios({ method: 'GET', url: url, responseType: 'blob' });

  if (response.status !== 200) {
    console.warn('file download failed');
    return;
  }
  const extractedFileName = (() => {
    const contentDisposition = response.headers?.['content-disposition'] ?? null;
    if (!contentDisposition) {
      return null;
    }
    const filenameRegex = /filename="?([^"]+)"?/;
    const matches = filenameRegex.exec(contentDisposition);

    if (matches && matches[1]) {
      return matches[1];
    }
    return null;
  })();
  const blob = response.data;
  const link = document.createElement('a');
  const blobUrl = URL.createObjectURL(blob);
  link.href = blobUrl;
  link.download = extractedFileName ?? fallbackFilename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
};
