import { Box, Button, Collapse, useBoolean } from '@chakra-ui/react';
import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

/**
 * A Tab with a specific color, that has a trigger button (in color of prop + active state)
 * It shows the Content inside an Box that has the same color as background
 * @param props
 * @constructor
 */

export interface IToggletab {
  testId?: string;
  color?: string;
  isInitiallyOpen?: boolean;
  openCaption: string;
  closeCaption: string;
  children?: React.ReactNode;
}

export const ToggleTab: React.FC<IToggletab> = (props) => {
  const {
    children = null,
    isInitiallyOpen = false,
    openCaption,
    closeCaption,
    testId,
  } = props;
  const [isContentVisible, setIsContentVisible] = useBoolean(isInitiallyOpen);

  return (
    <Box>
      <Button
        data-test-id={testId}
        variant="ghost"
        onClick={setIsContentVisible.toggle}
        rightIcon={isContentVisible ? <FiChevronUp /> : <FiChevronDown />}
      >
        {isContentVisible ? closeCaption : openCaption}
      </Button>
      <Collapse in={isContentVisible}>
        <Box py={4} px={4}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default ToggleTab;
