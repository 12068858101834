import { Box, Divider, Heading, HStack, Icon, Stack, Text, VStack } from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosCar } from 'react-icons/io';

import { Card } from './Card';

type StepFormCardProps = {
  stepHeader: string;
  stepDescription: string;
  children?: React.ReactNode;
  isLoading: boolean;
  skeletonHeight?: string;
  abDesignTest?: boolean;
  currentStep?: number;
  width?: string;
  isInspectionPlan?: boolean;
};
export const StepFormCard: React.FC<StepFormCardProps> = ({
  abDesignTest,
  stepHeader,
  stepDescription,
  isLoading,
  children,
  skeletonHeight = '400px',
  currentStep,
  width,
  isInspectionPlan,
}) => {
  const { t } = useTranslation();

  return (
    <Card backgroundColor={abDesignTest && currentStep === 0 ? 'gray.200' : undefined} boxShadow={abDesignTest ? 'none' : undefined}>
      <Stack spacing="4" p={abDesignTest ? 0 : 8} width={width}>
        {isLoading ? (
          <Skeleton height="40px" />
        ) : isInspectionPlan ? (
          <HStack my={-3}>
            <Stack justifyContent={'center'} alignItems={'center'} mr={5}>
              <Icon as={IoIosCar} boxSize={14} />
            </Stack>

            <VStack align="start">
              <Heading size="xs">{currentStep === 1 ? t('forms:ip.download.downlaod_fill_header') : t('forms:ip.header')}</Heading>
              <Text color="muted" fontSize="sm">
                {currentStep === 1 ? t('forms:ip.download.step_description') : t('forms:ip.description')}
              </Text>
            </VStack>
          </HStack>
        ) : (
          <>
            {' '}
            <Heading size="xs">{stepHeader}</Heading>
            <Text color="muted" fontSize="sm">
              {stepDescription}
            </Text>
          </>
        )}
      </Stack>
      <Divider />
      <Stack direction={['column']} paddingBlock={4}>
        {isLoading ? (
          <Box paddingInline={8}>
            <Skeleton height={skeletonHeight} />
          </Box>
        ) : (
          children
        )}
      </Stack>
    </Card>
  );
};
