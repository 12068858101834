import PageMetatags from 'layout/PageMetatags';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContentCard } from '../../../layout/ContentCard';
import AccountUpdateForm from './AccountUpdateForm';

export interface IAccountUpdateDataPage {}

export const AccountUpdateDataPage: React.FC<IAccountUpdateDataPage> = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageMetatags title={`${t('workshop:update.label')}`}>
        <script src="https://js.chargebee.com/v2/chargebee.js"></script>
      </PageMetatags>
      <>
        <ContentCard
          header={t('workshop:update.title')}
          contentDescription={t('workshop:update.subtitle')}
        >
          <AccountUpdateForm />
        </ContentCard>
      </>
    </>
  );
};

export default AccountUpdateDataPage;
