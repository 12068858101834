import { Box, Container, ScaleFade, Stack, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';

export interface InfoCardProps {
  children?: React.ReactNode;
  bgColor?: string;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  children,
  bgColor = 'orange.100',
}) => {
  return (
    <ScaleFade in={true} initialScale={0.9} unmountOnExit>
      <Box
        bgColor={bgColor}
        boxShadow={useColorModeValue('lg', 'lg-dark')}
        borderRadius="lg"
        position="relative"
        flex={1}
      >
        <Container py={{ base: '4', md: '3.5' }} maxW={'unset'}>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justify="center"
            spacing={{ base: '0.5', md: '1.5' }}
            pe={{ base: '4', sm: '0' }}
            justifyContent="space-between"
          >
            {children}
          </Stack>
        </Container>
      </Box>
    </ScaleFade>
  );
};
