import { ListIcon, ListItem } from '@chakra-ui/react';
import * as React from 'react';
import { MdOutlineCheck, MdOutlineClose } from 'react-icons/md';

export const Requirement: React.FC<{
  fulfilled: boolean;
  caption: string;
}> = ({ fulfilled, caption }) => {
  return (
    <ListItem>
      <ListIcon
        as={fulfilled ? MdOutlineClose : MdOutlineCheck}
        color={fulfilled ? 'error' : 'green.500'}
      />
      {caption}
    </ListItem>
  );
};

export default Requirement;
