import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { Results, ShowVehicle } from '../../api/types';
import config from '../../config';
import { useAxios } from '../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';

export enum SERVICE_STATE {
  OPEN = 'open',
  ACTIVE = 'active',
  PROCESSING = 'in_progress',
  COMPLETED = 'past',
}

async function getVehicle(axios: AxiosInstance, vin: string): Promise<Results<ShowVehicle>> {
  const { data } = await axios.get(`${config.apiBaseUrl}/workshop/vehicles/${vin}`);
  return data;
}

export default function useVehicleQuery(vin: string) {
  const toast = useToast();
  const { t } = useTranslation();
  const axios = useAxios();
  return useQuery(getKey(vin), () => getVehicle(axios, vin), {
    enabled: !!vin,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}

function getKey(vin: string): QueryKey {
  return ['Vehicle', vin];
}

useVehicleQuery.getKey = getKey;
