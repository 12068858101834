import { Box, Button, Container, Flex, Icon, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { buildForm, buildValidationSchema } from 'helpers/general';
import useFileUploadMutation from 'hooks/private/mutations/useFileUploadMutation';
import { get, merge } from 'lodash';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import { LooseObject, Vehicle } from '../../api/types';

export type Group = {
  icon: string;
  label: string;
  fields: Field[];
};
export type Field = {
  name: string;
  validations: any;
  params?: object;
  id: number;
  label: string;
  position: number;
  description: string;
  type: string;
  children: Field[];
  display: {
    deps: [];
    type: string;
  };
};
export type FormSchema = {
  id: number;
  version: string;
  slug: string;
  sections: Array<{
    id: number;
    groups: Group[];
  }>;
};

export const MakeSpecificDataStep = (props: {
  formSchema: FormSchema | undefined;
  backendErrors: object;
  lastSectionSeen: number;
  stepTwoMakeCheckboxData: object;
  // eslint-disable-next-line no-unused-vars
  saveInState: (data: any) => void;
  // eslint-disable-next-line no-unused-vars
  setStep: (step: number) => void;
  setLastSectionSeen: SetStateAction<any>;
  vehicle: Vehicle;
  setInvoiceUuid: React.Dispatch<React.SetStateAction<string>>;
  setInspectionPlanUuid: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const {
    vehicle,
    backendErrors,
    stepTwoMakeCheckboxData,
    setStep,
    saveInState,
    lastSectionSeen,
    formSchema,
    setLastSectionSeen,
    setInvoiceUuid,
    setInspectionPlanUuid,
  } = props;
  const [section, setSection] = useState(lastSectionSeen);
  const savedData = { ...stepTwoMakeCheckboxData };
  const [changeSection, setChangeSection] = useState<SetStateAction<any>>();
  const sections = formSchema?.sections || [];
  const totalSections = sections?.length;
  const schema = buildValidationSchema(get(sections?.[section], 'groups', []));

  const { handleSubmit, formState, setValue, watch, register, control, reset, setFocus, getFieldState } = useForm({
    defaultValues: savedData,
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const [customErrorMessage, setCustomErrorMessage] = useState('');
  const errors = merge(formState.errors, backendErrors);

  const fileUploadMutation = useFileUploadMutation();

  const inspectionPlanWatch = watch('inspection_plan');
  const invoiceWatch = watch('invoice');

  useEffect(() => {
    if (invoiceWatch !== undefined && typeof invoiceWatch !== 'string') {
      const formData = new FormData();
      // @ts-ignore
      formData.append('file', invoiceWatch?.file as File);

      (async () => {
        const fileData = await fileUploadMutation.mutateAsync(formData);
        setInvoiceUuid(fileData?.data?.uuid);
      })();
    }
  }, [invoiceWatch]);

  useEffect(() => {
    if (inspectionPlanWatch !== undefined && typeof inspectionPlanWatch !== 'string') {
      const formData = new FormData();
      // @ts-ignore
      formData.append('file', inspectionPlanWatch?.file as File);

      (async () => {
        const fileData = await fileUploadMutation.mutateAsync(formData);
        setInspectionPlanUuid(fileData?.data?.uuid);
      })();
    }
  }, [inspectionPlanWatch]);

  useEffect(() => {
    reset(section);
    setChangeSection(null);
  }, [section]);

  useEffect(() => {
    if (changeSection === 'next') {
      const nextSection = section + 1;
      if (nextSection >= totalSections) {
        setStep(2);
      } else {
        setLastSectionSeen(nextSection);
        setSection(nextSection);
      }
      setChangeSection(null);
    }
    if (changeSection === 'prev') {
      const nextSection = section - 1;
      if (nextSection < 0) {
        setLastSectionSeen(0);
        setStep(0);
      } else {
        setSection(nextSection);
      }
      setChangeSection(null);
    }
  }, [changeSection]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field: any, a: any) => {
      // @ts-ignore
      return errors[field] ? field : a;
    }, null);
    if (firstError) {
      try {
        // @ts-ignore
        setFocus(firstError);
      } catch (e) {
        console.log('missing ref for field ' + firstError);
      }
    }
  }, [errors, setFocus]);

  /**
   * check at least one field is set validation :
   *
   * for the case of a dropdown like "daimler_inspection_scope",
   * the filed choice is dependent on the children that are required,
   * besides intermidiate, so the user can only choose it and continue with service record request
   *
   * not sure if false should be added to the default case or just keeping it with a break
   * */
  const onSubmit = (formData: LooseObject) => {
    const hasSetField = Object.values(formData).some((inputValue) => {
      switch (typeof inputValue) {
        case 'undefined':
          return false;
        case 'boolean':
          return inputValue === true;
        case 'string':
          return inputValue !== '' && inputValue !== undefined && inputValue !== null;
        case 'number':
          return inputValue !== undefined && inputValue !== null;
        case 'object':
          return inputValue !== undefined && inputValue !== null;
        default:
          break;
      }
    });
    if (!hasSetField) {
      setCustomErrorMessage(t('forms:service_record.step_two.fields.no_field_set'));
      return;
    }

    saveInState(formData);
    setChangeSection('next');
  };

  return (
    <Container as="form" onSubmit={handleSubmit(onSubmit)} data-test-id="service-record-make-specific-form" maxWidth={'unset'} m={0}>
      <Stack direction={['column']} w={{ base: 'full', lg: '50%' }} spacing={4}>
        {buildForm(sections?.[section], {
          control,
          register,
          errors,
          watch,
          vehicle,
          setValue,
          getFieldState,
          reset,
          setCustomErrorMessage,
        })}
      </Stack>
      <Box textAlign={'right'} px={{ base: '4', md: '6' }} height={10}>
        {customErrorMessage && <Box color={'error'}>{customErrorMessage}</Box>}
      </Box>
      <Flex direction="row-reverse" pt={8} pb={4}>
        <Button
          type="submit"
          variant="primary"
          disabled={customErrorMessage.length > 0 || fileUploadMutation.isLoading}
          data-test-id="service-record-make-specific-next-button"
          isLoading={fileUploadMutation.isLoading}
        >
          {t('common:next')}
          <Icon as={FiArrowRight} boxSize="4" />
        </Button>
        <Button
          onClick={() => {
            setChangeSection('prev');
          }}
          variant="outline"
          mr={{ base: 0, md: 2 }}
          data-test-id="service-record-make-specific-back-button"
        >
          <Icon as={FiArrowLeft} boxSize="4" />
          {t('common:back')}
        </Button>
      </Flex>
    </Container>
  );
};
