import { withAuthenticationRequired } from '@auth0/auth0-react';
import { get } from 'lodash';
import React from 'react';

import AuthOnRedirecting from './AuthOnRedirecting';

interface ProtectedRouteProps {
  component: Element | any;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
  const Component = get(window, 'Cypress')
    ? component
    : withAuthenticationRequired(component, {
        onRedirecting: () => <AuthOnRedirecting />,
      });

  return <Component />;
};
