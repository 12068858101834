/* eslint-disable react/jsx-key */
import { Box, Heading, Image, List, ListItem, Stack } from '@chakra-ui/react';
import { get, intersection, isBoolean, map, pick, property } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { GroupField, LooseObject } from '../../api/types';
import IndentionBox from '../../components/form-elements/IndentionBox';
import { displayRow, renderValue } from '../../helpers/general';
import { Field, FormSchema } from './MakeSpecificDataStep';

interface IServiceRecordDataProps {
  record: LooseObject;
  schema: FormSchema | undefined;
  timezone?: string;
  isLoading?: boolean;
}
function flattenArray(arr) {
  const result = [];
  arr?.forEach((obj) => {
    result.push(obj);
    if (obj?.children && obj?.children?.length > 0) {
      obj?.children?.forEach((childObj) => {
        result.push(childObj);
        if (childObj?.children && childObj?.children?.length > 0) {
          result.push(...flattenArray(childObj?.children));
        }
      });
    }
  });
  return result;
}

export const ServiceRecordData: React.FC<IServiceRecordDataProps> = (props) => {
  const { record, schema, timezone = 'Europe/Vienna', isLoading = false } = props;
  const { t } = useTranslation();

  //config differently when we use sections
  const groups = get(schema, 'sections[0].groups', []);

  return (
    <>
      {groups?.map((group: GroupField) => {
        const overlap = intersection(Object.keys(record), map(group?.fields, property('name')));

        if (group?.fields?.length > 0 && overlap?.length > 0 && Object.values(pick(record, overlap)).some((x) => x)) {
          const fields = flattenArray(group?.fields);
          return (
            <Box>
              <Stack direction={'row'} alignItems={'center'} spacing={3}>
                <Image ml={2} boxSize={10} src={group?.icon} />,
                <Heading fontSize="xxs" lineHeight={1}>
                  {get(group, 'label')}
                </Heading>
              </Stack>
              <IndentionBox>
                <List>
                  {// @ts-ignore
                  fields?.map((field: Field, index) => {
                    const fieldKey = field?.name;
                    if (record[field?.name] === false || record[field?.name] === '') {
                      return null;
                    } else {
                      if (
                        fieldKey === 'oil_viscosity_low_temp' &&
                        record['oil_viscosity_low_temp'] !== null &&
                        record['oil_viscosity_low_temp'] !== undefined
                      ) {
                        return (
                          <ListItem key={index}>
                            {displayRow({
                              label: t('forms:oil_viscosity.label'), // @ts-ignore
                              content: `${record['oil_viscosity_low_temp']}W${record['oil_viscosity_high_temp']}`,
                              isLoading,
                            })}
                          </ListItem>
                        );
                      }

                      if (fieldKey === 'oil_viscosity_high_temp') {
                        return null;
                      }
                      if (
                        field?.label === 'Default service interval: mileage' &&
                        record['Default service interval: mileage'] !== null &&
                        record['Default service interval: mileage'] !== undefined
                      ) {
                        return (
                          <ListItem key={index}>
                            {displayRow({
                              label: get(field, 'label'),
                              // @ts-ignore
                              content: record[fieldKey],
                              // @ts-ignore
                              noColon: isBoolean(record[fieldKey]),
                              isLoading,
                              appendUnits: true,
                            })}
                          </ListItem>
                        );
                      }
                    }

                    return (
                      <ListItem key={index}>
                        {record[fieldKey] !== undefined &&
                          displayRow({
                            label: get(field, 'label'),
                            content: record[fieldKey] !== undefined ? renderValue(record[fieldKey], timezone, field, t) : null,
                            //@ts-ignore
                            noColon: isBoolean(record[fieldKey]),
                            isLoading,
                          })}
                      </ListItem>
                    );
                  })}
                </List>
              </IndentionBox>
            </Box>
          );
        }
        return null;
      })}
    </>
  );
};

export default ServiceRecordData;
