import { useAuth0 } from '@auth0/auth0-react';
import * as React from 'react';
import { useEffect } from 'react';

import { LoadingSpinner } from '../components/LoadingSpinner';
import config from '../config';
import { useUserContext } from '../context/UserContextProvider';

export const LogoutPage = () => {
  const { logout } = useAuth0();
  const userContext = useUserContext();

  useEffect(() => {
    userContext.logout();
    logout({ returnTo: config.logoutReturnTo.toString() });
  }, []);

  return <LoadingSpinner />;
};
