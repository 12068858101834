import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { Card } from 'layout/Card';
import PageMetatags from 'layout/PageMetatags';
import { PublicPageLayout } from 'layout/PublicPageLayout';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import GetInTouchCard from '../../../components/GetInTouchCard';

export const WelcomeDeactivatedCustomerPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PublicPageLayout>
      <PageMetatags title={t('workshop:welcome.existing.meta.title')} />
      <Card p={{ base: 2, lg: 10 }} mb={4} textAlign="left">
        <Stack spacing={3}>
          <Heading as="h2" size="md" p={2} textAlign="center">
            {t('workshop:welcome.deactivated.header')}
          </Heading>
          <Text color="muted" fontSize="sm" p={2} whiteSpace={'pre-wrap'}>
            {t('workshop:welcome.deactivated.description')}
          </Text>
          <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}></Flex>
        </Stack>
      </Card>

      <GetInTouchCard />
    </PublicPageLayout>
  );
};
export default WelcomeDeactivatedCustomerPage;
