import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import useVehicleQuery from 'hooks/queries/useVehicleQuery';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import config from '../../../config';
import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

async function update(
  axios: AxiosInstance,
  serviceHistoryRequestData: any,
): Promise<object> {
  return await axios.post(
    `${config.apiBaseUrl}/workshop/service-history-requests`,
    serviceHistoryRequestData,
    { timeout: 3000 },
  );
}

export default function useCreateServiceHistoryRequestMutation(vin: string) {
  const axios = useAxios();
  const t = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(
    (serviceHistoryRequestData: any) => update(axios, serviceHistoryRequestData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
      },
      onError: (err: any) => {
        manageErrorResponse({ toastInstance: toast, error: err.response, t });
      },
    },
  );
}
