import { Box, Text } from '@chakra-ui/react';
import { get } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { PartialStateCode } from '../api/types';

export const StatusReason = (props: {
  resourceKey: string;
  statusCodes: PartialStateCode<number>;
  reasons?: PartialStateCode<number>;
  currentStatus?: number;
  currentStatusReason?: number;
  hideDescription?: boolean;
}) => {
  const { t } = useTranslation();
  const {
    resourceKey,
    statusCodes = {},
    currentStatus,
    currentStatusReason,
    reasons,
  } = props;

  if (!currentStatus) {
    return null;
  }

  return (
    <Box>
      {reasons && currentStatusReason ? (
        <Text>
          {t(
            `status:${resourceKey}.${get(statusCodes, currentStatus, 'unknown')}.${get(
              reasons[currentStatus],
              currentStatusReason,
              'unknown',
            )}.label`,
          )}
        </Text>
      ) : null}
    </Box>
  );
};
