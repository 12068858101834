import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import config from 'config';
import useVehicleQuery from 'hooks/queries/useVehicleQuery';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

export type IPStatusType = 0 | 200 | 700;

async function updateInspectionPlanStatus(
  axios: AxiosInstance,
  vin: string,
  id: string,
  status: IPStatusType,
): Promise<object> {
  const { data } = await axios.put(
    `${config.apiBaseUrl}/workshop/vehicles/${vin}/inspection-plans/${id}/status`,
    { status },
  );
  return data;
}

export default function useUpdateInspectionPlanStatusMutation(vin: string, id: string) {
  const queryClient = useQueryClient();
  const axios = useAxios();
  const toast = useToast();
  const { t } = useTranslation();

  const handleSuccess = () => {
    queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
  };

  const handleError = (err: any) => {
    manageErrorResponse({ toastInstance: toast, error: err.response, t });
  };

  return useMutation<unknown, unknown, IPStatusType>(
    (status: IPStatusType) => updateInspectionPlanStatus(axios, vin, id, status),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    },
  );
}
