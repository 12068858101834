import { CalendarIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Input as InputComponent,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react';
import cuid from 'cuid';
import { cs, da, de, enUS, es, fi, fr, it, nb, pt, sv } from 'date-fns/locale';
import {
  Calendar,
  DateObj,
  GetBackForwardPropsOptions,
  RenderProps,
  useDayzed,
} from 'dayzed';
import { isEmpty, isNil, map, merge } from 'lodash';
import React, {
  Fragment,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { useUserContext } from '../context/UserContextProvider';
import dateTransformer, { dateTransformerNoTimezone } from '../helpers/dateTransformer';

export const MONTH_NAMES_DEFAULT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const DAY_NAMES_DEFAULT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const DATE_FORMAT_DEFAULT = 'yyyy-MM-dd HH:mm:ss';

interface SingleDatepickerBackButtonsProps {
  calendars: Calendar[];
  // eslint-disable-next-line no-unused-vars
  getBackProps: (data: GetBackForwardPropsOptions) => Record<string, any>;
}

interface SingleDatepickerForwardButtonsProps {
  calendars: Calendar[];
  // eslint-disable-next-line no-unused-vars
  getForwardProps: (data: GetBackForwardPropsOptions) => Record<string, any>;
}

export interface SingleDatepickerProps {
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  onDateChange: SetStateAction<any>;
  id?: string;
  name?: string;
  date?: Date;
  maxDate?: Date;
  minDate?: Date;
  configs?: Partial<SingleDatepickerConfigs>;
  placeholder?: string;
}

export interface SingleDatepickerConfigs {
  dateFormat: string;
  monthNames: string[];
  dayNames?: string[];
}

export interface GridDatePickerProps {
  placeholder: string;
  date: Date;
  name: string;
  disabled: boolean;
  id: number;
  setSelectedDate: string;
  currentView: any;
  setCurrentView: any;
  setChosenYear: any;
  partiallyDateSaved: any;
  updatePartiallyDateSaved: any;
}

const transformDisplayDate = (value: any) => {
  const dateMatches =
    value
      .toString()
      .match(
        /^((0[1-9]|[12][012345678]|19)\.(0[1-9]|1[012])|29\.(0[13-9]|1[012])|30\.(0[13-9]|1[012])|31\.(0[13578]|1[02]))\.(19|20)\d\d|29\.02\.(19|20)([02468][048]|[13579][26])$/gm,
      ) ?? [];
  if (dateMatches.length > 0) {
    const chunks = value.split('.');
    if (chunks[2].length > 4) {
      return false;
    } else {
      return new Date(chunks[2], chunks[1] - 1, chunks[0], 12, 0, 0);
    }
  }
  return false;
};

const SingleDatepickerBackButtons = (props: SingleDatepickerBackButtonsProps) => {
  const { calendars, getBackProps } = props;
  return (
    <Fragment>
      <Button
        {...getBackProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        {'<<'}
      </Button>
      <Button {...getBackProps({ calendars })} variant="ghost" size="sm">
        {'<'}
      </Button>
    </Fragment>
  );
};

const SingleDatepickerForwardButtons = (props: SingleDatepickerForwardButtonsProps) => {
  const { calendars, getForwardProps } = props;
  return (
    <Fragment>
      <Button {...getForwardProps({ calendars })} variant="ghost" size="sm">
        {'>'}
      </Button>
      <Button
        {...getForwardProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        {'>>'}
      </Button>
    </Fragment>
  );
};

const SingleDatepickerCalendar = (
  props: RenderProps & { configs: SingleDatepickerConfigs },
) => {
  const { calendars, getDateProps, getBackProps, getForwardProps, configs } = props;

  if (isEmpty(calendars)) {
    return null;
  }

  return (
    <HStack className="datepicker-calendar">
      {map(calendars, (calendar) => {
        return (
          <VStack key={`${calendar.month}${calendar.year}`}>
            <HStack>
              <SingleDatepickerBackButtons
                calendars={calendars}
                getBackProps={getBackProps}
              />
              <Heading size="sm" textAlign="center">
                {configs?.monthNames[calendar.month]} {calendar.year}
              </Heading>
              <SingleDatepickerForwardButtons
                calendars={calendars}
                getForwardProps={getForwardProps}
              />
            </HStack>
            <Divider />
            <SimpleGrid columns={7} spacing={2} textAlign="center">
              {map(configs.dayNames, (day) => (
                <Box key={`${calendar.month}${calendar.year}${day}`}>
                  <Text fontSize="sm" fontWeight="semibold">
                    {day}
                  </Text>
                </Box>
              ))}
              {map(calendar.weeks, (week, weekIndex) => {
                return map(week, (dateObj: DateObj, index) => {
                  const {
                    date,
                    today,
                    // prevMonth,
                    // nextMonth,
                    selected,
                  } = dateObj;
                  const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;

                  const propsForButton = {
                    ...dateObj,
                    date: new Date(
                      date.getFullYear(),
                      date.getMonth(),
                      date.getDate(),
                      new Date().getHours(),
                      new Date().getMinutes(),
                      new Date().getSeconds(),
                    ),
                  };
                  return (
                    <Button
                      {...getDateProps({ dateObj: propsForButton })}
                      key={key}
                      size="sm"
                      variant="outline"
                      borderColor={today ? 'purple.400' : 'transparent'}
                      bg={selected ? 'purple.200' : undefined}
                    >
                      {date.getDate()}
                    </Button>
                  );
                });
              })}
            </SimpleGrid>
          </VStack>
        );
      })}
    </HStack>
  );
};
const defaultConfigs = {
  dateFormat: DATE_FORMAT_DEFAULT,
  monthNames: MONTH_NAMES_DEFAULT,
  dayNames: DAY_NAMES_DEFAULT,
};

export const GridDatePicker: React.FC<GridDatePickerProps> = (props: {
  placeholder: string;
  date: Date;
  name: string;
  disabled: boolean;
  id: number;
  setSelectedDate: string;
  currentView: any;
  setCurrentView: any;
  setChosenYear: any;
  partiallyDateSaved: any;
  updatePartiallyDateSaved: any;
}) => {
  const {
    placeholder,
    date,
    name,
    disabled,
    id,
    setSelectedDate,
    currentView,
    setCurrentView,
    partiallyDateSaved,
    updatePartiallyDateSaved,
  } = props;
  const customDatePickerStyles = (currentView: string) => {
    return {
      '.react-datepicker': {
        width: '500px',
        fontFamily: '"Inter", sans-serif',
        boxShadow: 'none',
        color: '#4A5568',
      },
      '.react-datepicker__aria-live': {
        display: 'none',
      },
      '.react-datepicker__header': {
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontWeight: '700',
        backgroundColor: 'accent',
        borderBottom: '1px solid #CBD5E0',
        marginBottom: '10px',
        padding: '5px',
      },
      '.react-datepicker__day-names': {
        color: 'white',
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: '5px',
      },
      '.react-datepicker__month': {
        display: 'grid',
        gridTemplateColumns:
          currentView === 'month' ? 'repeat(4, 1fr)' : 'repeat(7, 1fr)',
        gap: '10px',
      },
      '.react-datepicker__week': {
        display: 'contents',
      },
      '.react-datepicker__day': {
        margin: '3px',
        width: '90%',
        color: '#2D3748',
        border: '1px solid transparent',
        fontSize: '14px',
        padding: '0.4rem',
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
          bgColor: 'blue.200',
          fontWeight: 'bold',
          width: '90%',
        },
      },
      '.react-datepicker__month-text': {
        margin: '3px',
        width: '90%',
        fontSize: '14px',
        border: '1px solid transparent',
        padding: '0.4rem',
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
          bgColor: 'blue.200',
          fontWeight: 'bold',
          width: '90%',
        },
      },
      '.react-datepicker__year-text': {
        margin: '3px',
        width: '90%',
        color: '#2D3748',
        //border: '1px solid transparent',
        fontSize: '14px',
        padding: '0.4rem',
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
          bgColor: 'blue.200',
          fontWeight: 'bold',
          width: '90%',
        },
      },
      '.react-datepicker__day--selected': {
        backgroundColor: '#3182CE',
        color: 'white',
      },
      '.react-datepicker__month-year': {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '10px',
      },
      '.react-datepicker__year-wrapper': {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '10px',
      },
      '.react-datepicker__current-month': {
        display: 'none',
        color: '#2D3748',
        fontSize: '16px',
        fontWeight: 'bold',
      },
      '.react-datepicker__navigation--previous': {
        display: 'flex',
        borderRightColor: '#A0AEC0',
      },
      '.react-datepicker__navigation--next': {
        borderLeftColor: '#A0AEC0',
        display: 'flex',
      },
      '.react-datepicker__header__dropdown': {
        display: 'none',
      },
      '.react-datepicker__month-read-view': {
        display: 'none',
      },
      '.react-datepicker__year-read-view': {
        display: 'none',
      },
      '.react-datepicker__month-read-view--down-arrow': {
        display: 'none',
      },
      '.react-datepicker__year-read-view--down-arrow': {
        display: 'none',
      },
      '.react-datepicker__year-text--disabled': {
        color: '#d3d3d3',
        cursor: 'cursor',
        transition: 'none',
        '&:hover': {
          color: '#d3d3d3',
          bgColor: 'white',
          fontWeight: 'normal',
        },
      },
      '.react-datepicker__month-text--disabled': {
        color: '#d3d3d3',
        cursor: 'cursor',
        transition: 'none',
        '&:hover': {
          color: '#d3d3d3',
          bgColor: 'white',
          fontWeight: 'normal',
        },
      },
      '.react-datepicker__day-text--disabled': {
        color: '#d3d3d3',
        cursor: 'cursor',
        transition: 'none',
        '&:hover': {
          color: '#d3d3d3',
          bgColor: 'white',
          fontWeight: 'normal',
        },
      },
      'react-datepicker__month--disabled': {
        color: '#d3d3d3 !important',
        '&:hover': {
          color: 'black.400',
        },
      },
      '.react-datepicker__day--disabled': {
        color: '#d3d3d3',
        '&:hover': {
          color: 'black.400',
        },
      },
    };
  };
  registerLocale('cs', cs);
  registerLocale('pt', pt);
  registerLocale('da', da);
  registerLocale('de', de);
  registerLocale('en', enUS);
  registerLocale('es', es);
  registerLocale('fi', fi);
  registerLocale('fr', fr);
  registerLocale('it', it);
  registerLocale('no', nb);
  registerLocale('sv', sv);
  const userContext = useUserContext();
  const workshopLanguage = userContext?.workshop?.language.toLowerCase();
  const { t } = useTranslation();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const ref = useRef<HTMLElement>(null);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const icon: ReactNode = <CalendarIcon fontSize="sm" />;
  useOutsideClick({
    ref: ref,
    handler: () => setPopoverOpen(false),
  });
  const lastChosenYear = partiallyDateSaved[partiallyDateSaved.length - 1];
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    const chosenYearLocal = new Date(date).getFullYear();
    updatePartiallyDateSaved(chosenYearLocal);
  }, [date]);
  const handleView = (date: any) => {
    setSelectedDate(date);
    if (currentView === 'day') {
      setCurrentView('year');
      setPopoverOpen(!popoverOpen);
    }
    setCurrentView(currentView === 'month' ? 'day' : 'month');
  };
  useEffect(() => {
    if (!popoverOpen) {
      return setCurrentView('year');
    }
  }, [popoverOpen]);
  const renderMonthContentWithMonthsDisabled = (month: number, shortMonth: string) => {
    const currentMonth = new Date().getMonth();
    return <Text color={month > currentMonth && 'black.400'}>{shortMonth}</Text>;
  };
  const renderMonthContent = (month: number, shortMonth: string) => {
    return <Text>{shortMonth}</Text>;
  };
  return (
    <>
      <Popover
        placement="bottom-start"
        variant="responsive"
        isOpen={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        initialFocusRef={initialFocusRef}
        isLazy
      >
        <PopoverTrigger>
          <InputGroup>
            <InputComponent
              id={id}
              key={'date-' + cuid()}
              autoComplete="off"
              isDisabled={disabled}
              ref={initialFocusRef}
              onClick={() => setPopoverOpen(!popoverOpen)}
              name={name}
              placeholder={placeholder}
              _placeholder={{ opacity: 0.5 }}
              value={date ? dateTransformer(date) : null}
            />
            <InputRightElement
              color="gray.500"
              onClick={() => setPopoverOpen(!popoverOpen)}
            >
              {icon}
            </InputRightElement>
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent ref={ref} minWidth={'500px'} marginRight={2}>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
            border="1px"
            borderColor={'accent'}
          >
            <Box sx={customDatePickerStyles(currentView)}>
              <DatePicker
                locale={workshopLanguage}
                renderMonthContent={
                  lastChosenYear === currentYear
                    ? renderMonthContentWithMonthsDisabled
                    : renderMonthContent
                }
                onChange={(date) => {
                  handleView(date);
                }}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                showYearPicker={currentView === 'year'}
                showMonthYearPicker={currentView === 'month'}
                inline
                renderCustomHeader={({
                  monthDate,
                  increaseYear,
                  decreaseYear,
                  decreaseMonth,
                  increaseMonth,
                }) => (
                  <>
                    <HStack justifyContent={'space-between'}>
                      <Button
                        padding={1}
                        ml={1}
                        size={'sm'}
                        fontSize={'12px'}
                        bgColor={'accent'}
                        color={'white'}
                        aria-label="Previous Month"
                        className={
                          'react-datepicker__navigation react-datepicker__navigation--previous'
                        }
                        sx={currentView !== 'year' ? { visibility: 'hidden' } : null}
                        _hover={{
                          bg: 'white',
                          color: 'accent',
                          transform: 'scale(1.05)',
                          transition: 'all 0.2s ease-in-out',
                        }}
                        onClick={currentView === 'year' ? decreaseYear : decreaseMonth}
                      >
                        {'<'}
                      </Button>
                      <Text
                        color={'white'}
                        mb={currentView === 'day' ? 5 : 0}
                        fontSize={'18px'}
                        fontWeight={'bold'}
                      >
                        {currentView === 'year'
                          ? t(
                              'forms:initial_registration_date.select_date.date_picker.select_a_year',
                            )
                          : currentView === 'month'
                            ? t(
                                'forms:initial_registration_date.select_date.date_picker.select_a_month',
                              )
                            : t(
                                'forms:initial_registration_date.select_date.date_picker.select_a_day',
                              )}
                      </Text>
                      <Box className="react-datepicker__current-month">
                        {monthDate.toLocaleString('en-US', {
                          month: 'long',
                          year: 'numeric',
                        })}
                      </Box>
                      <Button
                        padding={1}
                        ml={1}
                        size={'sm'}
                        fontSize={'12px'}
                        bgColor={'accent'}
                        color={'white'}
                        aria-label="Next Month"
                        className={
                          'react-datepicker__navigation react-datepicker__navigation--next'
                        }
                        sx={currentView !== 'year' ? { visibility: 'hidden' } : null}
                        _hover={{
                          bg: 'white',
                          color: 'accent',
                          transform: 'scale(1.05)',
                          transition: 'all 0.2s ease-in-out',
                        }}
                        onClick={currentView === 'year' ? increaseYear : increaseMonth}
                      >
                        {'>'}
                      </Button>
                    </HStack>
                  </>
                )}
              />
            </Box>
          </Flex>
        </PopoverContent>
      </Popover>
    </>
  );
};

export const SingleDatepicker: React.FC<SingleDatepickerProps> = ({
  configs = defaultConfigs,
  ...props
}) => {
  const { placeholder, date, minDate, maxDate, name, disabled, onDateChange, id } = props;
  //so that you only have to pass some of the config parameters
  const dateConfigs = merge(defaultConfigs, configs);
  const ref = useRef<HTMLElement>(null);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const icon: ReactNode = <CalendarIcon fontSize="sm" />;

  useOutsideClick({
    ref: ref,
    handler: () => setPopoverOpen(false),
  });

  const onDateSelected = (options: { selectable?: boolean; date: Date | false }) => {
    const { selectable, date } = options;
    if (!selectable) return;
    if (!isNil(date)) {
      onDateChange(date);
      setPopoverOpen(false);
      return;
    }
  };

  const dayzedData = useDayzed({
    showOutsideDays: true,
    onDateSelected,
    selected: date,
    maxDate: maxDate,
    minDate: minDate,
  });

  return (
    <Popover
      placement="bottom-start"
      variant="responsive"
      isOpen={popoverOpen}
      onClose={() => setPopoverOpen(false)}
      initialFocusRef={initialFocusRef}
      isLazy
    >
      <PopoverTrigger>
        <InputGroup>
          <InputComponent
            id={id}
            key={'date-' + cuid()}
            autoComplete="off"
            isDisabled={disabled}
            ref={initialFocusRef}
            onClick={() => setPopoverOpen(!popoverOpen)}
            name={name}
            placeholder={placeholder}
            value={
              date ? dateTransformerNoTimezone(date, dateConfigs.dateFormat) : undefined
            }
            onChange={(e) => {
              const value = transformDisplayDate(e.target.value);
              onDateSelected({
                selectable: true,

                date: value,
              });
            }}
          />
          <InputRightElement
            color="gray.500"
            onClick={() => setPopoverOpen(!popoverOpen)}
          >
            {icon}
          </InputRightElement>
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent ref={ref}>
        <PopoverBody padding={'10px 5px'} borderWidth={1} borderColor="accent">
          <SingleDatepickerCalendar {...dayzedData} configs={dateConfigs} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const SingleDateInput: React.FC<SingleDatepickerProps> = ({
  configs = defaultConfigs,
  ...props
}) => {
  const { placeholder, date, minDate, maxDate, name, disabled, onDateChange, id } = props;
  //so that you only have to pass some of the config parameters
  const dateConfigs = merge(defaultConfigs, configs);
  const initialFocusRef = useRef<HTMLInputElement>(null);

  const onDateSelected = (options: { selectable?: boolean; date: Date | false }) => {
    const { date } = options;
    if (!isNil(date)) {
      onDateChange(date);
      return;
    }
  };
  return (
    <InputComponent
      id={id}
      autoComplete="off"
      isDisabled={disabled}
      ref={initialFocusRef}
      name={name}
      placeholder={placeholder}
      _placeholder={{ opacity: 0.5 }}
      max={maxDate ? dateTransformerNoTimezone(maxDate, DATE_FORMAT_DEFAULT) : undefined}
      min={minDate ? dateTransformerNoTimezone(minDate, DATE_FORMAT_DEFAULT) : undefined}
      value={date ? dateTransformerNoTimezone(date, dateConfigs.dateFormat) : undefined}
      onChange={(e) => {
        const value = transformDisplayDate(e.target.value);
        onDateSelected({
          date: value,
        });
      }}
    />
  );
};
