import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Grid,
  HStack,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useId } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { simpleCheckBox } from '../../helpers/makeFormFields';
import { ButtonSpinner } from '../LoadingSpinner';

/**
 * Modal with a User Given Consent Checkbox
 * @constructor
 */
export interface IConsentForm {
  consent: any;
}

export interface IConsentModal {
  isLoading?: boolean;
  onSubmit: (data: any) => void;
  onClose?: () => void;
  closeOnOverlayClick?: boolean;
  content?: {
    hide?: boolean;
    icon?: string | React.ReactElement;
    header?: string | React.ReactElement;
    checkbox?: {
      name?: string;
      label?: string;
      description?: string;
    };
    footer?: {
      buttons: {
        cancelCaption?: string;
        actionCaption?: string;
      };
      before?: boolean;
      top?: string | React.ReactElement;
      bottom?: string | React.ReactElement;
    };
  };
  styleConfig: {
    variant?: string;
    minW?: string;
    maxW?: string;
    w?: string;
    motionPreset?: any;
  };
  modal?: any;
  children: any;
  t: any;
}

export const ConfirmationScaffolding = (props: IConsentModal) => {
  const id = useId();
  const {
    isLoading = false,
    content = {},
    styleConfig = {},
    onSubmit,
    onClose: onModalClose = false,
    closeOnOverlayClick = true,
    children,
    modal,
    t,
  } = props;

  const {
    w = 'full',
    maxW = '50rem',
    minW,
    variant = false,
    motionPreset = 'slideInBottom',
  } = styleConfig;

  const {
    hide = false,
    icon = false,
    header = false,
    checkbox = {},
    footer = { buttons: {} },
  } = content;

  const {
    name = `consent-${id}`,
    label = props.t('forms:consent_addonbuy.label'),
    description = undefined,
  } = checkbox;

  const {
    buttons: { cancelCaption = false, actionCaption = t('common:submit') },
    before = false,
    top = false,
    bottom = false,
  } = footer;

  const schema = yup.object({
    [name]: yup.bool().required().oneOf([true], t('common:required')),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, submitCount },
  } = useForm<IConsentForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const onClose = () => {
    if (onModalClose) {
      onModalClose();
    }
    modal.hide();
    modal.remove();
  };
  return (
    <AlertDialog
      onClose={onClose}
      isOpen={modal.visible}
      leastDestructiveRef={cancelRef}
      closeOnOverlayClick={closeOnOverlayClick}
      motionPreset={motionPreset}
    >
      <AlertDialogOverlay sx={{ backdropFilter: 'blur(2rem)' }} border={'none'}>
        <AlertDialogContent
          data-test-id="alert-dialog"
          as={'form'}
          minW={minW}
          w={w}
          maxW={maxW}
          onSubmit={handleSubmit(onSubmit)}
        >
          {!hide ? (
            <AlertDialogHeader>
              <HStack>
                {icon}
                {header ? (
                  <Box px={3} fontWeight={'bold'} fontSize="md">
                    {header}
                  </Box>
                ) : null}
              </HStack>
            </AlertDialogHeader>
          ) : null}

          {closeOnOverlayClick ? (
            <ModalCloseButton data-test-id="alert-dialog-button-close" />
          ) : null}
          {!hide ? <Divider /> : null}
          {children ? (
            <AlertDialogBody>
              {children}
              {!hide &&
                simpleCheckBox({
                  name,
                  label,
                  description,
                  register,
                  errors,
                })}
            </AlertDialogBody>
          ) : null}
          {!hide ? <Divider /> : null}
          {!hide && (
            <Text as={'p'} size="xs" p={2} textAlign={'center'}>
              {top}
            </Text>
          )}
          {!hide ? (
            <AlertDialogFooter p={1}>
              <Grid templateColumns={`repeat(${cancelCaption ? '3' : '2'}, 1fr)`} gap={3}>
                <Box>{before}</Box>
                {cancelCaption && (
                  <Button
                    variant={variant ? `outline-${variant}` : 'outline'}
                    data-test-id="alert-dialog-cancel-button"
                    ref={cancelRef}
                    onClick={onClose}
                    disabled={isLoading}
                  >
                    {cancelCaption}
                  </Button>
                )}
                <Button
                  variant="primary"
                  data-test-id="alert-dialog-action-button"
                  disabled={submitCount > 0 || !isValid || isLoading}
                  type="submit"
                >
                  {actionCaption} {isLoading ? <ButtonSpinner /> : null}
                </Button>
              </Grid>
            </AlertDialogFooter>
          ) : null}
          {!hide ? (
            <Text as={'p'} size="xs" p={2} textAlign={'center'}>
              {bottom}
            </Text>
          ) : null}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default NiceModal.create((props: IConsentModal) => {
  // Use a hook to manage the modal state
  const modal = useModal();
  const { t } = useTranslation();
  // @ts-ignore
  return <ConfirmationScaffolding t={t} {...props} modal={modal} />;
});
