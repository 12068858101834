import { Box, Button, Flex, HStack, Icon, Stack, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import ConversationFileUploadModal from 'components/modals/ConversationFileUploadModal';
import { noop } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiArrowRight } from 'react-icons/fi';
import { ImAttachment } from 'react-icons/im';
import { IoTrash } from 'react-icons/io5';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';

import { ConversationMessageShow, Document, PartialStateCode } from '../../api/types';
import { ConversationBubble } from '../../components/ConversationBubble';
import { dateTimeTransformer } from '../../helpers/dateTransformer';
import { textareaInput } from '../../helpers/makeFormFields';
import useAddMessageToConversationMutation from '../../hooks/private/mutations/useAddMessageToConversationMutation';
import useServiceRecordQuery from '../../hooks/queries/service/useServiceRecordQuery';
export interface IMessageForm {
  text: string | null;
  document: { type: string; file_uuid: string; comment?: string } | null;
}

const ConversationCard = (props: {
  conversationId: number;
  conversation: any;
  sendRequest(y: any): Promise<any>;
  statusCodes: PartialStateCode<string>;
  currentStatus?: number;
  recordId: number;
  serviceRecordId: number;
}) => {
  const {
    conversation: { messages = [] },
    conversationId,
    statusCodes = {},
    serviceRecordId,
    currentStatus = 999,
  } = props;
  const { t } = useTranslation();

  const [fileData, setFileData] = useState<any>(null);
  const schema = yup
    .object({
      text: yup.string().max(255),
      document: yup.object().nullable(),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    setError,
  } = useForm<IMessageForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [openFileModal, setOpenFileModal] = useState(false);

  const queryClient = useQueryClient();

  const addMessageToConversationMutation = useAddMessageToConversationMutation(conversationId);

  useEffect(() => {
    setValue('document', fileData);
  }, [fileData]);

  const onSubmit: SubmitHandler<IMessageForm> = (data) => {
    // if (data.document_id !== null && data.text === '') {
    //   data.text = null;
    // }

    const conversationData = {
      text: data?.text ?? undefined,
      document:
        data?.document === null
          ? undefined
          : {
              type: data?.document?.type ?? undefined,
              file_uuid: data?.document?.file_uuid ?? undefined,
              comment: data?.document?.comment ? undefined : fileData.comment,
            },
    };

    addMessageToConversationMutation.mutate(conversationData, {
      onSuccess: () => {
        queryClient.invalidateQueries(useServiceRecordQuery.getKey(serviceRecordId));
        queryClient.invalidateQueries({ queryKey: ['ServiceEntryConversation'] });
      },
      onError: (error: any) => {
        const { errors } = error.response.data;

        (Object.keys(errors) as Array<keyof IMessageForm>).forEach((key) => {
          setError(key, {
            type: 'server',
            message: errors[key]!.join('. '),
          });
        });
      },
    });
  };

  return (
    <Stack spacing={{ base: 3, lg: 6 }}>
      <Stack direction={'column'} gap={4} data-test-id="conversation-card">
        {messages.length === 0 && <Text alignSelf={'end'}>{t('components:conversation_card.pending')}</Text>}
        {messages.map((message: ConversationMessageShow, index: number) => {
          return (
            <Box width={'70%'} alignSelf={message?.workshop_user_id ? 'flex-end' : 'flex-start'} key={index}>
              <ConversationBubble
                data-test-id={`message-${index}`}
                isOwnMessage={Boolean(message?.workshop_user_id)}
                statusCodes={statusCodes}
                // @ts-ignore
                file={message?.document?.file}
                currentStatus={currentStatus}
                message={message?.translated_text !== null ? message?.translated_text : message?.text}
                author={message?.author}
                timestamp={dateTimeTransformer(message?.created_at)}
                index={index}
                fileId={message?.document?.id}
                serviceRecordId={message?.document?.service_id}
              />
            </Box>
          );
        })}
      </Stack>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} data-test-id="upload-form">
        <Box backgroundColor={'white'} borderRadius={'md'}>
          {textareaInput({
            name: 'text',
            placeholder: t('components:conversation_card.message.placeholder'),
            register,
            errors,
            style: { borderColor: 'white' },
          })}

          {fileData === null ? (
            <VStack alignItems={'start'} p={4}>
              <Button
                leftIcon={<ImAttachment />}
                variant={'outline'}
                borderColor={'currentColor'}
                bg={'transparent'}
                type={'button'}
                _hover={{
                  backgroundColor: 'accent',
                  borderColor: 'accent',
                  color: 'inverted',
                }}
                onClick={() => setOpenFileModal(true)}
              >
                {t('documents:upload')}
              </Button>
              {/* <Text color={'red.500'} fontSize={'sm'}>
                {errors?.document_id?.message}
              </Text> */}
            </VStack>
          ) : (
            <HStack alignItems={'center'} spacing={4} p={4}>
              <Text color={'blue.500'} as="b">
                {fileData.name}
              </Text>
              <Button
                data-test-id={'dropzone-remove-file'}
                size={'lg'}
                variant={'link'}
                colorScheme={'red'}
                onClick={() => setFileData(null)}
                aria-label={'remove file'}
                leftIcon={<Icon as={IoTrash} boxSize="5" />}
              >
                {t('documents:remove')}
              </Button>
            </HStack>
          )}
        </Box>

        <Flex direction="row" py="4" px={{ base: '2', md: '2' }}>
          <ConversationFileUploadModal setFileData={setFileData} openFileModal={openFileModal} setOpenFileModal={setOpenFileModal} />
          <Button
            type="submit"
            variant="primary"
            data-test-id="message-submit-button"
            ml={'auto'}
            // disabled={!!errors.document_id || !!errors.text}
          >
            {t('common:submit')}
            <Icon as={FiArrowRight} boxSize="4" />
          </Button>
        </Flex>
      </Box>
    </Stack>
  );
};

export default ConversationCard;
