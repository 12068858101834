import { useIsWorkshopBlocked } from 'helpers/general';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { useUserContext } from '../../context/UserContextProvider';
import PageMetatags from '../../layout/PageMetatags';
import MyDataBillingEmailCard from './MyDataBillingEmailCard';
import MyDataChangePwCard from './MyDataChangePwCard';
import MyDataWorkshopInfoCard from './MyDataWorkshopInfoCard';
import MyPaymentMethodCard from './MyPaymentMethodCard';

const MyData: React.FC = () => {
  const { t } = useTranslation();
  const userContext = useUserContext();
  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }

  return (
    <>
      <PageMetatags
        title={`${t('workshop:update.label')} ${userContext.workshop?.name}`}
      />
      <MyDataWorkshopInfoCard />
      <MyDataBillingEmailCard />
      <MyDataChangePwCard />
      <MyPaymentMethodCard />
    </>
  );
};

export default MyData;
