import { Heading, HeadingProps } from '@chakra-ui/react';
import * as React from 'react';

interface NavSectionTitleProps extends HeadingProps {
  isLoading?: boolean;
  label: string;
}

export const NavSectionTitle = (props: NavSectionTitleProps) => {
  // eslint-disable-next-line
  const { label, isLoading = false, ...HeadingProps } = props;
  return (
    <Heading as="h2" size="xxs" opacity={0.9} {...HeadingProps} m={0}>
      {label}
    </Heading>
  );
};
