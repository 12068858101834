import { DeleteIcon } from '@chakra-ui/icons';
import { Button, Divider, Flex, Icon, Text, Tooltip, useToast } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { AlertButton } from 'components/buttons/AlertButton';
import { Skeleton } from 'components/Skeleton';
import config from 'config';
import { useAxios } from 'context/AxiosContextProvider';
import { downloadFileFromUrl } from 'helpers/downloadFiles';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import { get, isNil } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IoHourglassSharp } from 'react-icons/all';
import { FaShippingFast } from 'react-icons/fa';
import { IoIosWarning } from 'react-icons/io';
import { MdArrowDownward } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { CHARGE_KEYS, ServiceActivity, StatusCodes } from '../../../../api/types';
import { ServiceRecordIcon } from '../../../../components/Icons';
import AlertModal from '../../../../components/modals/AlertModal';
import BaseServiceTypeTimelineElement from '../../../../components/StandardTimeline/BaseServiceTypeTimelineElement';
import { StatusReason } from '../../../../components/StatusReason';
import { StatusTag } from '../../../../components/StatusTag';
import { STATUS_COMPLETED, STATUS_NEEDS_CLARIFICATION } from '../../../../context/UserContextProvider';
import dateTransformer from '../../../../helpers/dateTransformer';
import { formatMultiCurrencyIntl } from '../../../../helpers/formatCurrency';
import { getStatus } from '../../../../helpers/general';
import getColourSchemeBasedOnStatus from '../../../../helpers/getColourSchemeBasedOnStatus';
import useDeleteServiceRecordMutation from '../../../../hooks/private/mutations/useDeleteServiceRecordMutation';
import useUpdateServiceRecordToFastlaneMutation from '../../../../hooks/private/mutations/useUpdateServiceRecordToFastlaneMutation';
import useServiceRecordQuery from '../../../../hooks/queries/service/useServiceRecordQuery';
import useVehicleQuery from '../../../../hooks/queries/useVehicleQuery';
import { WorkshopData } from '../../../../hooks/queries/workshop/useWorkshopQuery';
import CancelModal from '../CancelModal';

export const ServiceRecordActivityCard = (props: {
  vin: string;
  activity: ServiceActivity;
  noActionsAllowed: boolean;
  statusCodes: StatusCodes;
  isLoading: boolean;
  workshop: WorkshopData | undefined;
  qrContent: any;
}) => {
  const { vin, activity, statusCodes, qrContent, workshop, noActionsAllowed } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const axios = useAxios();
  const queryClient = useQueryClient();

  // the service record object was removed from the activity object why I called the Sr query to get the data
  const serviceRecordQuery = useServiceRecordQuery(activity?.service_record?.id);
  const serviceRecordData = serviceRecordQuery.data?.data;

  const updateServiceRecordToFastlaneMutation = useUpdateServiceRecordToFastlaneMutation(serviceRecordData?.id as number);
  const deleteServiceRecordMutation = useDeleteServiceRecordMutation(serviceRecordData?.id as number, vin);

  const currency = workshop?.currency;
  const status = activity?.service_record?.status;

  const downloadServiceRecord = async (id: number | undefined) => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/workshop/service-records/${id}/confirmation-temp-url`);
      const url = response.data?.data?.url;
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    }
  };

  const units = workshop?.units;
  const isCompleted = status === STATUS_COMPLETED;
  const isDeleted = status === 850;
  const isRequestedDeletion = status === 800;
  const isCreated = status === 0;
  const needsClarification = status === STATUS_NEEDS_CLARIFICATION;
  const codes = get(statusCodes, 'service_record');
  const showCancel = !isNil(status) && [getStatus(codes, 'created'), getStatus(codes, 'submitted')].includes(status);

  const handleCancel = (): any => {
    deleteServiceRecordMutation.mutate();
    navigate('/app/w/vehicle?vin=' + vin);
  };

  const handleShowDetails = () => {
    navigate(`/app/w/service-record/${serviceRecordData?.id}?vin=${vin}`);
  };

  const handleUpdateDetails = () => {
    navigate(`/app/w/service-record/${serviceRecordData?.id}/update?vin=${vin}`);
  };
  const isPayPerUse = workshop?.subscription?.plan?.is_pay_per_use;
  const getCharge = () => {
    return parseInt(
      // @ts-ignore
      get(workshop?.subscription?.plan?.charges, `${CHARGE_KEYS.FASTLANE}.price`, 0),
      10,
    );
  };
  const statusForMessage = get(codes, status);
  return (
    <>
      {serviceRecordQuery.isFetching ? (
        <Skeleton height="200px" />
      ) : (
        <BaseServiceTypeTimelineElement
          id={`service_record_${serviceRecordData?.id}`}
          dataTestId={'service_record_timeline_element'}
          date={dateTransformer(activity?.service_record?.submitted_at ?? activity?.service_record?.created_at)}
          title={`${t('components:service_process_card.service_record.label')}, ${serviceRecordData?.user_readable_id}`}
          statusReason={
            <StatusReason
              statusCodes={codes}
              reasons={statusCodes['service_record_reasons']}
              resourceKey={'service_record'}
              currentStatus={serviceRecordData?.status}
              currentStatusReason={serviceRecordData?.status_reason}
            />
          }
          status={
            <StatusTag
              statusCodes={codes}
              reasons={statusCodes['service_record_reasons']}
              hideDescription
              resourceKey={'service_record'}
              currentStatus={serviceRecordData?.status}
            />
          }
          iconBgColor={needsClarification ? 'orange' : getColourSchemeBasedOnStatus(get(codes, status))}
          iconClassName={needsClarification ? 'active' : ''}
          icon={<Icon as={needsClarification ? IoIosWarning : isRequestedDeletion ? IoHourglassSharp : isDeleted ? DeleteIcon : ServiceRecordIcon} />}
          badge={
            serviceRecordData?.fastlane ? (
              <Tooltip
                label={t('components:service_process_card.service_record.fastlane.tooltip')}
                className={'tour-process-card-service-record-fastlane'}
                shouldWrapChildren
              >
                <Icon as={FaShippingFast} boxSize={10} color={'orange.500'} />
              </Tooltip>
            ) : !isPayPerUse && showCancel ? (
              <>
                <Tooltip label={t('components:service_process_card.service_record.fastlane.upsell.tooltip')} shouldWrapChildren>
                  <Icon
                    as={FaShippingFast}
                    onClick={() =>
                      NiceModal.show(AlertModal, {
                        onSubmit: () => {
                          updateServiceRecordToFastlaneMutation.mutate();
                          return queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
                        },
                        content: {
                          header: t('components:service_process_card.service_record.fastlane.upsell.modal.header'),
                          footer: {
                            buttons: {
                              cancelCaption: t('common:cancel'),
                              actionCaption: t('common:update'),
                            },
                          },
                        },
                        children: (
                          <>
                            {' '}
                            <Text>{t('components:service_process_card.service_record.fastlane.upsell.modal.description')}</Text>
                            <Text>
                              {`${t('forms:fastlane.label')} (${formatMultiCurrencyIntl(
                                getCharge(),
                                currency,
                              )} ${t('pages:subscription.extra_charge')})`}
                            </Text>
                          </>
                        ),
                      })
                    }
                    boxSize={10}
                  />
                </Tooltip>
              </>
            ) : (
              ''
            )
          }
        >
          <Text fontSize="sm" style={{ marginBottom: '-15px' }}>
            <strong>{t('forms:service_date.label')}: </strong> {`${dateTransformer(activity?.date)}`}
          </Text>
          <Text fontSize="sm">
            <strong>{t('forms:mileage.label')}: </strong> {`${serviceRecordData?.mileage} ${units === 'metric' ? 'km' : 'mi'}`}
          </Text>

          <Text as="div" fontSize="sm">
            {statusForMessage ? t(`status:service_record.${statusForMessage}.timeline.message`) : null}
          </Text>
          <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }} gap={4}>
            {isCompleted && (
              <Button
                leftIcon={<MdArrowDownward />}
                variant="primary"
                ml={2}
                data-test-id={`service_record_${serviceRecordData?.id}_timeline_download_button`}
                onClick={() =>
                  downloadFileFromUrl(
                    axios,
                    `${config.apiBaseUrl}/workshop/service-records/${serviceRecordData?.id}/confirmation-download`,
                    'Service Record.pdf',
                  )
                }
              >
                {t('common:download')}
              </Button>
            )}

            {isCreated && (
              <Button
                variant={'primary'}
                color={'white'}
                className={'tour-process-card-service-record-details'}
                data-test-id={`service_record_${serviceRecordData?.id}_timeline_details_button`}
                onClick={() => handleUpdateDetails()}
              >
                {t('common:edit')}
              </Button>
            )}
            {showCancel && !noActionsAllowed && !isPayPerUse && <CancelModal handleCancel={handleCancel} />}
            {needsClarification ? (
              <AlertButton onClick={() => handleShowDetails()} data-test-id={`service_record_${serviceRecordData?.id}_timeline_details_button`}>
                {t('common:details')}
              </AlertButton>
            ) : isCreated ? null : (
              <Button
                variant={'outline'}
                color={'accent'}
                bgColor={'primary'}
                className={'tour-process-card-service-record-details'}
                data-test-id={`service_record_${serviceRecordData?.id}_timeline_details_button`}
                onClick={() => handleShowDetails()}
              >
                {t('common:details')}
              </Button>
            )}
          </Flex>
          {isCompleted ? <Divider borderColor="grey" /> : null}
          {isCompleted ? qrContent : null}
        </BaseServiceTypeTimelineElement>
      )}
    </>
  );
};
