import { Button, Grid, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Card } from '../Card';

export const WarningPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    // @ts-ignore
    message = false,
    // @ts-ignore
    title = false,
    // @ts-ignore
    redirectUrl = false,
    // @ts-ignore
    buttonText = t('errors:boundary.back'),
  } = location?.state ?? {};

  const resetWarning = useCallback(() => {
    if (!redirectUrl) {
      navigate('/');
    } else {
      navigate(redirectUrl);
    }
  }, [message]);
  return (
    <>
      <Grid w="full" h="full" placeItems="center" data-test-id="error-boundary">
        <Card p={{ base: 2, lg: 20 }} textAlign="center">
          <Stack spacing={8}>
            <Heading as="h2" size="md" p={2} textAlign="center">
              {title ?? t('errors:boundary.title')}
            </Heading>
            <Text size={'sm'}>{message}</Text>
            <HStack alignSelf={'center'}>
              <Button variant={'primary'} onClick={resetWarning}>
                {buttonText}
              </Button>
            </HStack>
          </Stack>
        </Card>
      </Grid>
    </>
  );
};

export default WarningPage;
