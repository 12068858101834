import { useToast } from '@chakra-ui/react';
import { Results } from 'api/types';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import config from '../../../config';
import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

async function getInspectionPlan(
  axios: AxiosInstance,
  vin: string,
  id: string | undefined,
): Promise<Results<any>> {
  const { data } = await axios.get(
    `${config.apiBaseUrl}/workshop/vehicles/${vin}/inspection-plans/${id}`,
  );
  return data;
}

export default function useShowInspectionPlanQuery(vin: string, id: string | undefined) {
  const { t } = useTranslation();
  const toast = useToast();
  const axios = useAxios();

  return useQuery(getKey(vin, id), () => getInspectionPlan(axios, vin, id), {
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    enabled: !!vin && !!id,
    refetchOnWindowFocus: false,
    staleTime: 0,
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}

function getKey(vin: string, id: string | undefined): QueryKey {
  return ['Inspection Plan', vin, id];
}

useShowInspectionPlanQuery.getKey = getKey;
