import { chakra, HTMLChakraProps } from '@chakra-ui/react';
import * as React from 'react';

export const AlexAroundCorner = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    xmlns="http://www.w3.org/2000/svg"
    width="600"
    viewBox="0 0 600 800"
    height="800"
    version="1.0"
    {...props}
  >
    <defs>
      <clipPath id="al-mob-clippath">
        <path fill="none" className="cls-1" d="M0 0h600v800H0z" />
      </clipPath>
      <clipPath id="al-mob-clippath-1">
        <path
          fill="none"
          className="cls-1"
          d="m-49.49 164.33 73.17 34.64 26.18 22.31-23.84 62.7-105.83-29.51 30.32-90.14z"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#al-mob-clippath)">
      <g clipPath="url(#al-mob-clippath-1)">
        <path
          fill={'#3f7dc1'}
          d="M-14.19 209.71c-7.33-2.25-10.33-9.74-8-17.34 2.35-7.64 9.05-12.2 16.38-9.94 7.29 2.24 10.27 9.77 7.92 17.41-2.34 7.6-9.02 12.11-16.31 9.87Zm1.84-6c4.4 1.35 7.98-1.29 9.27-5.47 1.31-4.27-.18-8.42-4.58-9.77-4.44-1.37-8.01 1.23-9.33 5.5-1.28 4.18.2 8.38 4.64 9.74Z"
        />
        <path
          fill={'#3f7dc1'}
          transform="rotate(107.09 5.809 217.017)"
          d="M-22.88 215.66h57.36v2.73h-57.36z"
        />
      </g>
      <g fill={'none'} stroke={'#000'} strokeMiterlimit={10} strokeWidth={'6px'}>
        <path
          id="Pfad_466"
          d="m154.61 137.59 11.22-3.27 20.79-53.22h87.53v53.22l25.49-62.94c-58.35-24.14-109.47-34.72-136.41 0l-20.62 54.88c5.66 1.5 10.17 5.77 11.98 11.33"
          fill="#256cb4"
        />
        <path
          id="Pfad_467"
          d="m154.61 137.59 11.22-3.27 20.79-53.22h87.53v53.22l25.49-62.94c-58.35-24.14-109.47-34.72-136.41 0l-16.64 44.29-3.98 10.6"
        />
        <path
          id="Pfad_468"
          d="m88.98 205.61 55.79 97.96 101.34 6.8c9.7-20.87 16.93-36.23 13.54-68.35"
        />
        <path
          id="Pfad_469"
          d="M134.66 164.17c-11.47 0-20.76-9.31-20.75-20.78 0-11.47 9.31-20.76 20.78-20.75 11.46 0 20.75 9.3 20.75 20.76"
        />
        <path
          id="Pfad_470"
          d="M294.95 158.77c0 11.47-9.31 20.77-20.78 20.77v-41.53c11.47 0 20.78 9.29 20.79 20.76Z"
        />
        <path id="Pfad_471" d="M274.16 179.53v59.91H164.54V172.6" />
        <path
          id="Pfad_472"
          d="M249.78 200.58c-9.74 14.42-29.33 18.22-43.75 8.48a31.545 31.545 0 0 1-13.88-26.08"
        />
        <path id="Linie_99" d="M210.67 138.3h3.64" />
        <path id="Linie_100" d="M248.19 122.64v29.2" />
        <path id="Linie_101" d="m229.17 113.12 32.16-6.28" />
        <path id="Linie_102" d="m189.71 113.12 32.16-6.28" />
        <path id="Pfad_473" d="m409.94 350.83 16.89-16.87 69.18 8.44" />
        <path id="Pfad_625" d="m282.4 226.99 19.09 152.99" />
        <path id="Pfad_474" d="m323.51 331.4-44.43 97.66S150.91 673.93-74.94 800.75" />
        <path id="Pfad_475" d="M489.51 440.71h-79.57l-114.17-45.37" />
        <path
          id="Pfad_476"
          d="M536.6 356.73v-11.79l-22.4-3.53-6.65 23.93c9.11 17.67 44.32 28.11 70.95 22.76l5.2-33.7-53.75-33.74 1.23-32.15-12.73-15.86-18.23 3.24 7.41 43.2-21.99 51.49"
        />
        <path id="Pfad_477" d="M552.52 407.16c-19.45 2.55-37.77-2.02-49.63-19.03" />
        <path id="Pfad_478" d="M544.21 428.69c-16.47 2.98-34.02-1.36-43.75-16.24" />
        <path id="Pfad_482" d="M53.04 201.92C90.57 286.39 38 435.06-10.89 380.08" />
        <path
          id="Pfad_483"
          d="m237.5 391.08-37.24 80.91-92.99-11.48 27-91.11c41.94 18.13 66.43 20.67 103.22 0"
        />
        <path
          id="Pfad_490"
          d="m569.18 390.64-9.09 55.88c-23.98 7.44-47.7 5.53-63.03-9.53v-6.45l-13.23-7.41 3.22-29.96"
        />
        <path id="Pfad_491" d="m426.83 333.96-94.75-92.99-60.35-15.69" />
        <path id="Linie_109" d="M212.49 151.84v-29.21" />
        <path id="Pfad_626" d="M107.28 555.06C24.53 685.22-81.96 753.46-156.99 762.08" />
        <path id="Pfad_630" d="m29.59 354.52-89.14-86.24" />
        <path id="Pfad_631" d="m130.87 166.14-14.16 40.69-86.67-6.08-106.21-48.63" />
        <path d="m-11.25 125.53 21.53-16.2 7.29-40.8-29.92-32.04-13.44 12.86 3.08 14.74L-1.4 78.52" />
      </g>
    </g>
  </chakra.svg>
);
AlexAroundCorner.getAspectRatio = () => ({
  width: 600,
  height: 800,
  getRatio() {
    return this.height / this.width;
  },
});

export const AlexHandyMail = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    id="alex-handy-hands"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 800 800"
    {...props}
  >
    <defs>
      <clipPath id="alex-handy-hands-clippath">
        <path fill="none" d="M0 0h800v800H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#alex-handy-hands-clippath)" strokeMiterlimit="10" strokeWidth="5px">
      <path
        stroke="#1d1d1b"
        fill="none"
        d="m466.11 209.15 4.88 219.31h-15.18M348.63 326.17l3.09-158.94h65.72"
      />
      <path
        stroke="#1d1d1b"
        fill="none"
        d="m329.57 336.97 4.78-187.49h148.2l7.58 296.73-3.19 14.96H329.96l-3.18-14.96 1.13-44.47 80.54-39.48 29.64-41.92-20.65-33.15-144.53 81.86-38.18 484.49"
      />
      <path
        stroke="#1d1d1b"
        fill="none"
        d="m285.4 361.98 12.29-61.24 33.9-38.93M485.59 245.9l14.86-11.17 14.86 32.87-28.25 22.59M487.94 314.18l33.66-9.01v31.48l-33.66 9.01M488.61 367.39l25.34-1.9-1.73 27.6-22.09 3.12M421.31 461.17l-13.77 53.99-25.21 10.02 41.08 308.47"
      />
      <path
        fill="#3f7dc1"
        d="M413.17 229.27V83.62h203.72v145.65H483.15l-50.27 52.94 16.82-52.94h-36.53z"
      />
      <path stroke="#1d1d1b" fill="none" d="m423.9 92.26 88.24 73.34 94.01-73.34" />
    </g>
  </chakra.svg>
);
AlexHandyMail.getAspectRatio = () => ({
  width: 800,
  height: 800,
  getRatio() {
    return this.height / this.width;
  },
});

export const AlexHandTel = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg
    id="alex-hand-tel"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 800 800"
    {...props}
  >
    <defs>
      <clipPath id="clippath">
        <path fill="none" d="M0 0h797.77v800H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#clippath)">
      <path
        className="cls-4"
        fill={'#3f7dc1'}
        d="M366.71 424.94 496.68 549.7l86.07 28.85 58.83-61.28L523.16 403.6l-31.97 33.3-142.72-136.99-12.96 45.16 31.2 79.87zM283.33 344.9 175.88 241.76l-34.91-82.15 58.83-61.28L318.22 212l-29.41 30.64L314 266.82l-30.67 78.08z"
      />
      <path
        className="cls-2"
        fill={'none'}
        stroke={'#1d1d1b'}
        strokeMiterlimit={10}
        strokeWidth={'5px'}
        d="m287.1 827.71 29.42-338.4-36.72-77.99 2.21-63.31 34.51-87.59h44.26l12.32 26.29-18.98 18.62-5.65-5.42-12.96 45.16 41.36 105.87M432.38 487.97l-7.13 18.66 30.12 315.17"
      />
      <path
        className="cls-2"
        fill={'none'}
        stroke={'#1d1d1b'}
        strokeMiterlimit={10}
        strokeWidth={'5px'}
        d="M366.71 424.94 496.68 549.7l86.07 28.85 58.83-61.28L523.16 403.6l-31.97 33.3-137.07-131.57M314 266.82l-25.19-24.18L318.22 212 199.8 98.33l-58.83 61.28 34.91 82.15L283.33 344.9M273.83 228.26l-87.32-83.82M594.66 536.23l-87.32-83.82M273.83 228.26l-87.32-83.82M594.66 536.23l-87.32-83.82M596.98 801.68c.14-.47-.07-1.04-.56-1.68-5.8-7.57-51.35-26.01-49.77-33.39 1.42-6.64 59.18-3.85 60.27-11.5.98-6.94-55.24-20.05-54.77-27.57.45-7.11 57.91-13.37 57.71-20.8-.19-7.19-57.82-9.97-58.79-17.28-.95-7.16 53.87-25.34 52.01-32.45-1.82-6.99-58.3 4.45-61.16-2.33-2.79-6.63 22.62-19.85 19.5-41.07-2.26-15.41-8.42-34.56-29.46-52.08"
      />
      <path
        className="cls-2"
        fill={'none'}
        stroke={'#1d1d1b'}
        strokeMiterlimit={10}
        strokeWidth={'5px'}
        d="m385.54 286.71 19.6 3.26 20.11 18.79 10.13 59.54-8.94 4.31M310.05 263.03l35.5-23.93 39.99 25.49v54.7l-13.4 3.34M431.94 348.08l7.47 3.93 21 39.95-8.28 7.45M280.76 383.82l-37.61-77.48M392.95 342.61l-4.05-16.84"
      />
    </g>
  </chakra.svg>
);

AlexHandTel.getAspectRatio = () => ({
  width: 800,
  height: 800,
  getRatio() {
    return this.height / this.width;
  },
});
