import { useAuth0 } from '@auth0/auth0-react';
import { Button, ButtonProps, HStack, Icon, Text } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiLogOut } from 'react-icons/fi';

import config from '../config';
import { useUserContext } from '../context/UserContextProvider';
import { FONT_WEIGHTS } from '../global/Fonts';
import AlertModal from './modals/AlertModal';

const LogoutButton = (props: ButtonProps) => {
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const userContext = useUserContext();

  return (
    <Button
      variant="ghost-on-accent"
      justifyContent="start"
      data-test-id="logout-button"
      fontFamily="body"
      fontWeight={FONT_WEIGHTS.normal}
      paddingInline={0}
      {...props}
      onClick={() => {
        NiceModal.show(AlertModal, {
          onSubmit: () => {
            userContext.logout();
            logout({ logoutParams: { returnTo: config.logoutReturnTo.toString() } });
          },
          content: {
            header: t('pages:logout.description'),
            footer: {
              buttons: {
                cancelCaption: t('common:cancel'),
                actionCaption: t('pages:logout.label'),
              },
            },
          },
        });
      }}
    >
      <HStack spacing="3">
        <Icon as={FiLogOut} boxSize="6" />
        <Text>{t('pages:logout.label')}</Text>
      </HStack>
    </Button>
  );
};

export default LogoutButton;
