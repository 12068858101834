import { Button, Flex, HStack, Text } from '@chakra-ui/react';
import { useChargeBeePortal } from 'hooks/queries/useChargeBee';
import PageMetatags from 'layout/PageMetatags';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContentCard } from '../../layout/ContentCard';

const MyPaymentMethodCard = () => {
  const { t } = useTranslation();
  const { triggerPortal } = useChargeBeePortal();

  return (
    <ContentCard header={t('workshop:card.payment.header')}>
      <PageMetatags title={t('workshop:card.payment.meta.title')}>
        <script src="https://js.chargebee.com/v2/chargebee.js" />
      </PageMetatags>
      <HStack justifyContent={'space-between'}>
        <Text>{t('workshop:card.payment.description')}</Text>
        <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
          <Button variant="primary" onClick={triggerPortal}>
            {t('workshop:card.payment.action')}
          </Button>
        </Flex>
      </HStack>
    </ContentCard>
  );
};
export default MyPaymentMethodCard;
