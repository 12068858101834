import { AspectRatio, AspectRatioProps, chakra } from '@chakra-ui/react';
import React from 'react';

const logoSize = {
  WIDTH: 283.46,
  HEIGHT: 63.78,
} as const;

export const OeLogo: React.FC<AspectRatioProps> = (props) => {
  return (
    <AspectRatio
      ratio={logoSize.WIDTH / logoSize.HEIGHT}
      position={'relative'}
      {...props}
    >
      <chakra.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${logoSize.WIDTH} ${logoSize.HEIGHT}`}
        w={'full'}
        h={'full'}
        position={'absolute'}
        inset={0}
      >
        <path
          stroke="currentColor"
          fill="currentColor"
          d="M89.47 36.22c2.85 3.09 6.67 4.49 11.07 4.49 3.72 0 6.19-.82 6.19-2.71s-2.32-1.93-8.17-2.66c-6.38-.82-11.46-1.93-11.46-7.35 0-5.03 4.83-8.46 12.57-8.46 6.04 0 10.78 2.08 13.15 4.78l-3.87 4.11c-2.13-2.32-5.51-3.67-9.62-3.67-3.77 0-5.61 1.02-5.61 2.51 0 1.59 1.79 1.79 7.3 2.46 6.67.77 12.42 1.74 12.42 7.59s-5.95 8.65-13.2 8.65c-6.14 0-11.75-1.93-14.65-5.32l3.87-4.45ZM167.35 26.89c-1.16-.29-1.69-.39-3.05-.39-5.61 0-9.33 2.27-9.33 8.12v10.83h-6.77V20.02h6.77v4.49c2.18-3.34 5.41-4.64 9.57-4.64 1.16 0 2.08.15 2.8.34v6.67ZM180.79 45.45l-12.52-25.43h7.54l8.6 18.13 8.6-18.13h7.25l-12.52 25.43h-6.96ZM209.55 45.45h-6.77V20.02h6.77v25.43ZM228.31 19.54c7.11 0 11.89 4.01 13.39 8.12l-5.66 2.75c-.92-2.8-3.87-5.27-7.78-5.27-4.83 0-8.46 2.85-8.46 7.59s3.63 7.64 8.46 7.64c3.91 0 6.86-2.47 7.78-5.27l5.61 2.85c-1.5 4.06-6.24 8.02-13.34 8.02-8.6 0-15.08-5.37-15.08-13.24s6.48-13.2 15.08-13.2ZM266.04 36.74c-1.37 2.28-4.26 3.73-7.61 3.73-4.35 0-7.75-2.17-8.57-5.85h22.52v-2.13c0-7.25-5.27-12.95-14.07-12.95s-14.98 5.7-14.98 13.2c0 7.88 6.35 13.24 15.1 13.24 6.25 0 11.4-3.01 13.42-6.82l-5.81-2.42Zm-7.96-11.79c4.4 0 7.25 2.71 7.64 5.07h-15.61c1.06-3.33 4.25-5.07 7.98-5.07ZM138.17 36.74c-1.37 2.28-4.26 3.73-7.61 3.73-4.35 0-7.75-2.17-8.57-5.85h22.52v-2.13c0-7.25-5.27-12.95-14.07-12.95s-14.98 5.7-14.98 13.2c0 7.88 6.35 13.24 15.1 13.24 6.25 0 11.4-3.01 13.42-6.82l-5.81-2.42Zm-7.96-11.79c4.4 0 7.25 2.71 7.64 5.07h-15.61c1.06-3.33 4.25-5.07 7.98-5.07ZM40.82 32.74c0 7.98-6.53 13.24-14.79 13.24s-14.89-5.27-14.89-13.24 6.57-13.2 14.89-13.2 14.79 5.27 14.79 13.2Zm-6.53 0c0-4.78-3.72-7.54-8.27-7.54s-8.31 2.75-8.31 7.54 3.67 7.59 8.31 7.59 8.27-2.75 8.27-7.59ZM66.04 36.74c-1.37 2.28-4.26 3.73-7.61 3.73-4.35 0-7.75-2.17-8.57-5.85h22.52v-2.13c0-7.25-5.27-12.95-14.07-12.95s-14.98 5.7-14.98 13.2c0 7.88 6.35 13.24 15.1 13.24 6.25 0 11.4-3.01 13.42-6.82l-5.81-2.42Zm-7.96-11.79c4.4 0 7.25 2.71 7.64 5.07H50.11c1.06-3.33 4.25-5.07 7.98-5.07ZM12.16 53.47h59.65v2.83H12.16zM12.16 9.21h59.65v2.83H12.16z"
        />
      </chakra.svg>
    </AspectRatio>
  );
};

export default OeLogo;
