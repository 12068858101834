import { Button, Flex, HStack, Text, useToast } from '@chakra-ui/react';
import { useAxios } from 'context/AxiosContextProvider';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../config';
import { useUserContext } from '../../context/UserContextProvider';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';
import { ContentCard } from '../../layout/ContentCard';

const MyDataChangePwCard: React.FC = () => {
  const userContext = useUserContext();
  const { t } = useTranslation();
  const toast = useToast();
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const axios = useAxios();

  const authRequest = async () => {
    return await axios
      .post(`https://${config.authDomain}/dbconnections/change_password`, {
        client_id: config.authClientId,
        email: userContext.workshop?.email,
        connection: config.authConnection,
      })
      .catch((err: { response: { status: number } }) => {
        manageErrorResponse({ toastInstance: toast, error: err.response, t });
        return Promise.reject(err);
      })
      .then(() => setIsPasswordReset(true));
  };

  return (
    <>
      <ContentCard
        header={t('workshop:card.password.header')}
        data-test-id="password-change-card"
      >
        <HStack justifyContent={'space-between'}>
          <Text>
            {' '}
            {isPasswordReset
              ? t('workshop:card.password.requested')
              : t('workshop:card.password.description')}
          </Text>
          <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
            <Button
              variant="primary"
              disabled={isPasswordReset}
              onClick={() => authRequest()}
            >
              {t('workshop:card.password.action')}
            </Button>
          </Flex>
        </HStack>
      </ContentCard>
    </>
  );
};

export default MyDataChangePwCard;
