import { Box, Grid, Icon, Image } from '@chakra-ui/react';
import animationConfig from 'configs/animations';
import { noop } from 'lodash';
import React from 'react';
import { GiFilmStrip, HiOutlineBookOpen, IoPlayCircleOutline } from 'react-icons/all';

// Video Placeholder Presentational Component

export interface IVideoPlaceholderProps {
  image?: string;
  fallback?: string;
  isPageLink?: boolean;
  onClick?: () => void;
}

export const VideoPlaceholder: React.FC<IVideoPlaceholderProps> = (props) => {
  const { image, fallback, isPageLink = false, onClick = noop } = props;

  return (
    <Grid
      onClick={onClick}
      borderRadius="lg"
      overflow={'hidden'}
      className={'oe-video-placeholder'}
      sx={{
        cursor: 'pointer',
        '> *': {
          gridColumn: '1/-1',
          gridRow: '1/-1',
        },
      }}
    >
      {image ? (
        <Image
          srcSet={image}
          src={fallback}
          w={'full'}
          objectFit={'cover'}
          sx={{
            aspectRatio: '16/9',
          }}
        />
      ) : (
        <Grid
          w={'full'}
          overflow={'hidden'}
          sx={{
            aspectRatio: '16/9',
            '> *': {
              gridColumn: '1/-1',
              gridRow: '1/-1',
            },
          }}
          bgColor={'brand.gray.dark'}
        >
          <Icon as={GiFilmStrip} boxSize="40" color={'default'} m={'auto'} />
        </Grid>
      )}
      <Box
        w={'full'}
        h={'full'}
        bgColor={'brand.blue.dark'}
        opacity={0.6}
        transition={`opacity ${animationConfig.transition.duration.short.milliseconds} ${animationConfig.easing.easeIn}`}
        sx={{
          '.oe-video-placeholder:hover &': {
            opacity: '.2',
          },
        }}
      ></Box>
      <Icon
        as={isPageLink ? HiOutlineBookOpen : IoPlayCircleOutline}
        boxSize="20"
        color={'white'}
        m={'auto'}
        zIndex={1}
        transition={`transform ${animationConfig.transition.duration.short.milliseconds} ${animationConfig.easing.easeIn}`}
        sx={{
          filter: 'drop-shadow(.1rem .1rem .2rem rgba(0,0,0, .4))',
          '.oe-video-placeholder:hover &': {
            transform: 'scale(1.4)',
          },
        }}
      />
    </Grid>
  );
};

export default VideoPlaceholder;
