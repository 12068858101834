import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonSpinner,
  Container,
  Divider,
  Flex,
  Grid,
  ModalCloseButton,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUserContext } from 'context/UserContextProvider';
import { dropdown, dropzone, textareaInput } from 'helpers/makeFormFields';
import useFileUploadMutation, { UploadedFileInfo } from 'hooks/private/mutations/useFileUploadMutation';
import useStoreServiceDocumentMutation from 'hooks/private/mutations/useStoreServiceDocumentMutation';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as yup from 'yup';

interface FileUploadModalProps {
  sendRequest: (data: FormData) => void;
  isLoadingParent?: boolean;
  openFileModal: boolean;
  setOpenFileModal: React.Dispatch<React.SetStateAction<boolean>>;
  uploadCallback?: (x?: any) => void;
  parentId: number;
  url: string;
}

interface IFileForm {
  file: string | any;
  type: string;
  comment: string;
}

const FileUploadModal = (props: FileUploadModalProps) => {
  const { t } = useTranslation();
  const { workshop } = useUserContext();

  const [serviceFile, setServiceFile] = useState<UploadedFileInfo | null>(null);

  const { sendRequest, isLoadingParent, openFileModal, setOpenFileModal, uploadCallback, parentId, url } = props;

  const schema = yup
    .object({
      file: yup.mixed().required(t(`documents:validation_error.file`)),
      type: yup.string().required(t(`documents:validation_error.type`)),
      comment: yup.string().nullable(),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors, isValid, submitCount },
    control,
    watch,
    register,
    setError,
    setValue,
    reset,
  } = useForm<IFileForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const serviceFileHookForm = watch('file');
  const fileUploadMutation = useFileUploadMutation();

  const storeServiceDocumentMutation = useStoreServiceDocumentMutation(parentId);

  useEffect(() => {
    if (serviceFileHookForm) {
      const formData = new FormData();
      formData.append('file', serviceFileHookForm.file as File);

      (async () => {
        const fileData = await fileUploadMutation.mutateAsync(formData, {
          onError: () => {
            setServiceFile(null);
            setValue('file', undefined);
          },
        });
        setServiceFile(fileData?.data as UploadedFileInfo);
      })();
    } else {
      setServiceFile(null);
    }
  }, [serviceFileHookForm]);

  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const onClose = () => {
    setOpenFileModal((prevState) => !prevState);
    reset();
  };

  // eslint-disable-next-line
  const { mutate, isLoading } = useMutation<any, Error, IFileForm>(async (data) => {
    const formData = new FormData();
    formData.append('file', data.file.file as File);
    formData.append('type', data.type);
    if (data.comment) {
      formData.append('comment', data.comment);
    }
    return sendRequest(formData);
  });
  const onSubmit: SubmitHandler<IFileForm> = (formData) => {
    const serviceDocumentData = {
      type: formData.type,
      comment: formData.comment ? formData.comment : undefined,
      file_uuid: serviceFile?.uuid as string,
    };

    return storeServiceDocumentMutation.mutate(serviceDocumentData, {
      onSuccess: () => {
        uploadCallback && uploadCallback();
        onClose();
      },
    });
  };

  const typeOptions = [
    { label: t('documents:type.invoice'), value: 'invoice' },
    {
      label: t('documents:type.inspection_plan'),
      value: 'inspection_plan',
    },

    { label: t('documents:type.picture'), value: 'picture' },

    {
      label: t('documents:type.other'),
      value: 'other',
    },
    {
      label: t('documents:type.quote'),
      value: 'quote',
    },
    {
      label: t('documents:type.approval'),
      value: 'approval',
    },
    {
      label: t('documents:type.statutory_certificate'),
      value: 'statutory_certificate',
    },
  ];

  return (
    <AlertDialog
      isOpen={openFileModal}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      motionPreset="slideInBottom"
      isCentered
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay sx={{ backdropFilter: 'blur(2rem)' }}>
        <AlertDialogContent data-test-id="alert-dialog" minW="inherit" w="full" maxW="50rem">
          <AlertDialogHeader>{t('documents:modal.header')}</AlertDialogHeader>
          <ModalCloseButton data-test-id="alert-dialog-button-close" />
          <Divider />
          <AlertDialogBody>
            <Container as="form" data-test-id="upload-form">
              <Grid gap={4}>
                {dropdown({
                  name: 'type',
                  label: t('documents:form.type.label'),
                  register,
                  control,
                  errors,
                  options: typeOptions,
                  workshop: workshop,
                })}
                {dropzone({
                  name: 'file',
                  register,
                  control,
                  errors,
                  isLoading: isLoadingParent,
                  required: true,
                  label: t('documents:form.file.label'),
                })}

                {textareaInput({
                  name: 'comment',
                  label: t('documents:form.comment.label'),
                  register,
                  errors,
                  schema,
                })}
              </Grid>
              <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  variant="primary"
                  disabled={!errors || (submitCount! > 0 && !isValid) || isLoading}
                  data-test-id="upload-submit-button"
                >
                  {t('common:upload')} {isLoading && <ButtonSpinner />}
                </Button>
              </Flex>
            </Container>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default FileUploadModal;
