import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { Config } from '../../../api/types';
import config from '../../../config';
import { DURATION } from '../../../constants';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';
async function getConfig(): Promise<Config> {
  const { data } = await axios.get(config.spfApiBaseUrl + '/utilities/config');
  return data;
}

export default function useSpfConfigQuery(): {
  data: Config;
  isFetching: boolean;
} {
  const toast = useToast();
  const { t } = useTranslation();
  // @ts-ignore
  return useQuery(getKey(), getConfig, {
    enabled: true,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    refetchOnWindowFocus: false,
    cacheTime: DURATION.Hour * 5,
    staleTime: DURATION.Hour,
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}

function getKey(): QueryKey {
  return ['SpfConfig'];
}

useSpfConfigQuery.getKey = getKey;
