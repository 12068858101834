import { Box, Button, Center, Flex, Grid, Heading, HStack, Icon, Stack, Text, useToast, VStack } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { downloadFileFromUrl } from 'helpers/downloadFiles';
import { concat, get, isNil } from 'lodash';
import { useState } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BiQrScan } from 'react-icons/bi';
import { IoIosWarning } from 'react-icons/io';
import { MdArrowDownward } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { Navigate, NavLink, useNavigate, useParams } from 'react-router-dom';

import { ADDON_KEYS, CHARGE_KEYS, DataForEndpoint, PartialStateCode, ServiceRecord, SubscriptionAddon } from '../../api/types';
import { ServiceRecordIcon } from '../../components/Icons';
import { InfoCard } from '../../components/InfoCard';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import AlertModal from '../../components/modals/AlertModal';
import PageHeading from '../../components/PageHeading';
import { StatusCard } from '../../components/StatusCard';
import config from '../../config';
import { useAxios } from '../../context/AxiosContextProvider';
import { useUserContext } from '../../context/UserContextProvider';
import { FONT_WEIGHTS } from '../../global/Fonts';
import { dateTimeTransformer, dateTimezoneTransformer } from '../../helpers/dateTransformer';
import { formatMultiCurrencyIntl } from '../../helpers/formatCurrency';
import { getReason, getStatus, SendRequestProps, useIsWorkshopBlocked } from '../../helpers/general';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';
import transformer from '../../helpers/transformer';
import UnlockFeature from '../../helpers/unlockFeature';
import useDeleteServiceRecordMutation from '../../hooks/private/mutations/useDeleteServiceRecordMutation';
import useReopenServiceRecordMutation from '../../hooks/private/mutations/useReopenServiceRecordMutation';
import useServiceRecordDeletionRequestMutation from '../../hooks/private/mutations/useServiceRecordDeletionRequestMutation';
import useServiceEntryConversationQuery from '../../hooks/queries/service/useServiceEntryConversationQuery';
import useServiceRecordQuery from '../../hooks/queries/service/useServiceRecordQuery';
import useAddonsQuery from '../../hooks/queries/useAddonsQuery';
import useFormSchemaQuery from '../../hooks/queries/useGetFormSchemaQuery';
import { Subscription, WorkshopData } from '../../hooks/queries/workshop/useWorkshopQuery';
import { FlexCard } from '../../layout/Card';
import { ContentCard } from '../../layout/ContentCard';
import Link from '../../layout/Link';
import CancelModal from '../vehicle/ServiceProcessCard/CancelModal';
import { BasicFormKeys, FORM_TYPES } from './BasicDataStep';
import ConversationCard from './ConversationCard';
import ConvertModal from './ConvertModal';
import QRScannerForm from './QRScannerForm';
import ServiceRecordData from './ServiceRecordData';

export const getQRSection = (
  displayError: boolean,
  record: ServiceRecord | undefined,
  workshop: WorkshopData | undefined,
  addons: SubscriptionAddon[],
  sendRequest: (data: any) => Promise<any>,
  t: any,
) => {
  // @ts-ignores
  const subscription: Subscription = workshop?.subscription;
  const currency = workshop?.currency;
  const innerString = isNil(record?.qr_code_identifier) ? 'not_assigned' : 'assigned';
  const activeAddons = get(subscription, 'active_addons', {});
  const hasQRConnectPackage = get(activeAddons, ADDON_KEYS.QR_CONNECT, false);
  const qrCodeApplied = record?.qr_code_identifier;

  return (
    <Stack marginTop={'40px'} direction={{ base: 'column', xl: 'row' }} spacing={4} justify="space-between" data-test-id="qr-card">
      <>
        {t(`status:service_record.completed.subcontent.qr.${innerString}`)}
        {hasQRConnectPackage ? (
          <QRScannerForm sendRequest={sendRequest} data={record?.qr_code_identifier} qrCodeApplied={qrCodeApplied} />
        ) : (
          <Box m={4}>
            <Center>
              <Icon as={BiQrScan} boxSize="10" />
            </Center>
            <Center>
              <UnlockFeature currency={currency} workshop={workshop} addonId={ADDON_KEYS.QR_CONNECT} addons={addons} />
            </Center>
          </Box>
        )}
      </>
    </Stack>
  );
};

const ServiceRecordDetailsPage = () => {
  const { statusCodes, workshop, user } = useUserContext();
  const currency = workshop?.currency;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast: any = useToast();
  let { id = '' } = useParams();
  const serviceRecordId = Number(id);
  const addonsQuery = useAddonsQuery();
  const addonsData = addonsQuery.data?.data || [];
  const isWorkshopBlocked = useIsWorkshopBlocked();
  const axios = useAxios();
  const country = workshop?.country;
  const units = workshop?.units;
  const language = workshop?.language?.toLowerCase();
  const localeParams = `${language}-${country}`;

  const serviceRecordQuery = useServiceRecordQuery(serviceRecordId);
  const serviceRecordData = serviceRecordQuery.data?.data;

  const serviceEntryConversation = useServiceEntryConversationQuery(
    //@ts-ignore
    serviceRecordData?.conversation?.id,
  );
  const serviceEntryConversationData = serviceEntryConversation.data?.data;

  const formSchemaQuery = useFormSchemaQuery(serviceRecordData?.dsb_form_version_id as number);
  const formSchemaData = formSchemaQuery.data?.data || {};

  const [displayError, setDisplayError] = useState(false);
  const queryClient = useQueryClient();

  const serviceRecordDeletionRequestMutation = useServiceRecordDeletionRequestMutation(serviceRecordId);
  const reopenServiceRecordMutation = useReopenServiceRecordMutation(serviceRecordId);

  const deleteServiceRecordMutation = useDeleteServiceRecordMutation(serviceRecordId, serviceRecordData?.vehicle?.vin as string);

  if (isNaN(serviceRecordId)) {
    navigate('/404', { replace: true });
    return null;
  }

  const displayDocumentsDownload =
    serviceRecordData?.vehicle?.manufacturer?.make?.slug === 'hyundai' || serviceRecordData?.vehicle?.manufacturer?.make?.slug === 'honda';
  const manufacturerSupportsDsb = serviceRecordData?.vehicle?.has_dsb;
  const serviceRecordCodes: PartialStateCode<string> = statusCodes?.service_record || {};
  const isIdsb = serviceRecordData?.form === FORM_TYPES.PLUS_DSB;
  const isServiceRecordCompleted = serviceRecordData?.status === getStatus(serviceRecordCodes, 'completed');
  const qrCodeIsAlphanumeric = /^[0-9A-Z]+$/;
  const refresh = () => window.location.reload();
  const sendRequest = async ({
    url,
    method = 'put',
    dataForEndpoint = {} as object | DataForEndpoint,
    disableInvalidate = false,
  }: SendRequestProps) => {
    if (
      'qr_code_identifier' in dataForEndpoint &&
      typeof dataForEndpoint['qr_code_identifier'] === 'string' &&
      !qrCodeIsAlphanumeric.test(dataForEndpoint['qr_code_identifier'])
    ) {
      return NiceModal.show(AlertModal, {
        closeOnOverlayClick: false,
        children: (
          <Box>
            <Box height={330} width={'full'}>
              <Center>
                <VStack>
                  <Box>
                    <Icon as={IoIosWarning} boxSize="20" color={'red.500'} mt={10} />
                  </Box>

                  <Text align={'center'} mb={10}>
                    <Trans i18nKey="components:scanner_modal_form.input_field.error_message.only_alphanumeric" />
                  </Text>
                  <Button mt={10} variant={'primary'} onClick={refresh}>
                    Close
                  </Button>
                </VStack>
              </Center>
            </Box>
          </Box>
        ),
        content: {
          header: (
            <Box>
              <Center>
                <Box>
                  <Icon as={BiQrScan} color={'accent'} boxSize="10" mr={5} />
                </Box>
                <Box>
                  <Text mb={2}>
                    <Trans i18nKey="components:scanner_modal_form.title" />
                  </Text>
                </Box>
              </Center>
            </Box>
          ),
          footer: {
            buttons: {
              hide: true,
              cancelCaption: t('common:ok'),
            },
          },
        },
      });
    }

    const axiosCall =
      method !== 'delete' && method !== 'delete_with_body'
        ? //@ts-ignore
          axios[method](url, dataForEndpoint)
        : method === 'delete_with_body'
          ? //@ts-ignore
            axios['delete'](url, { data: dataForEndpoint })
          : axios[method](url);
    return await axiosCall
      .catch((err: { response: { status: number } }) => {
        manageErrorResponse({ toastInstance: toast, error: err.response, t });
        return Promise.reject(err.response);
      })
      .then(({ data }: any) => {
        if (disableInvalidate) {
          return data;
        } else {
          queryClient.invalidateQueries(useServiceRecordQuery.getKey(serviceRecordId));
        }
      });
  };

  const generalData: PartialStateCode<string> = transformer(serviceRecordData, concat(BasicFormKeys, ['additional_notes']));
  const reasons = statusCodes?.service_record_reasons || {};
  let subContent: any;

  if (serviceRecordData?.status === getStatus(serviceRecordCodes, 'clarification')) {
    subContent = (
      <ConversationCard
        recordId={serviceRecordData?.service_activity?.service?.id}
        conversation={serviceEntryConversationData || {}}
        statusCodes={serviceRecordCodes}
        currentStatus={serviceRecordData?.status}
        sendRequest={sendRequest}
        serviceRecordId={serviceRecordId}
        conversationId={serviceRecordData?.conversation?.id}
      />
    );
  }
  if (isServiceRecordCompleted) {
    subContent = getQRSection(
      displayError,
      serviceRecordData,
      workshop,
      addonsQuery.data,
      (data) =>
        sendRequest({
          url: `${config.apiBaseUrl}/workshop/service-records/${serviceRecordData?.id}/associate-qr`,
          dataForEndpoint: data,
        }).catch((err) => {
          setDisplayError(true);
          const { message, data = {} } = typeof err == 'string' ? JSON.parse(err) : err;
          const responseFromQrCodeSubmission = data?.message;
          if (
            responseFromQrCodeSubmission === 'qr code identifier ist bereits vergeben.' ||
            responseFromQrCodeSubmission === 'this service record already has a qr assigned' ||
            responseFromQrCodeSubmission.includes('qr code identifier muss ein String sein')
          ) {
            setDisplayError(true);
            return 'qrCodeAlreadyUsed';
          }

          if (responseFromQrCodeSubmission !== 'qr code identifier ist bereits vergeben.') {
            return toast({
              title: responseFromQrCodeSubmission || message,
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          }
        }),
      t,
    );
  }
  const isNotHyundai = serviceRecordData?.vehicle?.manufacturer?.make?.slug !== 'hyundai';
  // @ts-ignore
  const charge: number = get(workshop?.subscription?.plan?.charges, `${CHARGE_KEYS.DELETE_SERVICE_RECORD}.price`, 0);

  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (formSchemaQuery.isLoading || serviceRecordQuery.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Stack justify="space-between" direction={{ base: 'column', xl: 'row' }} spacing={4}>
        <PageHeading
          fontSize={'2xl'}
          isLoading={serviceRecordQuery.isFetching}
          title={t('pages:service_record.detail.title', {
            date: dateTimezoneTransformer(serviceRecordData?.date, user?.timezone),
          })}
        />
      </Stack>

      <StatusCard
        isLoading={serviceRecordQuery.isFetching}
        resourceKey={'service_record'}
        statusCodes={serviceRecordCodes}
        currentStatus={serviceRecordData?.status}
        currentStatusReason={serviceRecordData?.status_reason}
        subContent={subContent}
        reasons={reasons}
        customButton={
          isServiceRecordCompleted ? (
            <Button
              data-test-id="download-service-record"
              leftIcon={<MdArrowDownward />}
              variant="primary"
              size="sm"
              my="2"
              onClick={() =>
                downloadFileFromUrl(
                  axios,
                  `${config.apiBaseUrl}/workshop/service-records/${serviceRecordData?.id}/confirmation-download`,
                  'Service Record.pdf',
                )
              }
            >
              {t('common:download')}
            </Button>
          ) : serviceRecordData?.status === getStatus(serviceRecordCodes, 'canceled') &&
            serviceRecordData?.status_reason === getReason(reasons, serviceRecordData?.status, 'no_dsb') &&
            addonsQuery.isFetched ? (
            <ConvertModal
              // @ts-ignore
              subscription={workshop?.subscription}
              addons={addonsData}
              costControlDisabled={workshop?.disable_cost_control}
              handleModal={() =>
                sendRequest({
                  url: `${config.apiBaseUrl}/workshop/service-records/${serviceRecordId}/convert`,
                })
              }
            />
          ) : null
        }
      />

      {Boolean(serviceRecordData?.legacy) && (
        <InfoCard>
          <HStack spacing={4}>
            <Icon boxSize="12" color={'orange'} as={IoIosWarning} />
            <Box>
              <Text fontWeight={FONT_WEIGHTS.bold} fontSize={'xl'}>
                {t('pages:service_record.detail.legacy.header')}
              </Text>
              <Text>{t('pages:service_record.detail.legacy.description')}</Text>
            </Box>
          </HStack>
        </InfoCard>
      )}

      <ContentCard
        isLoading={serviceRecordQuery.isFetching}
        icon={<ServiceRecordIcon boxSize={10} />}
        minH="fit-content"
        header={t('pages:service_record.detail.card.header')}
        contentDescription={t('pages:service_record.detail.card.description')}
        data-test-id="service-record-detail-card"
        footer={
          isIdsb &&
          isServiceRecordCompleted && (
            <FlexCard p={4} alignItems={{ base: 'center', lg: 'flex-end' }}>
              <Stack direction={{ base: 'column', lg: 'row' }} spacing={4} alignItems={'center'}>
                <Text maxWidth={'70ch'}>{t('pages:service_record.detail.idsb_update_delete')}</Text>

                <Button
                  variant="outline"
                  w={{ base: 'full', md: 'auto' }}
                  minW="10rem"
                  flexShrink={0}
                  fontSize="md"
                  as={NavLink}
                  to={'/app/w/support'}
                >
                  {t('components:get_in_touch_card.please_contact_support')}
                </Button>
              </Stack>
            </FlexCard>
          )
        }
      >
        <Stack>
          {Object.keys(generalData).map((key: string) => {
            return (
              <HStack key={key}>
                <Text fontWeight="bold">{t(`forms:${key}.label`)}:</Text>
                <Text fontSize="sm" textAlign="left">
                  {generalData[key] === generalData?.date
                    ? dateTimeTransformer(generalData?.date)
                    : key === 'mileage'
                      ? `${generalData[key].toLocaleString(localeParams)} ${units === 'metric' ? 'km' : 'mi'}`
                      : generalData[key]}
                </Text>
              </HStack>
            );
          })}
          <Grid gap={4}>
            <ServiceRecordData record={get(serviceRecordData, 'data', {})} schema={formSchemaData} timezone={user?.timezone} />
          </Grid>
        </Stack>
        {displayDocumentsDownload && serviceRecordData?.data?.invoice_id ? (
          <Flex>
            <HStack alignSelf={'left'} mb={2}>
              <Text fontWeight="bold"> {t('forms:download_invoice.label')}:</Text>
              <Button
                ml={0}
                as={'u'}
                color={'#3F7DC1'}
                fontSize={14}
                variant={'link'}
                onClick={() =>
                  downloadFileFromUrl(
                    axios,
                    `${config.apiBaseUrl}/workshop/service-records/${serviceRecordData?.id}/documents/${serviceRecordData?.data?.invoice_id}/download`,
                    'Invoice.pdf',
                  )
                }
              >
                {t('forms:download_invoice.download')}
              </Button>
            </HStack>
          </Flex>
        ) : null}
        {displayDocumentsDownload && serviceRecordData?.data?.inspection_plan_id ? (
          <Flex>
            <HStack alignSelf={'left'} mb={2}>
              <Text fontWeight="bold">{t('forms:download_inspection_plan.label')}:</Text>
              <Button
                as={'u'}
                variant={'link'}
                fontSize={14}
                color={'#3F7DC1'}
                onClick={() =>
                  downloadFileFromUrl(
                    axios,
                    `${config.apiBaseUrl}/workshop/service-records/${serviceRecordData?.id}/documents/${serviceRecordData?.data?.inspection_plan_id}/download`,
                    'Inspection Plan.pdf',
                  )
                }
              >
                {t('forms:download_inspection_plan.download')}
              </Button>
            </HStack>
          </Flex>
        ) : null}
        <Flex justifyContent={'right'} py="4" px={{ base: '4', md: '6' }}>
          {serviceRecordData?.status === getStatus(serviceRecordCodes, 'created') ||
            (serviceRecordData?.status === getStatus(serviceRecordCodes, 'submitted') && (
              <CancelModal
                type={t('pages:service_record.label')}
                handleCancel={() => {
                  deleteServiceRecordMutation.mutate();
                  navigate('/app/w/vehicle?vin=' + serviceRecordData?.vehicle?.vin);
                }}
              />
            ))}
          {serviceRecordData?.status === getStatus(serviceRecordCodes, 'created') && (
            <Button
              mx={2}
              variant="outline"
              color="error"
              borderColor="error"
              _hover={{ bgColor: 'error', color: 'white' }}
              onClick={() =>
                sendRequest({
                  url: `${config.apiBaseUrl}/service-record/${serviceRecordId}`,
                  dataForEndpoint: {
                    ...serviceRecordData,
                    status: getStatus(serviceRecordCodes, 'canceled'),
                  },
                })
              }
            >
              {t('common:cancel_order')}
            </Button>
          )}
          {serviceRecordData?.status === getStatus(serviceRecordCodes, 'created') && isNotHyundai && (
            <Button
              as={Link}
              to={`/app/w/service-record/${serviceRecordData?.id}/update?vin=${serviceRecordData?.vehicle?.vin}`}
              variant="primary"
              _hover={{ textDecoration: 'none' }}
            >
              {t('common:edit')}
            </Button>
          )}
          {serviceRecordData?.status === getStatus(serviceRecordCodes, 'submitted') && isNotHyundai && (
            <Button
              mx={2}
              variant="primary"
              data-test-id="service-record-update-button"
              onClick={() =>
                NiceModal.show(AlertModal, {
                  onSubmit: () => {
                    reopenServiceRecordMutation.mutate();
                    navigate(`/app/w/service-record/${id}/update?vin=${serviceRecordData?.vehicle?.vin}`);
                  },

                  content: {
                    header: t('pages:service_record.modals.update.title'),
                    footer: {
                      buttons: {
                        cancelCaption: t('common:cancel'),
                        actionCaption: t('common:edit'),
                      },
                    },
                  },
                  children: (
                    <>
                      <Text>{t('pages:service_record.modals.update.text')}</Text>
                    </>
                  ),
                })
              }
            >
              {t('common:edit')}
            </Button>
          )}
        </Flex>
      </ContentCard>
      {serviceRecordData?.status === 700 && manufacturerSupportsDsb ? (
        <Box>
          <Heading size={'xs'} mb={'20px'}>
            {t('pages:service_record.request_deletion.header')}
          </Heading>
          <ContentCard bg={'red.200'} data-test-id="request-sr-deletion">
            <HStack justifyContent={'space-between'}>
              <Box>
                <Text>
                  <Trans i18nKey="pages:service_record.request_deletion.message" />
                </Text>
              </Box>
              <Box>
                <Button
                  bg={'red.600'}
                  // @ts-ignore
                  _hover={'red.600'}
                  variant="primary"
                  onClick={() => {
                    NiceModal.show(AlertModal, {
                      onSubmit: () => serviceRecordDeletionRequestMutation.mutate(),
                      children: (
                        <Text>
                          <Trans
                            i18nKey="pages:service_record.request_deletion.modal.message"
                            values={{
                              delete_service_record: formatMultiCurrencyIntl(charge, currency),
                            }}
                          />
                        </Text>
                      ),
                      content: {
                        header: t('pages:service_record.request_deletion.modal.header'),
                        footer: {
                          buttons: {
                            cancelCaption: t('common:cancel'),
                            actionCaption: t('common:delete'),
                          },
                        },
                      },
                    });
                  }}
                >
                  {t('pages:service_record.request_deletion.button')}
                </Button>
              </Box>
            </HStack>
          </ContentCard>
        </Box>
      ) : null}
    </>
  );
};
export default ServiceRecordDetailsPage;
