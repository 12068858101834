import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Box, Heading } from '@chakra-ui/react';
import * as React from 'react';
import { Carousel } from 'react-responsive-carousel';

export function TestimonialsCarousel(props: {
  title: string;
  children: React.ReactChild[];
  abTestNewDesign?: boolean;
}) {
  const { title, children } = props;

  return (
    <Box
      borderRadius={'8px'}
      position={'relative'}
      maxHeight={700}
      maxWidth={1216}
      overflow={'hidden'}
    >
      <Heading textAlign={'center'} size="xs" mb={'20px'}>
        {title}
      </Heading>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={10000}
        showStatus={false}
        showArrows={true}
        showIndicators={false}
        showThumbs={false}
      >
        {children}
      </Carousel>
    </Box>
  );
}
export default TestimonialsCarousel;
