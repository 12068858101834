import { captureException } from '@sentry/react';
import { format } from 'date-fns';

export default function dateTransformer(value: string, timezone = 'Europe/Vienna') {
  if (!value) {
    return value;
  }
  const dateMatches =
    value.toString().match(
      // eslint-disable-next-line
      /^(\d{4})(-(\d{2}))??(-(\d{2}))??(T(\d{2}):(\d{2})(:(\d{2}))??(\.(\d+))??(([\+\-]{1}\d{2}:\d{2})|Z)??)??$/gm,
    ) ?? [];

  if (
    Object.prototype.toString.call(value) === '[object Date]' ||
    dateMatches.length > 0
  ) {
    const date = new Date(value);
    return dateTimezoneTransformer(date, timezone);
  }

  return false;
}

export function dateTransformerNoTimezone(
  value: Date | string,
  dateFormat = 'dd.MM.yyyy HH:mm:ss',
) {
  if (!value) {
    return value;
  }

  const dateMatches =
    value
      .toString()
      .match(
        /^((0[1-9]|[12][012345678]|19)\.(0[1-9]|1[012])|29\.(0[13-9]|1[012])|30\.(0[13-9]|1[012])|31\.(0[13578]|1[02]))\.(19|20)\d\d|29\.02\.(19|20)([02468][048]|[13579][26])$/gm,
      ) ?? [];

  if (
    Object.prototype.toString.call(value) === '[object Date]' ||
    dateMatches.length > 0
  ) {
    const date = new Date(value);
    try {
      return format(date, dateFormat);
    } catch (e) {
      captureException(e);
    }
  }
}

export const dateTimezoneTransformer = (
  date: string | number | Date | undefined,
  timezone: string = 'Europe/Vienna',
  showTime: boolean = false,
) => {
  if (showTime) {
    // @ts-ignore
    return new Date(date).toLocaleTimeString('ro-RO', {
      timeZone: timezone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }
  // @ts-ignore
  return new Date(date).toLocaleString('ro-RO', {
    timeZone: timezone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
};

export const dateTimeTransformer = (
  value: string,
  timezone = 'Europe/Vienna',
  showTime: boolean = true,
) => {
  if (!value) {
    return value;
  }
  const date = new Date(value);
  return dateTimezoneTransformer(date, timezone, showTime);
};
