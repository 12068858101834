import posthog from "posthog-js";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';

export default function PostHogPageView() {

    let location = useLocation();

    useEffect(() => {
        if (
            posthog &&
            (
                location?.pathname.includes('/signup') ||
                location?.pathname.includes('/setup') ||
                location?.pathname.includes('/login')
            )
        ) {
            if (!localStorage.getItem('session_id')) {
                posthog.opt_in_capturing();
                posthog.startSessionRecording();
                localStorage.setItem('session_id', posthog.get_session_id());
            }
        } else if (
            (location?.pathname.includes('/dashboard') && location?.search === '') ||
            (location?.pathname.includes('/app/w') && !location.pathname.includes('/dashboard'))
        ) {
            posthog.opt_out_capturing();
            if (localStorage.getItem('session_id')) {
                if (localStorage.getItem('session_id') === posthog.get_session_id()) {
                    posthog.stopSessionRecording();
                }
                localStorage.removeItem('session_id');
            } else if (posthog.sessionRecordingStarted()) {
                posthog.stopSessionRecording();
            }
        }
    }, [location]);

    return null;
}