import { Button, Heading, Text } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import AlertModal from '../../../components/modals/AlertModal';
import { Card } from '../../../layout/Card';

const AccountUpdatePendingCard = (props: {
  cancel(): void;
  date: string;
  state?: string;
  showCancelBttn: boolean;
}) => {
  const { cancel, date, state = 'pending', showCancelBttn } = props;

  const { t } = useTranslation();
  return (
    <>
      <Card
        bgColor="orange.100"
        textAlign="center"
        data-test-id="account-update-pending-card"
      >
        <Heading as="h6" size="sm" p={2}>
          {t('workshop:update.pending.header')}
        </Heading>
        <Text color="muted" fontSize="sm" p={2}>
          {t(`workshop:update.${state}.text`, { created_at: date })}
        </Text>

        {showCancelBttn && (
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={() =>
              NiceModal.show(AlertModal, {
                onSubmit: cancel,
                content: {
                  header: t('workshop:update.pending.modal.header'),
                  footer: {
                    buttons: {
                      cancelCaption: t('common:no'),
                      actionCaption: t('common:yes'),
                    },
                  },
                },
                children: t('workshop:update.pending.modal.description'),
              })
            }
            size="sm"
            my="2"
          >
            {t('common:cancel')}
          </Button>
        )}
      </Card>
    </>
  );
};

export default AccountUpdatePendingCard;
