import { Box, BoxProps } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import * as React from 'react';

import confetti from '../assets/animations/confetti.json';

export const Confetti: React.FC<BoxProps> = (props) => {
  return (
    <Box {...props} pointerEvents={'none'}>
      <Lottie animationData={confetti} loop={false} />
    </Box>
  );
};

export default Confetti;
