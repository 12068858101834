import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { LooseObject } from '../../../api/types';
import config from '../../../config';
import { DURATION } from '../../../constants';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

export type Config = {
  data: {
    countries: string[];
    languages: { [country: string]: string[] };
    telephone_prefixes: LooseObject;
    zipcode_length: LooseObject;
    terms_and_conditions_url: string;
    data_privacy_url: string;
    dpa_url: string;
    support_telephone: string;
    support_email: string;
  };
};

async function getConfig(language: string, country?: string): Promise<Config> {
  const { data } = await axios.get(`${config.apiBaseUrl}/utilities/config?language=${language}&country=${country}`);
  return data;
}

export default function usePublicConfigQuery(
  language: string,
  country?: string,
): {
  data: Config | undefined;
  isFetching: boolean;
} {
  const toast = useToast();
  const { t } = useTranslation();
  return useQuery([getKey(language, country)], () => getConfig(language, country), {
    enabled: !!language && !!country,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    refetchOnWindowFocus: false,
    cacheTime: DURATION.Hour * 5,
    staleTime: DURATION.Hour,
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}

function getKey(language: string, country: string | undefined) {
  return ['PublicConfig', language, country];
}

usePublicConfigQuery.getKey = getKey;
