import { Center, Flex, Heading, Image, Link, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Card } from '../Card';

export const PreregistrationErrorPage = () => {
  const { t } = useTranslation();

  return (
    <Flex height={'100vh'} justifyContent={'center'} alignItems={'center'}>
      <Card p={{ base: 70, lg: 200 }} textAlign="center">
        <Stack spacing={8}>
          <Heading as="h2" size="md" p={2} textAlign="center">
            {t('errors:boundary.title')}
          </Heading>
          <Text fontSize={'lg'}>
            <Trans
              i18nKey="errors:boundary.preregistration"
              components={{ 1: <Link href="mailto:mascdia@oeservice.eu" /> }}
            />
          </Text>
          <Center mb={8}>
            <Image src="/images/accident.svg" alt="Error Gif" w={'90%'} h={'auto'} />
          </Center>
        </Stack>
      </Card>
    </Flex>
  );
};

export default PreregistrationErrorPage;
