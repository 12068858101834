import { Box, ChakraProps } from '@chakra-ui/react';
import * as React from 'react';

export interface ICornerRibbonProps {
  style?: object;
  backgroundColor?: string;
  fontColor?: string;
  position?: string;
  className?: string;
  children: any;
}

export const CornerRibbon = (props: ICornerRibbonProps) => {
  const {
    children,
    style = {},
    backgroundColor = 'orange.500',
    fontColor = '#f0f0f0',
    position = 'top-right',
    className,
    ...rest
  }: ICornerRibbonProps = props;

  let positionStyle = {};
  switch (position) {
    case 'top-left':
      positionStyle = {
        top: 0,
        left: 0,
        transform:
          'translateY(-100%) rotate(-90deg) translateX(-70.71067811865476%) rotate(45deg)',
        transformOrigin: 'bottom left',
        WebkitTransform:
          'translateY(-100%) rotate(-90deg) translateX(-70.71067811865476%) rotate(45deg)',
      };
      break;
    case 'top-right':
      positionStyle = {
        top: 0,
        right: 0,
        transform:
          'translateY(-100%) rotate(90deg) translateX(70.71067811865476%) rotate(-45deg)',
        transformOrigin: 'bottom right',
        WebkitTransform:
          'translateY(-100%) rotate(90deg) translateX(70.71067811865476%) rotate(-45deg)',
      };
      break;
    case 'bottom-left':
      positionStyle = {
        bottom: 0,
        left: 0,
        transform:
          'translateY(100%) rotate(90deg) translateX(-70.71067811865476%) rotate(-45deg)',
        transformOrigin: 'top left',
        WebkitTransform:
          'translateY(100%) rotate(90deg) translateX(-70.71067811865476%) rotate(-45deg)',
      };
      break;
    case 'bottom-right':
      positionStyle = {
        right: 0,
        bottom: 0,
        transform:
          'translateY(100%) rotate(-90deg) translateX(70.71067811865476%) rotate(45deg)',
        transformOrigin: 'top right',
        WebkitTransform:
          'translateY(100%) rotate(-90deg) translateX(70.71067811865476%) rotate(45deg)',
      };
      break;
    default:
      break;
  }

  const computedStyle: ChakraProps = {
    position: 'absolute',
    padding: '0.1em 4em',
    zIndex: 99,
    textAlign: 'center',
    letterSpacing: '2px',
    fontSize: '18px',
    pointerEvents: 'auto',
    boxShadow: '0 0 3px rgba(0,0,0,.3)',
    ...(backgroundColor && { backgroundColor }),
    ...(fontColor && { color: fontColor }),
    ...positionStyle,
    ...style,
  };

  const containerStyle: ChakraProps = {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflow: 'hidden',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
  };

  return (
    <Box {...containerStyle} className={className} {...rest}>
      <Box {...computedStyle}>{children}</Box>
    </Box>
  );
};
