import { Box, Tag, VStack } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { find, get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriptionAddon } from '../api/types';
import GetAddonModalData from '../components/modals/GetAddonModalData';
import { WorkshopData } from '../hooks/queries/workshop/useWorkshopQuery';
import { eventTrack } from './analytics';

export const UnlockFeature = React.forwardRef(
  (
    props: {
      vin?: string | undefined;
      ppuFastlane?: boolean;
      displayCheckBox?: React.ReactNode;
      currency: string | undefined;
      workshop: WorkshopData | null | undefined;
      addonId?: string;
      addons: SubscriptionAddon[];
      onSuccess?: () => void;
      icon?: object;
      color?: string;
      colorScheme?: string;
      noBoxShadow?: boolean;
      label?: string | object | null;
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const {
      vin,
      ppuFastlane = false,
      displayCheckBox,
      workshop = {},
      currency,
      addons = [],
      addonId,
      onSuccess,
      icon,
      color,
      colorScheme,
      noBoxShadow,
      label,
    } = props;
    // @ts-ignore
    const { subscription = {}, disable_cost_control = false } = workshop;

    const modalData = GetAddonModalData({
      vin,
      ppuFastlane,
      currency,
      onSuccess,
      addon: find(addons, { id: addonId }),
      activeAddon: get(subscription?.active_addons, addonId) || { id: addonId },
      subscription,
      costControlDisabled: disable_cost_control,
      isTrial: true,
    });
    return (
      <>
        {displayCheckBox ? (
          <Tag
            // @ts-ignore
            ref={ref}
            size={'sm'}
            bgColor={'white'}
            id={'upgrade-content-ppu-fastlane'}
            onClick={() => {
              NiceModal.show(modalData?.modalComponent, modalData);
              if (modalData?.eventTrackingString) {
                eventTrack(modalData?.eventTrackingString);
              }
            }}
          >
            {displayCheckBox}
          </Tag>
        ) : icon !== undefined ? (
          <Tag
            // @ts-ignore
            alignSelf={'center'}
            ref={ref}
            size={'sm'}
            variant="solid"
            colorScheme="blue"
            transition={'transform .2s ease-in'}
            id={'upgrade-content-ip'}
            bgColor={'white'}
            onClick={() => {
              NiceModal.show(modalData?.modalComponent, modalData);
              if (modalData?.eventTrackingString) {
                eventTrack(modalData?.eventTrackingString);
              }
            }}
            cursor={'pointer'}
            _hover={{ transform: 'scale(1.2)' }}
          >
            <>
              <VStack>
                <Box>{icon}</Box>
                <Box>
                  {label ? (
                    <Box color={color} fontSize={'medium'}>
                      {label}
                    </Box>
                  ) : null}
                </Box>
                <Box>
                  <Tag
                    // @ts-ignore
                    ref={ref}
                    size={'sm'}
                    variant="solid"
                    colorScheme={colorScheme === undefined ? 'blue' : colorScheme}
                    transition={'transform .2s ease-in'}
                    id={'upgrade-content'}
                    cursor={'pointer'}
                    boxShadow={
                      noBoxShadow === false ? '2px 2px 3px rgba(0,0,0, .2)' : 'undefined'
                    }
                  >
                    <>{t('common:unlock_feature')}</>
                  </Tag>
                </Box>
              </VStack>
            </>
          </Tag>
        ) : (
          <Tag
            // @ts-ignore
            ref={ref}
            size={'sm'}
            variant="solid"
            colorScheme={colorScheme === undefined ? 'blue' : colorScheme}
            transition={'transform .2s ease-in'}
            id={'upgrade-content'}
            onClick={() => {
              NiceModal.show(modalData?.modalComponent, modalData);
              if (modalData?.eventTrackingString) {
                eventTrack(modalData?.eventTrackingString);
              }
            }}
            cursor={'pointer'}
            boxShadow={
              noBoxShadow === false ? '2px 2px 3px rgba(0,0,0, .2)' : 'undefined'
            }
            _hover={{ transform: 'scale(1.2)' }}
          >
            <>{t('common:unlock_feature')}</>
          </Tag>
        )}
      </>
    );
  },
);
UnlockFeature.displayName = 'UnlockFeature';
export default UnlockFeature;
