import { List } from '@chakra-ui/react';
import * as React from 'react';
import { FieldErrors } from 'react-hook-form';

import Requirement from './Requirement';

interface IProps {
  validationMap: Map<string, string>;
  error?: FieldErrors;
}

export const RequirementsChecklist: React.FC<IProps> = ({
  validationMap,
  error,
}: IProps) => {
  const renderRequirements = () => {
    return Array.from(validationMap).map(([type, caption], index) => {
      return (
        <Requirement
          key={index}
          fulfilled={Object.keys(error?.types || {}).includes(type)}
          caption={caption}
        />
      );
    });
  };

  return <List spacing={1}>{renderRequirements()}</List>;
};

export default RequirementsChecklist;
