import React from 'react';
import { Helmet } from 'react-helmet';

import config from '../config';

export interface IPageMeta {
  title?: string;
  children?: React.ReactElement;
}

export const PageMetatags: React.FC<IPageMeta> = ({ title = '', ...props }) => {
  return (
    <Helmet>
      <title>
        {config.appTitle?.toLowerCase()} - {title}
      </title>
      <meta name="title" content={`${config.appTitle} - ${title}`} />
      {props.children}
    </Helmet>
  );
};

export default PageMetatags;
