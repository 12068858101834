import { Button, ButtonProps } from '@chakra-ui/react';
import * as React from 'react';

import { ButtonSpinner } from '../LoadingSpinner';

export interface IOutlineErrorButton extends ButtonProps {
  isLoading?: boolean;
}

export const OutlineErrorButton = ({
  isLoading = false,
  children,
  ...props
}: IOutlineErrorButton) => {
  return (
    <Button
      color="error"
      borderColor="error"
      variant="outline"
      _hover={{
        bg: 'error',
        color: 'white',
      }}
      _active={{
        bg: 'error',
        color: 'white',
      }}
      {...props}
    >
      {isLoading ? <ButtonSpinner /> : <>{children}</>}
    </Button>
  );
};
