import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import config from 'config';
import useVehicleQuery from 'hooks/queries/useVehicleQuery';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

async function updateVehicle(
  axios: AxiosInstance,
  vin: string,
  vehicle_type_id: string,
  initial_registration_date?: string,
): Promise<object> {
  const { data } = await axios.put(`${config.apiBaseUrl}/workshop/vehicles/${vin}`, {
    hp_vehicle_type_id:
      vehicle_type_id === ''
        ? undefined
        : vehicle_type_id === 'unknown'
          ? null
          : vehicle_type_id,
    initial_registration_date: initial_registration_date ?? undefined,
  });
  return data;
}

export default function useUpdateVehicleMutation(vin: string) {
  const queryClient = useQueryClient();
  const axios = useAxios();
  const toast = useToast();
  const t = useTranslation();

  return useMutation(
    ({
      vehicle_type_id,
      initial_registration_date,
    }: {
      vehicle_type_id: number;
      initial_registration_date?: string;
    }) => updateVehicle(axios, vin, vehicle_type_id, initial_registration_date),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
      },
      onError: (err: any) => {
        manageErrorResponse({ toastInstance: toast, error: err.response, t });
      },
    },
  );
}
