import { DownloadIcon } from '@chakra-ui/icons';
import { HStack, IconButton, Stack, Text, useToast } from '@chakra-ui/react';
import config from 'config';
import { useAxios } from 'context/AxiosContextProvider';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import { get } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { FileObject, PartialStateCode } from '../api/types';
import { getDarkStatusColor, getTextStatusColor } from '../helpers/getColourSchemeBasedOnStatus';
export const ConversationBubble = (props: {
  message: string;
  author: string;
  timestamp?: string;
  isOwnMessage: boolean;
  statusCodes: PartialStateCode<number>;
  currentStatus?: number;
  file?: FileObject;
  index: number;
  fileId?: number;
  serviceRecordId: number;
}) => {
  const {
    message,
    author,
    timestamp,
    isOwnMessage,
    statusCodes,
    serviceRecordId,
    fileId,
    index,
    file = {
      original_name: undefined,
    },
    currentStatus = 999,
    ...rest
  } = props;

  const axios = useAxios();
  const toast = useToast();
  const { t } = useTranslation();

  const downloadConversationDocument = async (serviceId: number, documentId: number | undefined) => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/workshop/services/${serviceId}/documents/${documentId}/temp-url`);
      const url = response.data?.data?.url;
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    }
  };

  return (
    <Stack spacing={3}>
      <Stack textAlign={isOwnMessage ? 'left' : 'right'} sx={isOwnMessage ? { marginLeft: 2 } : { marginRight: 2 }}>
        <Text color="muted" fontSize="xs" m={0}>
          {author} <br />
          {timestamp}
        </Text>
      </Stack>
      <Stack
        spacing={6}
        h="100%"
        rounded="2xl"
        shadow="sm"
        py={{ base: '4', md: '4' }}
        px={{ base: '4', md: '4' }}
        bg={isOwnMessage ? 'gray.100' : getDarkStatusColor(get(statusCodes, currentStatus, 'unknown'))}
        color={isOwnMessage ? 'default' : getTextStatusColor(get(statusCodes, currentStatus, 'unknown'))}
        {...rest}
      >
        <HStack justifyContent={message === null ? 'flex-end' : 'space-between'}>
          {message?.split('<br />').map((line, index) => (
            <Text fontSize="xs" whiteSpace={'pre-wrap'} key={index}>
              <React.Fragment key={line}>
                {line}
                <br />
              </React.Fragment>
            </Text>
          ))}
          {file?.original_name !== undefined && index !== 0 ? (
            <HStack>
              <Text fontSize="xx-small">{file?.original_name}</Text>
              <IconButton
                onClick={() => downloadConversationDocument(serviceRecordId, fileId)}
                cursor="pointer"
                icon={<DownloadIcon />}
                boxSize="4"
                aria-label="download"
              />
            </HStack>
          ) : null}
        </HStack>
      </Stack>
    </Stack>
  );
};
