export default {
  colors: {
    'bg-canvas': {
      default: 'brand.gray.default',
      _dark: 'brand.gray.default',
    },
    'bg-surface': {
      default: 'white',
      _dark: 'gray.800',
    },
    'bg-subtle': {
      default: 'gray.50',
      _dark: 'gray.700',
    },
    'bg-muted': {
      default: 'gray.100',
      _dark: 'gray.600',
    },

    default: {
      default: 'black',
      _dark: 'white',
    },
    inverted: {
      default: 'white',
      _dark: 'black',
    },
    emphasized: {
      default: 'gray.700',
      _dark: 'gray.100',
    },
    muted: {
      default: 'gray.600',
      _dark: 'gray.300',
    },
    subtle: {
      default: 'gray.500',
      _dark: 'gray.400',
    },
    border: {
      default: 'gray.200',
      _dark: '#333e51', // lighten('gray.700', 3)(props.theme)
    },
    accent: {
      default: 'brand.blue.default',
      _dark: 'brand.blue.default',
    },
    success: {
      default: 'green.600',
      _dark: 'green.200',
    },
    error: {
      default: 'status.error',
      _dark: 'status.error',
    },
    'error-light': {
      default: 'brand.orange.100',
      _dark: 'brand.orange.100',
    },
    disabled: {
      default: 'status.disabled',
      _dark: 'status.disabled',
    },
    'bg-accent': 'brand.blue.default',
    'bg-accent-subtle': 'brand.blue.default',
    'bg-accent-muted': 'brand.400',
    'on-accent': 'white',
    'on-accent-muted': 'brand.50',
    'on-accent-subtle': 'brand.100',
  },
};
