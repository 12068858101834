import { useToast } from '@chakra-ui/react';
import { useAxios } from 'context/AxiosContextProvider';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import config from '../../../config';

export default function useDeleteServiceRecordMutation(serviceRecordId: number, vin: string) {
  const axios = useAxios();
  const t = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return axios.delete(`${config.apiBaseUrl}/workshop/service-records/${serviceRecordId}`);
    },
    onSuccess: (data: any) => {
      queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
    },
    onError: (error: any) => {
      const { status, message } = error.response.data;
      toast({
        title: message,
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
    },
  });
}
