import { Box, HStack, Text } from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import * as React from 'react';

interface UserProfileProps {
  name?: string;
  image?: string;
  email?: string;
  isLoading?: boolean;
}

export const UserProfile = (props: UserProfileProps) => {
  const { name, email, isLoading } = props;
  return (
    <HStack spacing="3" ps="3">
      <Box w="100%" mt={4}>
        {!name || isLoading ? (
          <Skeleton height="10px" />
        ) : (
          <Text color="on-accent" fontWeight="medium" fontSize="sm">
            {name}
          </Text>
        )}

        {!email || isLoading ? (
          <Skeleton height="10px" />
        ) : (
          <Text
            color="on-accent-muted"
            fontSize="sm"
            overflowWrap={'break-word'}
            wordBreak={'break-word'}
          >
            {email}
          </Text>
        )}
      </Box>
    </HStack>
  );
};
