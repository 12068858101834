import 'focus-visible/dist/focus-visible';
import 'assets/css/global.css';

import { Auth0Provider } from '@auth0/auth0-react';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/browser';
import { AnimatePresence } from 'framer-motion';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import config from './config';
import { initSentry } from './configs/sentry';
import { myTheme } from './foundations/theme';
import Fonts from './global/Fonts';
import { GlobalErrorBoundary } from './layout/errors';
import { Environments } from 'helpers/environments';

initSentry();
if (typeof window !== 'undefined') {
  // hard coding API key in code for networks failures of getting the key from first hand
  // TODO: make a solution to solve this issue 
  if (config.appEnv === Environments.PROD) {
    posthog.init('phc_Pplx4CRanbzWI7gdEBkgmxaHVZse3IHzvKviiA9FYmE', config.posthog_api_host_url);
  } else {
    posthog.init('phc_fOU2BpaCBX9MOMPNYgcZECn5uzdp4h9wNiVf92a9a8K', config.posthog_api_host_url);
  }
}

const onError = (error: any) => {
  Sentry.captureException(error);
};

window.onerror = onError;
window.addEventListener('unhandledrejection', (event) => {
  Sentry.captureException(event.reason);
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <ChakraProvider theme={myTheme}>
        <Helmet>
          {config.gtmId ? (
            <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${config.gtmId}')`}</script>
          ) : null}
        </Helmet>
        <Fonts />
        <Auth0Provider
          domain={config.authDomain.toString()}
          clientId={config.authClientId.toString()}
          audience={config.auth0Audience.toString()}
          redirectUri={`${config.baseUrl}/app/w/dashboard`}
          cacheLocation={'localstorage'}
          useRefreshTokens={true}
          onRedirectCallback={(appState) => (window.location.href = appState?.returnTo || `${config.baseUrl}/app/w/dashboard`)}
        >
          <GlobalErrorBoundary>
            <AnimatePresence exitBeforeEnter>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </AnimatePresence>
          </GlobalErrorBoundary>
        </Auth0Provider>
      </ChakraProvider>
    </PostHogProvider>
  </React.StrictMode>,
);
