import React from 'react';

const EngineIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    style={{ transform: 'rotate(360deg)' }}
    {...props}
  >
    <path
      fill="currentColor"
      d="M7 4v2h3v2H7l-2 2v3H3v-3H1v8h2v-3h2v3h3l2 2h8v-4h2v3h3V9h-3v3h-2V8h-6V6h3V4H7Z"
    />
  </svg>
);

export default EngineIcon;
