import { Box, Button, Container, Flex, Grid, HStack, Icon, Stack, Text, Tooltip, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FONT_WEIGHTS } from 'global/Fonts';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import useAccountUpdateFormMutation from 'hooks/private/mutations/useAccountUpdateFormMutation';
import { get, set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getI18n, useTranslation } from 'react-i18next';
import { IoWarning } from 'react-icons/all';
import { useQueryClient } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import * as yup from 'yup';

import { LooseObject } from '../../../api/types';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { useUserContext } from '../../../context/UserContextProvider';
import { formatMultiCurrencyIntl } from '../../../helpers/formatCurrency';
import { useIsWorkshopBlocked, validateVat } from '../../../helpers/general';
import { formatTelephone, SupportedCountriesVatFormatting, telephonePrefixesOptions } from '../../../helpers/localization';
import { dropdown, simpleCheckBox, simpleInput } from '../../../helpers/makeFormFields';
import useUpdateWorkshopBaseDataMutation from '../../../hooks/private/mutations/useUpdateWorkshopBaseDataMutation';
import useWorkshopQuery, { WorkshopData } from '../../../hooks/queries/workshop/useWorkshopQuery';

const FORMFIELD_CONFIG = {
  spacing: 2,
};
export interface AccountUpdateableUserData {
  firstname: string;
  lastname: string;
  email: string;
  telephone: string;
  telephone_prefix?: string;
  name: string;
  vat_number: string;
  city: string;
  zipcode: string;
  address: string;
  consent_charge: any;
}

const getDataObject = (workshop: WorkshopData, telephonePrefixes: LooseObject) => {
  let telephone = workshop?.telephone;
  if (telephone[0] === telephone[1] && telephone[1] === '0') {
    telephone = '+' + telephone.substring(2);
  }
  const prefix = Object.values(telephonePrefixes).find((countryCode) => telephone.includes(countryCode));
  return {
    firstname: workshop?.firstname,
    lastname: workshop?.lastname,
    email: workshop?.email,
    telephone_prefix: prefix,
    // @ts-ignore
    telephone: telephone.replace(prefix, ''),
    name: workshop?.name,
    vat_number: workshop?.vat_number,
    city: workshop?.city,
    zipcode: workshop?.zipcode,
    address: workshop?.address,
  };
};

export const AccountUpdateForm = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();
  // @ts-ignore
  const {
    workshop,
    user,
    config: { telephone_prefixes: telephonePrefixes = {} },
    subscription,
  } = useUserContext();
  const [isVatValidated, setIsVatValidated] = useState(false);
  const workshopVerifications = workshop.verifications;

  const allVerificationStatuses = workshopVerifications.reduce((allStatuses: number[], verification) => {
    if (verification.status === 900) {
      allStatuses.push(verification.status);
    }
    return allStatuses;
  }, []);

  const workshopHasOnlyRejectedVerifications = allVerificationStatuses.length === workshopVerifications.length;

  const initialValues = getDataObject(workshop, telephonePrefixes);

  const schema = yup
    .object({
      firstname: yup.string().label(t('forms:firstname.label')).required(),
      lastname: yup.string().label(t('forms:lastname.label')).required(),
      email: yup
        .string()
        .label(t('forms:email.label'))
        .email()
        .matches(/^[^äÄöÖüÜ]*$/, { message: t('forms:email.special_characters') })
        .required(),
      name: yup.string().label(t('forms:name.label')).required(),
      address: yup.string().label(t('forms:address.label')).required(),
      city: yup.string().label(t('forms:city.label')).required(),
      zipcode: yup.string().label(t('forms:zipcode.label')).required(),
      telephone: yup.string().label(t('forms:telephone.label')).required(),
      telephone_prefix: yup.string().label(t('forms:telephone.label')).required(),
      vat_number: yup.string().label(t('forms:vat_number.label')).required(),
      consent_charge: yup.boolean().required().label(getI18n().t('forms:firstname.label')).oneOf([true], getI18n().t('common:required')),
    })
    .required();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid, submitCount },
    setError,
    setFocus,
    setValue,
    getValues,
    reset,
    clearErrors,
    watch,
  } = useForm<AccountUpdateableUserData>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  });
  useEffect(() => {
    reset(initialValues);
  }, [workshop]);
  const currency = workshop?.currency;
  const accountUpdateFormMutation = useAccountUpdateFormMutation();
  const updateWorkshopBaseDataMutation = useUpdateWorkshopBaseDataMutation();

  const firstNameWatch = watch('firstname');
  const lastNameWatch = watch('lastname');
  const emailWatch = watch('email');
  const telephoneWatch = watch('telephone');
  const telephonePrefixWatch = watch('telephone_prefix');
  const nameWatch = watch('name');
  const vatNumberWatch = watch('vat_number');
  const cityWatch = watch('city');
  const zipcodeWatch = watch('zipcode');
  const addressWatch = watch('address');

  const fieldsUntouchedExceptConsentCharge =
    firstNameWatch === initialValues.firstname &&
    lastNameWatch === initialValues.lastname &&
    emailWatch === initialValues.email &&
    telephoneWatch === initialValues.telephone &&
    telephonePrefixWatch === initialValues.telephone_prefix &&
    nameWatch === initialValues.name &&
    vatNumberWatch === initialValues.vat_number &&
    cityWatch === initialValues.city &&
    zipcodeWatch === initialValues.zipcode &&
    addressWatch === initialValues.address;

  const onSubmit: SubmitHandler<AccountUpdateableUserData> = (data) => {
    const prefix = data?.telephone_prefix != t('common:choose') ? data?.telephone_prefix : '';
    const requestData = {
      ...data,
      telephone: prefix + data?.telephone,
    };
    delete requestData.telephone_prefix;
    const modifiedSubmissionData = {
      ...requestData,
      workshop_id: workshop.id,
      workshop_user_id: user.id,
    };

    if (workshopHasOnlyRejectedVerifications) {
      updateWorkshopBaseDataMutation.mutate(modifiedSubmissionData, {
        onSuccess: () => {
          queryClient.invalidateQueries(useWorkshopQuery.getKey());
          navigate('/app/w/account/data');
        },
        onError: (error: any) => {
          const { errors } = error.response.data;

          (Object.keys(errors) as Array<keyof AccountUpdateableUserData>).forEach((key) => {
            setError(key, {
              type: 'server',
              message: errors[key]!.join('. '),
            });
          });
        },
      });
    } else {
      accountUpdateFormMutation.mutate(modifiedSubmissionData, {
        onSuccess: () => {
          queryClient.invalidateQueries(useWorkshopQuery.getKey());
          navigate('/app/w/account/data');
        },
        onError: (error: any) => {
          if (error.response.data.message === 'no changes detected') {
            manageErrorResponse({
              toastInstance: toast,
              error: error.response,
              // @ts-ignore
              customMessage: t('errors:no_changes'),
              t,
            });
          } else {
            manageErrorResponse({ toastInstance: toast, error: error.response, t });
          }
          const { errors } = error.response.data;

          (Object.keys(errors) as Array<keyof AccountUpdateableUserData>).forEach((key) => {
            setError(key, {
              type: 'server',
              message: errors[key]!.join('. '),
            });
          });
        },
      });
    }
  };

  const onChangeVat = useAsyncDebounce((val) => {
    // @ts-ignore

    if (workshop?.country !== 'CH' || workshop?.country !== 'LI') {
      validateVat(val, workshop?.country).then((data: boolean) => {
        if (!data) {
          setError('vat_number', {
            type: 'server',
            message: t('forms:vat_number.invalid'),
          });
          setTimeout(() => {
            setFocus('vat_number');
          }, 100);
        } else {
          clearErrors('vat_number');
          setIsVatValidated(false);
        }
      });
    }
  }, 2000);
  const getCharge = () => {
    if (workshopHasOnlyRejectedVerifications) {
      return 0;
    } else {
      return subscription?.plan?.charges?.update_workshop_data?.price ?? 0;
    }
  };

  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (updateWorkshopBaseDataMutation.isLoading || accountUpdateFormMutation.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <Container
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        py={{ base: '4', md: '8' }}
        px={0}
        opacity={accountUpdateFormMutation.isLoading ? 0.5 : 1}
        data-test-id="update-form"
        centerContent={false}
        m={0}
        maxW="container.lg"
      >
        {getCharge() > 0 ? (
          <Box justifyContent={'center'}>
            <HStack mb={50}>
              <Box pt={2}>
                <Icon as={IoWarning} boxSize="10" color={'orange'} padding={0} margin={0} />
              </Box>
              <Box>
                {' '}
                <Text ml={'3'} fontSize={'md'}>
                  {t('workshop:update.warning')}
                </Text>
              </Box>
            </HStack>
          </Box>
        ) : null}
        <Stack spacing="4">
          <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold}>
            {t('workshop:registration.stepper.one.info.company.label')}
          </Text>
          <Box pl={{ base: 0, lg: 4 }}>
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }} py={FORMFIELD_CONFIG.spacing}>
              {simpleInput({
                name: 'name',
                label: t('forms:name.label'),
                placeholder: t('forms:name.placeholder'),
                register,
                errors,
                schema,
                customClass: 'pii',
              })}
              {simpleInput({
                name: 'vat_number',
                label: t('forms:vat_number.label'),
                placeholder: t('forms:vat_number.placeholder'),
                register,
                errors,
                customClass: 'pii',
                schema,
                leftAddon: workshop?.country !== 'CH' ? workshop?.country : 'CHE',
                customHook: (val) => {
                  setIsVatValidated(true);
                  const formatter = get(SupportedCountriesVatFormatting, workshop?.country);
                  if (formatter) {
                    setValue('vat_number', formatter(val));
                  }
                  onChangeVat(val);
                },
              })}
            </Stack>
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }} py={FORMFIELD_CONFIG.spacing}>
              {simpleInput({
                name: 'city',
                label: t('forms:city.label'),
                placeholder: t('forms:city.placeholder'),
                register,
                errors,
                schema,
                customClass: 'pii',
              })}
              {simpleInput({
                name: 'zipcode',
                label: t('forms:zipcode.label'),
                placeholder: t('forms:zipcode.placeholder'),
                register,
                customHook: (val) => setValue('zipcode', val.replace(/ /g, '')),
                errors,
                schema,
                customClass: 'pii',
              })}
            </Stack>
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }} py={FORMFIELD_CONFIG.spacing}>
              {simpleInput({
                name: 'address',
                label: t('forms:address.label'),
                placeholder: t('forms:address.placeholder'),
                register,
                errors,
                customClass: 'pii',
                schema,
              })}
            </Stack>
          </Box>

          <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold}>
            {t('workshop:registration.stepper.one.info.contact.label')}
          </Text>
          <Box pl={{ base: 0, lg: 4 }}>
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }} py={FORMFIELD_CONFIG.spacing}>
              {simpleInput({
                name: 'firstname',
                label: t('forms:firstname.label'),
                placeholder: t('forms:firstname.placeholder'),
                register,
                errors,
                customClass: 'pii',
                schema,
              })}
              {simpleInput({
                name: 'lastname',
                label: t('forms:lastname.label'),
                placeholder: t('forms:lastname.placeholder'),
                register,
                errors,
                schema,
                customClass: 'pii',
              })}
            </Stack>

            <Grid
              gap="6"
              templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
              py={FORMFIELD_CONFIG.spacing}
              justifyContent={'center'}
              alignItems={'flex-start'}
            >
              {simpleInput({
                name: 'email',
                label: t('forms:email.label'),
                placeholder: t('forms:email.placeholder'),
                register,
                customHook: (val) => {
                  setValue('email', val.replace(/ /g, ''));
                },
                errors,
                customClass: 'pii',
                schema,
              })}

              <Flex alignItems={'flex-end'} justifyContent={'flex-end'}>
                <Box alignSelf={'center'} mr={1}>
                  <Tooltip label={t('forms:telephone.info')}>
                    <Box width={'max-content'} fontSize="sm" mb={1.5}>
                      {t('forms:telephone.label')}
                    </Box>
                  </Tooltip>
                  {dropdown({
                    name: 'telephone_prefix',
                    control,
                    errors,
                    register,
                    schema,
                    options: telephonePrefixesOptions(telephonePrefixes, t),
                  })}
                </Box>
                {simpleInput({
                  name: 'telephone',
                  placeholder: t('forms:telephone.placeholder'),
                  customHook: (val) => setValue('telephone', formatTelephone(val, getValues('telephone_prefix'))),
                  register,
                  errors,
                  schema,
                  customClass: 'pii',
                })}
              </Flex>
            </Grid>
          </Box>

          {simpleCheckBox({
            name: 'consent_charge',

            label:
              getCharge() > 0
                ? t('forms:consent_update_charge.text.cost', {
                    charge: formatMultiCurrencyIntl(getCharge(), currency),
                  })
                : t('forms:consent_update_charge.text.no_cost'),
            register,
            errors,
            schema,
          })}
        </Stack>
        <Flex direction="row" py="4" justifyContent={'flex-end'}>
          <Button
            mr={2}
            variant="outline"
            color="error"
            borderColor="error"
            _hover={{
              bg: 'error',
              color: 'white',
            }}
            onClick={() => navigate('/app/w/account/data')}
          >
            {t('common:cancel')}
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={fieldsUntouchedExceptConsentCharge || accountUpdateFormMutation.isLoading || isVatValidated || (submitCount > 0 && !isValid)}
            data-test-id="update-submit-button"
          >
            {t('workshop:card.update.submit')}
          </Button>
        </Flex>
      </Container>
    </>
  );
};

export default AccountUpdateForm;
