import { Box, Button, Checkbox, Flex, Grid, Heading, Text, Textarea, useBreakpointValue, useToast, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import useUpdateWorkshopBaseDataMutation from 'hooks/private/mutations/useUpdateWorkshopBaseDataMutation';
import { Card } from 'layout/Card';
import { PublicPageLayout } from 'layout/PublicPageLayout';
import Lottie from 'lottie-react';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import alexXmas from '../../../assets/animations/alex-xmas.json';

const OnboardingPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  const updateWorkshopBaseDataMutation = useUpdateWorkshopBaseDataMutation();

  const schema = yup.object().shape({
    source: yup.string().required(t('workshop:onboarding.source_required')),
    other: yup.string().when('source', {
      is: 'text-input',
      then: yup.string().required(t('workshop:onboarding.specify_source')),
    }),
  });

  const sourcesLeft = [
    { label: t('workshop:onboarding.discovered_us_through.google'), value: 'google' },
    {
      label: t('workshop:onboarding.discovered_us_through.distributor'),
      value: 'distributor',
    },
    {
      label: t('workshop:onboarding.discovered_us_through.friends_colleagues'),
      value: 'friends-or-colleagues',
    },
    { label: t('workshop:onboarding.discovered_us_through.youtube'), value: 'youtube' },

    { label: t('workshop:onboarding.discovered_us_through.other'), value: 'text-input' },
  ];

  const sourcesRight = [
    { label: t('workshop:onboarding.discovered_us_through.facebook'), value: 'facebook' },
    {
      label: t('workshop:onboarding.discovered_us_through.instagram'),
      value: 'instagram',
    },
    {
      label: t('workshop:onboarding.discovered_us_through.magazine'),
      value: 'magazine-blog',
    },
    {
      label: t('workshop:onboarding.discovered_us_through.trade_fair_event'),
      value: 'trade-fair-or-event',
    },
  ];

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<any> = (data) => {
    let discovered = data.source === 'text-input' ? data.other : data.source;
    const workshopWithDiscoveredBy = { discovered_us_through: discovered };

    updateWorkshopBaseDataMutation.mutate(workshopWithDiscoveredBy, {
      onSuccess: () => {
        navigate('/app/w/dashboard');
      },
      onError: (error: any) => {
        manageErrorResponse({ toastInstance: toast, error: error.response, t });
      },
    });
  };

  const source = watch('source');
  const other = watch('other');

  const handleCheckboxChange = (value: string) => {
    setValue('source', value, { shouldValidate: true });
  };

  const isSubmitDisabled = !source || (source === 'text-input' && !other);

  return (
    <PublicPageLayout>
      <Grid w="full" h="full" placeItems="center" as="form" onSubmit={handleSubmit(onSubmit)}>
        <Card width={'60%'} minWidth={'320'}>
          <Box data-test-id="setup-completed-content" p={10}>
            <Text color="muted" fontSize="md">
              <Trans i18nKey="workshop:onboarding.discovered_us_through_question" />
            </Text>

            <Heading size={'xs'} my={10}>
              {t('workshop:onboarding.discovered_us')}
            </Heading>

            <Flex direction={useBreakpointValue({ base: 'column', md: 'row' })}>
              <VStack spacing={6} justifyContent={'flex-start'} alignItems={'flex-start'} mr={20}>
                {useBreakpointValue({ base: sourcesRight, md: sourcesLeft })?.map((option) => (
                  <Controller
                    key={option.value}
                    name="source"
                    control={control}
                    render={({ field }) => (
                      <Flex align="center" key={option.value}>
                        <Checkbox {...field} isChecked={field.value === option.value} onChange={() => handleCheckboxChange(option.value)} size="xl">
                          <Text pl={3} fontSize={'sm'}>
                            {option.label}
                          </Text>
                        </Checkbox>
                      </Flex>
                    )}
                  />
                ))}
              </VStack>
              <VStack spacing={6} justifyContent={'flex-start'} alignItems={'flex-start'} mt={useBreakpointValue({ base: 6, md: 'inherit' })}>
                {useBreakpointValue({ base: sourcesLeft, md: sourcesRight })?.map((option) => (
                  <Controller
                    key={option.value}
                    name="source"
                    control={control}
                    render={({ field }) => (
                      <Flex align="center" key={option.value}>
                        <Checkbox {...field} isChecked={field.value === option.value} onChange={() => handleCheckboxChange(option.value)} size="xl">
                          <Text pl={3} fontSize={'sm'}>
                            {option.label}
                          </Text>
                        </Checkbox>
                      </Flex>
                    )}
                  />
                ))}
              </VStack>
            </Flex>
            {errors.source && <Text color="red.500">{errors.source.message}</Text>}

            {source === 'text-input' && (
              <Box mt={4}>
                <Controller
                  name="other"
                  control={control}
                  render={({ field }) => (
                    <Textarea {...field} placeholder={t('workshop:onboarding.discovered_us_placeholder')} _placeholder={{ opacity: 0.5 }} />
                  )}
                />
                {errors.other && <Text color="red.500">{errors.other.message}</Text>}
              </Box>
            )}

            <Flex justifyContent="flex-end" px={4} mt={6}>
              <Button
                px={8}
                variant={'primary'}
                disabled={updateWorkshopBaseDataMutation.isLoading || isSubmitDisabled}
                isLoading={updateWorkshopBaseDataMutation.isLoading}
                width={useBreakpointValue({ base: '100%', md: 'inherit' })}
                type="submit"
              >
                {t('common:lets_go')}
              </Button>
            </Flex>
          </Box>
        </Card>
      </Grid>
      <Box w={useBreakpointValue({ base: '18%', md: '28%' })} position="fixed" bottom={0} left={0}>
        <Lottie animationData={alexXmas} loop={true} />
      </Box>
    </PublicPageLayout>
  );
};

export default OnboardingPage;
