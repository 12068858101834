import { Heading, HeadingProps, useBreakpointValue } from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import React from 'react';

import { FONT_WEIGHTS } from '../global/Fonts';

interface IPageHeadingProps extends HeadingProps {
  title: string;
  isLoading?: boolean;
}

const PageHeading: React.FC<IPageHeadingProps> = ({
  title,
  isLoading = false,
  textAlign = 'left',
  size = useBreakpointValue({ base: 'sm', lg: 'md' }),
  ...rest
}) => {
  return (
    <Heading size={size} fontWeight={FONT_WEIGHTS.bold} textAlign={textAlign} {...rest}>
      {isLoading ? <Skeleton height="20px" /> : title}
    </Heading>
  );
};

export default PageHeading;
