import { Box, IconButton } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FaPen } from 'react-icons/fa';

interface HoverableBoxProps {
  children: React.ReactNode;
  onOpen?: () => void;
  [key: string]: any; // This allows any additional props
}

const HoverableBox: React.FC<HoverableBoxProps> = ({ children, onOpen, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (isHovered && onOpen) {
      onOpen();
    }
  };

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
      position="relative"
      display="flex"
      alignItems="center"
      borderBottom={isHovered ? '1.6px solid #3182CE' : '1.6px solid transparent'}
      cursor={isHovered ? 'pointer' : 'default'}
      pr={9}
      {...props}
      style={{
        transition: 'border-bottom 0.3s ease-in-out, transform 0.3s ease-in-out',
        transform: isHovered ? 'scale(1.01)' : 'scale(1)',
      }}
    >
      {children}

      <IconButton
        icon={<FaPen />}
        size="sm"
        position="absolute"
        right="0"
        color={'blue.500'}
        aria-label="Edit"
        ml={3}
        onClick={handleClick}
        style={{
          backgroundColor: 'transparent',
          opacity: isHovered ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
      />
    </Box>
  );
};

export default HoverableBox;
