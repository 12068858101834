import { useToast } from '@chakra-ui/react';
import { Results } from 'api/types';
import { AxiosInstance } from 'axios';
import config from 'config';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useAxios } from '../../../context/AxiosContextProvider';

export interface StoredVerificationsInfo {
  id: null;
  type: string;
  comment: string;
}

export interface ServiceDocumentData {
  type: string;
  comment: string | undefined;
  file_uuid: string;
}

async function store(
  axios: AxiosInstance,
  serviceDocumentData: ServiceDocumentData,
  serviceId: number,
): Promise<Results<StoredVerificationsInfo>> {
  const { data } = await axios.post(
    `${config.apiBaseUrl}/workshop/services/${serviceId}/documents`,
    serviceDocumentData,
  );
  return data;
}

export default function useStoreServiceDocumentMutation(serviceId: number) {
  const axios = useAxios();
  const { t } = useTranslation();
  const toast = useToast();

  return useMutation<Results<StoredVerificationsInfo>, any, ServiceDocumentData>(
    (uploadedFiles) => store(axios, uploadedFiles, serviceId),
    {
      onError: (error: any) => {
        manageErrorResponse({
          toastInstance: toast,
          error: JSON.stringify(error.response?.data?.data || error.message || error),
          t,
        });
      },
    },
  );
}
