import { useToast } from '@chakra-ui/react';
import { Results } from 'api/types';
import { AxiosInstance } from 'axios';
import config from 'config';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useAxios } from '../../../context/AxiosContextProvider';

export interface StoredVerificationsInfo {
  id: number;
  status: 200;
  power_of_attorney_file_id: number;
  business_licence_file_id: number;
  identification_file_id: number;
  workshop_id: number;
  status_reason: null | string;
  workshop_update_request_id: null | number;
  workshop_user_id: number;
  completed_at: null | string;
  submitted_at: string;
  created_at: string;
  updated_at: string;
}

export interface UplodedFilesWithId {
  id: number;
  uploadedFiles: {
    power_of_attorney_uuid: string | null;
    business_licence_uuids: Array<string | null>;
    identification_uuid: string | null;
  };
}

async function update(axios: AxiosInstance, uploadedFilesWithId: UplodedFilesWithId): Promise<Results<StoredVerificationsInfo>> {
  const { id, uploadedFiles } = uploadedFilesWithId;
  const { data } = await axios.put(`${config.apiBaseUrl}/workshop/verifications/${id}`, uploadedFiles);
  return data;
}

export default function useUpdateStoreVerificationsMutation() {
  const axios = useAxios();
  const { t } = useTranslation();
  const toast = useToast();

  return useMutation<Results<StoredVerificationsInfo>, any, UplodedFilesWithId>((uploadedFilesWithId) => update(axios, uploadedFilesWithId), {
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error.response?.data?.data || error.message || error),
        t,
      });
    },
  });
}
