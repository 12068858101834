import { useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { Results } from 'api/types';
import axios from 'axios';
import config from 'config';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface VerificationData {
  id: number;
  status: number;
  workshop_name: string;
  systempartner: {
    logo_url: string;
    name: string;
    id: number;
  };
}

async function showVerification(verification_key: string | undefined): Promise<Results<VerificationData>> {
  const { data } = await axios.get(`${config.cpSpfApiBaseUrl}/verifications/${verification_key}`);
  return data;
}

export default function useSpfShowVerificationQuery(verification_key: string | undefined) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const toast = useToast();

  return useQuery(getKey(verification_key), () => showVerification(verification_key), {
    enabled: !!verification_key,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: (error: any) => {
      Sentry.captureException(error, {
        extra: { verification_key },
      });
      navigate('/complete-registration/error', { replace: true });
      return toast({
        title:
          error?.response?.data?.status >= 400 && error?.response?.data?.status < 500
            ? t('pages:workshop_verification_request.invalid_link')
            : error?.response?.data?.message,
        status: 'error',
        duration: 90000,
        isClosable: true,
        position: 'top',
      });
    },
  });
}

function getKey(verification_key: string | undefined): QueryKey {
  return ['SpfShowVerification', verification_key];
}

useSpfShowVerificationQuery.getKey = getKey;
