import { Box } from '@chakra-ui/react';
import PageMetatags from 'layout/PageMetatags';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import VerificationForm from './VerificationForm';

const AccountVerificationPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageMetatags title={t('pages:workshop_verification_request.label')} />
      <Box w={'full'} h={'full'}>
        <VerificationForm />
      </Box>
    </>
  );
};

export default AccountVerificationPage;
