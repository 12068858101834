import { Container } from '@chakra-ui/pro-theme/dist/components';
import { Box, SlideFade, SquareProps, Stack, useBreakpointValue } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Step } from '../../components/Stepper/Step';
import { StepFormCard } from '../../layout/StepFormCard';

type StepArrayProps = {
  title: string | number;
  stepHeader: string;
  stepDescription: string;
  content: Element | undefined | any;
};

interface StepperProps extends SquareProps {
  steps?: Array<StepArrayProps>;
  currentStep: number;
  warning?: any;
  content: typeof Container | any;
  isLoading: boolean;
  overWriteDesign?: boolean;
  abTestNewDesign?: boolean;
}

//test
export const Stepper = (props: StepperProps) => {
  const { steps = [], currentStep, warning, content, isLoading, overWriteDesign, abTestNewDesign } = props;
  const cardEl = useRef(null);
  const posthog = usePostHog();
  const location = useLocation();
  const pagepathIncludesSignup = location.pathname.includes('signup');
  const abTestSignupFormSplit = posthog.getFeatureFlag('signupFormTrust') === 'SignupFormSplit';
  const abDesignTest =
    (posthog.getFeatureFlag('signupForm2') === 'SignupFormLongNewDesign' || posthog.getFeatureFlag('signupForm2') === 'SignupFormSplitNewDesign') &&
    pagepathIncludesSignup;
  const stepperWidth = useBreakpointValue({
    base: '100%',
    sm: '90%',
    md: '70%',
    lg: '60%',
    xl: '60%',
    '2xl': '70%',
  });

  return (
    <Box mt={overWriteDesign ? 20 : 0}>
      <Box display="flex" justifyContent="center" alignItems="center">
        {steps.length !== 0 && (
          <Stack
            direction={'row'}
            spacing={0}
            paddingBlock={{ base: 3, lg: abTestSignupFormSplit ? 5 : 10 }}
            alignItems={'center'}
            width={abTestNewDesign || overWriteDesign ? stepperWidth : 'full'}
          >
            {steps.map((step: StepArrayProps, key: number) => (
              <Step
                key={key}
                cursor="pointer"
                title={step.title.toString()}
                isActive={currentStep === key}
                isCompleted={currentStep > key}
                isFirstStep={key === 0}
                isLastStep={steps.length === key + 1}
              />
            ))}
          </Stack>
        )}
      </Box>
      <SlideFade offsetY={'10%'} in={!isEmpty(warning)}>
        <>{warning}</>
      </SlideFade>
      <Stack direction={['column']} ref={cardEl}>
        {abDesignTest || overWriteDesign ? (
          <>{content}</>
        ) : (
          <StepFormCard
            currentStep={currentStep}
            abDesignTest={abDesignTest}
            isLoading={isLoading}
            stepHeader={steps?.[currentStep]?.stepHeader}
            stepDescription={steps?.[currentStep]?.stepDescription}
            isInspectionPlan={steps.length === 0}
          >
            <>{content}</>
          </StepFormCard>
        )}
      </Stack>
    </Box>
  );
};
