import { useToast } from '@chakra-ui/react';
import { Country, Results } from 'api/types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { DURATION } from '../../../constants';

const defaultData: Country = {
  ip: '',
  country: 'DE',
};

const api = 'https://api.country.is';
async function getCountryFromApi(countries: string[]): Promise<Country> {
  const { data } = await axios.get(api);
  const country = data?.country;
  // if the api fails to get the ip address of the user and the country related we set German as default
  if (!countries.includes(country)) {
    return defaultData;
  }

  return data;
}
export default function useCountryQuery(countries: string[] = []): any {
  const { t } = useTranslation();
  const toast = useToast();

  return useQuery(getKey(), () => getCountryFromApi(countries), {
    enabled: true,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    refetchOnWindowFocus: false,
    cacheTime: DURATION.Hour * 5,
    staleTime: DURATION.Hour,
    onError: (error: any) => {},
  });
}

function getKey(): QueryKey {
  return ['Country'];
}

useCountryQuery.getKey = getKey;
