import { Text } from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import axios from 'axios';
import PollingCard from 'features/account-setup/polling/PollingCard';
import useTokenQuery from 'hooks/queries/useTokenQuery';
import { isNil } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import config from '../../config';
import useWorkshopQuery from '../../hooks/queries/workshop/useWorkshopQuery';
import { ConfirmationScaffolding } from './ConsentAlertModal';

export interface IPollingConsentModal {
  onClose?: () => void;
  pollingCompleted?: () => void;
  pollingCondition: (data: any) => boolean;
  header: string;
  children: any;
  url: string;
  actionCaption?: string;
  cancelCaption?: string;
  data?: object;
  showSuccessAnimation?: boolean;
  content?: {
    hide?: boolean;
    icon?: string | React.ReactElement;
    header?: string | React.ReactElement;
    checkbox?: {
      name?: string;
      label?: string;
      description?: string;
    };
    footer?: {
      buttons: {
        cancelCaption?: string;
        actionCaption?: string;
      };
      before?: boolean;
      top?: string | React.ReactElement;
      bottom?: string | React.ReactElement;
    };
  };
  styleConfig?: {
    variant?: string;
    minW?: string;
    maxW?: string;
    motionPreset?: any;
    w?: string;
  };
}

export default NiceModal.create((props: IPollingConsentModal) => {
  const [error, setError] = useState(false);
  const modal = useModal();
  const { t } = useTranslation();
  const {
    pollingCompleted,
    pollingCondition,
    children,
    showSuccessAnimation = true,
    data = {},
    url,
    onClose,
    content = {},
    styleConfig = {},
  } = props;

  const [isPolling, setIsPolling] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const tokenQuery = useTokenQuery();
  const token = tokenQuery.data;
  const { mutate, isLoading } = useMutation<any, Error, any>(async () => {
    return await axios
      .put(`${config.apiBaseUrl}/${url}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .catch((err: { response: { status: number } }) => {
        return Promise.reject(err);
      })
      .then(() => queryClient.invalidateQueries(useWorkshopQuery.getKey()));
  });

  const onSubmit = (data: any) => {
    mutate(data, {
      onSuccess: () => {
        setIsPolling(true);
      },
      onError: (err: any) => {
        const { message } = err.response.data;
        const error500 = err.response.status === 500;

        if (error500) {
          modal.hide();
          navigate('/app/w/error');
        }
        setError(message);
      },
    });
  };
  return (
    <ConfirmationScaffolding
      t={t}
      isLoading={isLoading}
      styleConfig={styleConfig}
      onClose={onClose}
      content={{
        ...content,
        hide: isPolling,
        header: isPolling ? undefined : content?.header,
      }}
      onSubmit={onSubmit}
      closeOnOverlayClick={!isPolling}
      modal={modal}
    >
      {isPolling ? (
        <PollingCard
          noCard
          showSuccessAnimation={showSuccessAnimation}
          pollingCondition={pollingCondition}
          success={() => {
            setIsPolling(false);
            if (!isNil(pollingCompleted)) {
              pollingCompleted();
              modal.hide();
              modal.remove();
              return;
            }
            queryClient.invalidateQueries(useWorkshopQuery.getKey());
            modal.hide();
            modal.remove();
          }}
        />
      ) : (
        <>
          {children}

          <Text fontSize="sm" color={'error'}>
            {error}
          </Text>
        </>
      )}
    </ConfirmationScaffolding>
  );
});
