/**
 * Sentry initialization
 */
import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import {
  Routes,
} from 'react-router-dom';

import config from '../config';
import { Environments } from '../helpers/environments';

export const isSentryActivatedEnvironment = () =>
  [Environments.QA, Environments.PROD].includes(config.appEnv);

export const initSentry = () => {
  if (isSentryActivatedEnvironment()) {
    Sentry.init({
      release: config.sentry.release,
      dsn: config.sentry.dsn,
      beforeSend(event, hint) {
        // Check if the event is a TypeError with the message "Failed to fetch"
        if (hint && hint.originalException) {
          //@ts-ignore
          const errorMsg = hint.originalException?.message;
          if (
            hint.originalException instanceof TypeError &&
            errorMsg === 'Failed to fetch'
          ) {
            // Return null to drop the event and prevent it from being sent to Sentry
            return null;
          }
        }

        // Check if the event has a 401 status code
        //@ts-ignore
        if (event.request && event.request?.status_code === 401) {
          // Return null to drop the event and prevent it from being sent to Sentry
          return null;
        }

        return event;
      },
      integrations: [
        new posthog.SentryIntegration(posthog, config.sentry.organization_url, config.sentry.project_id)
      ],
      // don't record when no errors occur
      // replaysSessionSampleRate: 0,
      // always record when errors occur
      //replaysOnErrorSampleRate: 1.0,
      environment: config.appEnv,
      tracesSampleRate: 0.2,
    });
    Sentry.setTag('tag', config.buildTag);
  }
};

export const SentryRoutes = isSentryActivatedEnvironment()
  ? Sentry.withSentryReactRouterV6Routing(Routes)
  : Routes;
