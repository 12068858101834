import { AxiosInstance } from 'axios';
import config from 'config';
import { useMutation } from 'react-query';

import { useAxios } from '../../../context/AxiosContextProvider';

async function update(
  axios: AxiosInstance,
  conversationId: number,
  message: {},
): Promise<object> {
  const { data } = await axios.post(
    `${config.apiBaseUrl}/conversations/${conversationId}/message`,
    message,
  );
  return data;
}

export default function useAddMessageToConversationMutation(conversationId: number) {
  const axios = useAxios();
  return useMutation((message: {}) => update(axios, conversationId, message));
}
