import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading, Link, SimpleGrid, Stack } from '@chakra-ui/react';
import SHRImageFallback from 'assets/vimeo-previews/create-service-history.jpeg';
import SHRImage from 'assets/vimeo-previews/create-service-history.webp';
import DSBExplainedFallback from 'assets/vimeo-previews/dsb-explained.jpeg';
import DSBExplained from 'assets/vimeo-previews/dsb-explained.webp';
import GetInspectionPlansFallback from 'assets/vimeo-previews/get-inspection-plans.jpeg';
import GetInspectionPlans from 'assets/vimeo-previews/get-inspection-plans.webp';
import { useIsWorkshopBlocked } from 'helpers/general';
import { ContentCard } from 'layout/ContentCard';
import PageMetatags from 'layout/PageMetatags';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import GetInTouchCard from '../components/GetInTouchCard';
import { ModalVideo, VideoPlaceholder } from '../components/ModalVideo';
import config from '../config';
import { faq } from '../configs/faq';
import { useUserContext } from '../context/UserContextProvider';

const HelpSupportPage = () => {
  const { t } = useTranslation();
  const userContext = useUserContext();
  const supportPhoneNumber = userContext?.config?.support_telephone;
  const isWorkshopBlocked = useIsWorkshopBlocked();
  const navigate = useNavigate();

  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  const handleOpenNewTab = () => {
    navigate('/app/w/help/service-process');
  };

  return (
    <>
      <PageMetatags title={`${t('pages:support.label')} `} />
      {userContext.workshop?.language.toLowerCase() == 'de' && (
        <ContentCard minH={'unset'} header={t('pages:support.label')}>
          <SimpleGrid minChildWidth={'300px'} spacingX={5} spacingY={5}>
            <Stack justifyContent="space-between">
              <Heading size={'xxs'}>{t('pages:support.service_process_explained.title')}</Heading>
              <Box position="relative">
                <VideoPlaceholder image={'/images/service_process_explained.png'} onClick={handleOpenNewTab} isPageLink />
              </Box>
            </Stack>
            <Stack height={'full'} data-test-id="support-video" justifyContent="space-between">
              <Heading size={'xxs'} flexGrow={1}>
                {t('pages:support.videos.service_history_request')}
              </Heading>
              <ModalVideo videoId={'743410210'} placeholder={SHRImage} fallback={SHRImageFallback} />
            </Stack>
            <Stack height={'full'} data-test-id="support-video" justifyContent="space-between">
              <Heading size={'xxs'} flexGrow={1}>
                {t('pages:support.videos.dsb')}
              </Heading>
              <ModalVideo videoId={'743453199'} placeholder={DSBExplained} fallback={DSBExplainedFallback} />
            </Stack>
            <Stack height={'full'} data-test-id="support-video" justifyContent="space-between">
              <Heading size={'xxs'} flexGrow={1}>
                {t('pages:support.videos.inspection_plan')}
              </Heading>
              <ModalVideo videoId={'744127373'} placeholder={GetInspectionPlans} fallback={GetInspectionPlansFallback} />
            </Stack>
          </SimpleGrid>
        </ContentCard>
      )}
      <ContentCard minH={'unset'} header={t('pages:support.faq.label')}>
        <Accordion allowToggle>
          {faq.map((questionConfig: { links: any[]; title: string }, questionIndex: number) => {
            return (
              <AccordionItem key={questionIndex + 1} borderTop={0} borderColor={'blue.600'} borderBottomWidth={2}>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {t(`pages:support.faq.${questionConfig.title}.question`)}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Trans i18nKey={`pages:support.faq.${questionConfig.title}.answer`} values={{ phoneNumber: supportPhoneNumber }}>
                    {questionConfig?.links.map((link, index) => {
                      return <Link key={index} color="accent" href={link} isExternal />;
                    })}
                  </Trans>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </ContentCard>
      <GetInTouchCard />
      <ContentCard minH={'unset'} header={t('pages:support.more_info.header')}>
        <Trans i18nKey="pages:support.more_info.description">
          <Link color="accent" href={config.publicWebsiteUrl} isExternal />
        </Trans>
      </ContentCard>
    </>
  );
};

export default HelpSupportPage;
