import { Box, Button, Container, Divider, Grid, Heading, List, ListItem, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import GetInTouchCard from 'components/GetInTouchCard';
import useChargeBee from 'hooks/queries/useChargeBee';
import PageMetatags from 'layout/PageMetatags';
import { PublicPageLayout } from 'layout/PublicPageLayout';
import { delay, get } from 'lodash';
import Lottie from 'lottie-react';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { BILLING_CYCLE, SubscriptionPlan } from '../../../../api/types';
import alexXmas from '../../../../assets/animations/alex-xmas.json';
import { useUserContext } from '../../../../context/UserContextProvider';
import { formatMultiCurrencyIntl } from '../../../../helpers/formatCurrency';
import { simpleInput } from '../../../../helpers/makeFormFields';
import usePlansQuery from '../../../../hooks/queries/usePlansQuery';
import { ContentCard } from '../../../../layout/ContentCard';
import { AccountUpdateableUserData } from '../../../profile/update/AccountUpdateForm';

const schema = yup
  .object({
    plan_id: yup.number().required(),
    coupon_id: yup.string().nullable(),
    billing_cycle: yup.string().required(),
  })
  .required();

export const SubscriptionSummaryPage = () => {
  const posthog = usePostHog();
  const [sendPostHogEvent, setSendPostHogEvent] = useState(true);
  const { planId, billingCycle } = useParams();
  const userContext = useUserContext();
  const { t } = useTranslation();
  const plansQuery = usePlansQuery();
  const { triggerPayment } = useChargeBee();
  const currency = userContext.workshop?.currency;
  const plansData = plansQuery.data?.data || [];
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountUpdateableUserData>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      billing_cycle: billingCycle || BILLING_CYCLE.MONTHLY,
      plan_id: planId,
    },
  });
  const isMobile = useBreakpointValue({ base: true, md: false });

  const containerWidth = useBreakpointValue({
    base: '100%',
    sm: '90%',
    md: '80%',
    lg: '80%',
    xl: '80%',
    '2xl': '100%',
  });
  const selectedPlan: SubscriptionPlan = plansData.find((plan: SubscriptionPlan) => parseInt(plan?.id) === parseInt(planId));

  const [disableClick, setDisableClick] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (disableClick) {
      delay(() => {
        setDisableClick(false);
      }, 3000);
    }
  }, [disableClick]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (isNaN(Number(planId))) {
    navigate('/404', { replace: true });
    return null;
  }

  const onSubmit: SubmitHandler<AccountUpdateableUserData> = (data) => {
    setDisableClick(true);

    triggerPayment(data);
  };
  const priceObject = get(selectedPlan, 'price', {});
  const price = get(priceObject, billingCycle, {});
  const total = price?.discount ? price?.discount?.amount : price?.amount;
  const setupFee = selectedPlan?.charges?.setup_fee?.price;

  const abTestNewDesign =
    posthog.getFeatureFlag('signupForm2') === 'SignupFormLongNewDesign' || posthog.getFeatureFlag('signupForm2') === 'SignupFormSplitNewDesign';

  if (sendPostHogEvent) {
    posthog.capture('subscription_summary_page_pageview');
    setSendPostHogEvent(false);
  }

  return (
    <PublicPageLayout abTestNewDesign={abTestNewDesign}>
      <PageMetatags title={t('pages:subscription.meta.title')}>
        <script src="https://js.chargebee.com/v2/chargebee.js" />
      </PageMetatags>

      <Container
        mt={abTestNewDesign ? '20' : 0}
        as={'form'}
        onSubmit={handleSubmit(onSubmit)}
        data-test-id="subscription-plan-card"
        position={'relative'}
        width={abTestNewDesign ? containerWidth : 'full'}
      >
        <Grid gap={{ base: 6, lg: 10 }}>
          {userContext.workshop?.is_legacy && (
            <Box w="full" borderRadius="lg" p={4} bgColor="green.100">
              {t('pages:subscription.credit.text', {
                credit: formatMultiCurrencyIntl(userContext.workshop?.wp_wallet_balance, currency),
              })}
            </Box>
          )}

          <ContentCard>
            <Heading size="xs" alignSelf="start">
              {t('pages:subscription.summary.title')}
            </Heading>
            <VStack spacing={6} alignItems={'start'} data-test-id="subscription-summary">
              <Text>{t('pages:subscription.summary.review')}</Text>
              <List>
                <ListItem py={3}>
                  <h1 style={{ fontSize: 20 }}>
                    <strong>{t('pages:subscription.summary.selected_plan')} </strong> {selectedPlan?.name}
                  </h1>
                </ListItem>
                {!selectedPlan?.is_pay_per_use && (
                  <ListItem>
                    <strong>{t('pages:subscription.summary.billing_cycle')}</strong> {t(`pages:subscription.payment_interval.${billingCycle}`)}
                  </ListItem>
                )}
                <ListItem>
                  <strong>{t('pages:subscription.summary.recurring_charge')} </strong> {formatMultiCurrencyIntl(total, currency)}
                </ListItem>

                {setupFee && (
                  <ListItem>
                    <strong>{t('pages:subscription.charges.setup_fee')}</strong> {formatMultiCurrencyIntl(setupFee, currency)}
                  </ListItem>
                )}
              </List>

              <VStack spacing={10}>
                <Text>{t('pages:subscription.summary.info')}</Text>

                <Divider />
                <Box alignSelf={'self-start'} width={'45%'}>
                  {simpleInput({
                    name: 'coupon_id',
                    label: t('forms:coupon.label'),
                    placeholder: t('forms:coupon.placeholder'),
                    register,
                    errors,
                    schema,
                  })}
                </Box>

                <Box alignSelf="end">
                  <Button
                    variant="secondary"
                    size={'lg'}
                    type="submit"
                    alignSelf={'flex-end'}
                    mr={2}
                    onClick={() => navigate(`/app/setup/subscription?billingCycle=${billingCycle}&planId=${planId}`)}
                  >
                    {t('common:back')}
                  </Button>
                  <Button disabled={disableClick} variant="primary" size={'lg'} type="submit" alignSelf={'flex-end'}>
                    {t('pages:subscription.submit')}
                  </Button>
                </Box>
              </VStack>
            </VStack>
          </ContentCard>
          <GetInTouchCard abTestNewDesign={abTestNewDesign} />
        </Grid>
        {abTestNewDesign && !isMobile && (
          <Box position={'fixed'} left={0} bottom={0} w={'12%'} h={'auto'}>
            <Lottie animationData={alexXmas} loop={true} />
          </Box>
        )}
      </Container>
    </PublicPageLayout>
  );
};
export default SubscriptionSummaryPage;
