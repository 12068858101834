import { Button, Container, Flex, Icon, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, subDays } from 'date-fns';
import { get, merge } from 'lodash';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { CHARGE_KEYS, ServiceRecord } from '../../api/types';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { useUserContext } from '../../context/UserContextProvider';
import { formatMultiCurrencyIntl } from '../../helpers/formatCurrency';
import { dateInput, numberInput, simpleCheckBox, simpleInput } from '../../helpers/makeFormFields';
import UnlockFeature from '../../helpers/unlockFeature';
import useAddonsQuery from '../../hooks/queries/useAddonsQuery';

export enum FORM_TYPES {
  PLUS_DSB = 'oe_plus_dsb',
  HYUNDAI = 'hyundai',
}

export interface IBasicForm {
  date: string;
  vin: string;
  mileage: number;
  workshop_reference?: string;
}

export const BasicFormKeys = ['vin', 'date', 'mileage', 'workshop_reference'];

export const BasicDataStep = (props: {
  vin: string;
  srFastlaneAvailable: boolean;
  backendErrors: object;
  stepOneFormData: Partial<ServiceRecord>;
  formType: FORM_TYPES;
  // eslint-disable-next-line no-unused-vars
  saveInState: (data: any) => void;
  // eslint-disable-next-line no-unused-vars
  setStep: (step: number) => void;
  isUpdate?: boolean;
}) => {
  const { vin, srFastlaneAvailable, formType, backendErrors, stepOneFormData, setStep, saveInState } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userContext = useUserContext();
  const workshop = userContext.workshop;
  const subscription = userContext.workshop?.subscription;
  const addonsQuery = useAddonsQuery();
  const addonsData = addonsQuery.data?.data || [];
  const currency = workshop?.currency;
  const getCharge = () => {
    return parseInt(get(subscription?.plan?.charges, `${CHARGE_KEYS.FASTLANE}.price`, 0), 10);
  };

  const onKeyDown = (event: KeyboardEvent): void => {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  };

  const maxAgeDays = stepOneFormData?.max_age_in_days ?? 0;

  const schema = yup
    .object({
      vin: yup.string().label(t('forms:vin.label')).typeError(t('forms:vin.error')).required(),
      date: yup
        .date()
        .label(t('forms:date.label'))
        .min(subDays(new Date(), parseInt(maxAgeDays) + 1), t('forms:date.validation.min'))
        .max(addDays(new Date(), 1), t('forms:date.validation.max'))
        .typeError(t('forms:date.type_error'))
        .required(t('forms:date.validation.required')),
      mileage: yup
        .number()
        .label(t('forms:mileage.label'))
        .positive(t('forms:mileage.validation.min'))
        .typeError(t('forms:mileage.type_error'))
        .required(),
      workshop_reference: yup.string().label(t('forms:workshop_reference.label')).max(255).required(),
      fastlane: yup.boolean(),
    })
    .required();

  const { handleSubmit, formState, register, control, reset, setValue, watch } = useForm<IBasicForm>({
    defaultValues: stepOneFormData,
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const { isValid } = formState;
  const errors = merge(formState.errors, backendErrors);
  const fastlaneFormValue = watch('fastlane');

  useEffect(() => {
    if (srFastlaneAvailable && ![FORM_TYPES.PLUS_DSB, FORM_TYPES.HYUNDAI].includes(formType) && subscription?.plan?.is_pay_per_use) {
      setValue('fastlane', false);
    }
  }, [fastlaneFormValue]);

  useEffect(() => {
    reset(stepOneFormData);
  }, [stepOneFormData]);
  const onSubmit: SubmitHandler<IBasicForm> = (formData) => {
    saveInState(formData);
    setStep(1);
  };
  if (addonsQuery.isLoading) {
    return <LoadingSpinner />;
  }

  const handleChangeOnlyNmbs = (event: any) => {
    if (event.target.value.includes(',')) {
      const sanitizedValue = event.target.value.replace(/[,]/g, '');
      setValue('mileage', sanitizedValue.slice(0, -1));
    }
    if (event.target.value.includes('.')) {
      const sanitizedValue = event.target.value.replace(/[.]/g, '');
      setValue('mileage', sanitizedValue.slice(0, -1));
    }
    setValue('mileage', event.target.value);
  };

  return (
    <Container as="form" onSubmit={handleSubmit(onSubmit)} data-test-id="service-record-basic-form" maxWidth={'unset'} m={0}>
      <Stack direction={['column']} w={{ base: 'full', lg: '50%' }} spacing={2}>
        {simpleInput({
          name: 'vin',
          label: t('forms:vin.label'),
          register,
          errors,
          placeholder: 'VF133333333333333',
          disabled: true,
          schema,
        })}
        {dateInput({
          name: 'date',
          label: t('forms:date.label'),
          control,
          errors,
          schema,
          register,
          minDate: subDays(new Date(), parseInt(maxAgeDays) + 1),
          maxDate: new Date(),
        })}
        {numberInput({
          name: 'mileage',
          label: t('forms:mileage.label'),
          placeholder: '125000',
          register,
          onChange: handleChangeOnlyNmbs,
          errors,
          schema,
        })}
        {simpleInput({
          name: 'workshop_reference',
          label: t('forms:workshop_reference.label'),
          placeholder: 'A-23840KS',
          customKeyDownHook: (evt) => onKeyDown(evt),
          register,
          schema,
          errors,
        })}
        {srFastlaneAvailable && ![FORM_TYPES.PLUS_DSB, FORM_TYPES.HYUNDAI].includes(formType) && !subscription?.plan?.is_pay_per_use ? (
          simpleCheckBox({
            name: 'fastlane',
            label: `${t('forms:fastlane.label')} (${formatMultiCurrencyIntl(getCharge(), currency)} ${t('pages:subscription.extra_charge')})`,
            description: t('forms:fastlane.description'),
            showAsTooltip: true,
            register,
            errors,
            schema,
            size: 'xl',
          })
        ) : srFastlaneAvailable && ![FORM_TYPES.PLUS_DSB, FORM_TYPES.HYUNDAI].includes(formType) && subscription?.plan?.is_pay_per_use ? (
          <UnlockFeature
            displayCheckBox={simpleCheckBox({
              name: 'fastlane',
              label: t('forms:fastlane.label'),
              showAsTooltip: false,
              isChecked: false,
              register,
              errors,
              schema,
              size: 'xl',
            })}
            vin={vin}
            ppuFastlane={true}
            addons={addonsData}
            currency={currency}
            workshop={workshop}
            onSuccess={() => navigate(`app/w/service-record/create?vin=${vin}`)}
          />
        ) : null}
      </Stack>
      <Flex direction="row-reverse" pt={8} pb={4}>
        <Button type="submit" variant="primary" data-test-id="service-record-basic-next-button" disabled={formState.submitCount! > 0 && !isValid}>
          {t('common:next')}
          <Icon as={FiArrowRight} boxSize="4" />
        </Button>
      </Flex>
    </Container>
  );
};
