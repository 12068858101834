export const highlightVinInput = () => {
  return highlightInput('.vin-input', 'pulse-in');
};

export const highlightServiceEntry = (id: string) => {
  return highlightInput(`service_record_${id}`, 'pulse-in', 1500);
};

export const highlightInput = (
  selector: string,
  pulseClass = 'pulse',
  duration = 1000,
) => {
  const input =
    selector === '.vin-input'
      ? document.querySelector(selector)
      : document.getElementById(selector);

  return new Promise((resolve, reject) => {
    if (!input) {
      reject(`No element found with ${selector}`);
      return;
    }

    if (!input.classList.contains(pulseClass)) {
      input.classList.add(pulseClass);
      setTimeout(() => {
        input.classList.remove(pulseClass);
        resolve('input_animation--success');
      }, duration);
    }
  });
};
