import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { Invoices, PaginatedArrayResults } from '../../api/types';
import config from '../../config';
import { DURATION } from '../../constants';
import { useAxios } from '../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';
async function getInvoices(props: { axios: AxiosInstance; page: number; itemsPerPage: number }): Promise<PaginatedArrayResults<Invoices>> {
  const { axios, page, itemsPerPage } = props;
  const { data } = await axios.get(`${config.apiBaseUrl}/workshop/subscription/invoices`, { params: { page, items_per_page: itemsPerPage } });
  return data;
}

export default function useInvoicesQuery({ page, itemsPerPage }: { page: number; itemsPerPage: number }) {
  const toast = useToast();
  const { t } = useTranslation();
  const axios = useAxios();
  return useQuery(getKey({ page, itemsPerPage }), () => getInvoices({ axios, page, itemsPerPage }), {
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    staleTime: DURATION.Minute * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}
function getKey({ page, itemsPerPage }: { page: number; itemsPerPage: number }): QueryKey {
  return ['Invoices', page, itemsPerPage];
}

useInvoicesQuery.getKey = getKey;
