import { Flex, HStack, Text } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { OutlineErrorButton } from '../../../../components/buttons/OutlineErrorButton';
import AlertModal from '../../../../components/modals/AlertModal';
import { useUserContext } from '../../../../context/UserContextProvider';
import {
  Subscription,
  SUBSCRIPTION_NON_RENEWAL,
} from '../../../../hooks/queries/workshop/useWorkshopQuery';
import { Card } from '../../../../layout/Card';

export function CancelSubscriptionCard(props: { subscription?: Subscription }) {
  const { t } = useTranslation();
  const { subscription } = props;
  const [isCancelled, setIsCancelled] = useState(
    subscription?.status === SUBSCRIPTION_NON_RENEWAL,
  );
  const userContext = useUserContext();
  useEffect(() => {
    setIsCancelled(subscription?.status === SUBSCRIPTION_NON_RENEWAL);
  }, [subscription]);

  return (
    <>
      <Card
        height="fit-content"
        minHeight="unset"
        data-test-id={`${isCancelled ? 'resume' : 'cancel'}-subscription-card`}
      >
        <HStack justifyContent="space-between" spacing="4" py={4} px={4}>
          <Text>{t(`pages:subscription.manage.cancel.description`)}</Text>
          <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
            <OutlineErrorButton
              ml={2}
              onClick={() => {
                NiceModal.show(AlertModal, {
                  content: {
                    header: t(
                      'pages:subscription.manage.cancel.modal.delete_account.header',
                    ),
                    footer: {
                      buttons: {
                        actionCaption: t('common:close'),
                        cancelCaption: '',
                      },
                    },
                  },
                  children: (
                    <Trans
                      i18nKey="pages:subscription.manage.cancel.modal.delete_account.description"
                      values={{
                        phoneNumber: userContext.config?.data?.support_telephone,
                      }}
                    />
                  ),
                });
              }}
            >
              {t('pages:subscription.manage.cancel.modal.delete_account.header')}
            </OutlineErrorButton>
          </Flex>
        </HStack>
      </Card>
    </>
  );
}

export default CancelSubscriptionCard;
