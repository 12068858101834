import { Checkbox, Divider, HStack, Icon, IconProps, Text, VStack } from '@chakra-ui/react';
import ElectricsIcon from 'assets/svgIcons/ElectricsIcon';
import EngineIcon from 'assets/svgIcons/EngineIcon';
import GearboxIcon from 'assets/svgIcons/GearboxIcon';
import SteeringIcon from 'assets/svgIcons/SteeringIcon';
import TireIcon from 'assets/svgIcons/TireIcon';
import * as React from 'react';
// import { useTranslation } from 'react-i18next';
import { BsWrench, IoBanOutline, TbThumbDown, TbThumbUp } from 'react-icons/all';

interface IDescription {
  title: string | null;
  items: Array<string> | null;
}

interface IInspectionTask {
  description_id: string;
  descriptions: Array<IDescription> | null;
  name: string;
  remark: string | null;
}

interface IInspection {
  name: string;
  tasks: Array<IInspectionTask>;
  selectedTasks: Array<{ [key: string]: string }>;
  isInspectionPlanStatusCompleted: boolean;
  isDisabled?: boolean;
  isServiceCompleted?: boolean;
  icon: string | null;
  setSelectedTasks: React.Dispatch<React.SetStateAction<Array<{ [key: string]: string }>>>;
}

const BrakesIcon = (props: IconProps) => (
  <Icon viewBox="0 0 340 340" {...props}>
    <g transform="translate(0.000000,340.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
      <path d="M1524 3240 c-40 -9 -78 -37 -100 -75 -13 -22 -19 -58 -22 -142 l-5 -113 -31 0 c-47 -1 -190 -34 -286 -68 -457 -158 -812 -561 -919 -1042 -84 -380 -10 -775 206 -1104 167 -252 445 -467 728 -564 353 -121 773 -95 1090 66 341 174 589 449 722 801 27 72 73 261 73 302 l0 26 114 5 c100 4 119 7 149 28 60 41 71 71 69 185 -12 591 -345 1151 -866 1460 -198 117 -434 197 -661 224 -129 15 -225 19 -261 11z m333 -340 c396 -83 749 -338 950 -685 86 -148 163 -366 179 -506 l7 -59 -167 0 -166 0 0 24 c0 35 -47 190 -82 271 -139 318 -456 573 -793 637 l-65 13 0 162 c0 190 -14 175 137 143z m-457 -372 c1 -97 13 -144 47 -180 41 -44 52 -48 167 -58 216 -21 394 -105 533 -252 129 -137 195 -294 214 -502 14 -161 51 -196 212 -204 l99 -5 -7 -46 c-28 -188 -145 -409 -302 -572 -214 -222 -491 -339 -803 -339 -141 0 -230 14 -358 57 -328 109 -596 377 -709 708 -73 216 -71 507 7 729 28 81 112 238 165 305 56 74 187 198 261 248 133 90 349 177 452 182 21 1 22 -3 22 -71z" />
      <path d="M1491 1958 c-169 -19 -328 -154 -386 -327 -19 -59 -22 -197 -5 -261 41 -154 165 -284 319 -335 59 -19 197 -22 261 -5 154 41 284 165 335 319 19 59 22 197 5 261 -20 75 -66 154 -123 211 -111 114 -239 157 -406 137z m148 -329 c52 -29 74 -64 79 -125 8 -94 -51 -164 -144 -172 -94 -8 -164 51 -172 143 -6 72 33 140 93 165 37 16 106 11 144 -11z" />
    </g>
  </Icon>
);

const TaskCheckbox = ({
  task,
  value,
  selectedTasks = [],
  isDisabled,
  setSelectedTasks,
}: {
  task: IInspectionTask;
  value: string;
  isDisabled?: boolean;
  selectedTasks: Array<{ [key: string]: string }>;
  setSelectedTasks: React.Dispatch<React.SetStateAction<Array<{ [key: string]: string }>>>;
}) => {
  const isChecked = selectedTasks.some((obj) => obj[task.description_id] === value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTasks((prevState) => {
      const isChecked = prevState?.some((obj) => obj[task.description_id] === e.target.value);
      if (isChecked) {
        return prevState.filter((obj) => obj[task.description_id] !== e.target.value);
      } else {
        const filteredOtherOptions = prevState.filter(
          // eslint-disable-next-line no-prototype-builtins
          (obj) => !obj.hasOwnProperty(task.description_id),
        );
        return [...filteredOtherOptions, { [task.description_id]: e.target.value }];
      }
    });
  };

  return (
    <Checkbox
      isDisabled={isDisabled}
      size={'xl'}
      value={value}
      isChecked={isChecked}
      onChange={handleChange}
      style={{ marginLeft: value !== 'ok' ? 25 : 0 }}
    />
  );
};

const renderDescriptions = (descriptions: Array<IDescription> | null) => {
  return descriptions?.map((description: IDescription) => {
    const title = description?.title;
    const titleItems = description?.items?.map((item: string) => (
      <Text key={item} fontSize={16} as="i" pl={4}>
        • {item}
      </Text>
    ));

    return (
      <React.Fragment key={title}>
        {title && (
          <Text fontSize={16} as="u">
            {title}
          </Text>
        )}
        {titleItems}
      </React.Fragment>
    );
  });
};

const InspectionTask = ({ name, tasks, selectedTasks, isInspectionPlanStatusCompleted, isServiceCompleted, icon, setSelectedTasks }: IInspection) => {
  const iconMap = {
    engine: EngineIcon,
    gearbox: GearboxIcon,
    steering: SteeringIcon,
    tire: TireIcon,
    electrics: ElectricsIcon,
    brakes: BrakesIcon,
  };

  const inspectionTasks = tasks?.map((task: IInspectionTask, index: number) => {
    const isLastItem = tasks?.length - 1 === index;
    return (
      <React.Fragment key={task.description_id}>
        <HStack justifyContent={'space-between'} width="100%" alignItems="flex-start">
          <VStack align="flex-start">
            <Text fontSize={18} mt={1}>
              {task.name}
            </Text>
            {task?.remark && (
              <Text fontSize={16} as="i">
                {task.remark}
              </Text>
            )}
            {renderDescriptions(task.descriptions)}
          </VStack>
          <HStack>
            <TaskCheckbox
              isDisabled={isInspectionPlanStatusCompleted || isServiceCompleted}
              task={task}
              value="ok"
              selectedTasks={selectedTasks}
              setSelectedTasks={setSelectedTasks}
            />
            <TaskCheckbox
              isDisabled={isInspectionPlanStatusCompleted || isServiceCompleted}
              task={task}
              value="not_ok"
              selectedTasks={selectedTasks}
              setSelectedTasks={setSelectedTasks}
            />
            <TaskCheckbox
              isDisabled={isInspectionPlanStatusCompleted || isServiceCompleted}
              task={task}
              value="not_ok_but_repaired"
              selectedTasks={selectedTasks}
              setSelectedTasks={setSelectedTasks}
            />
            <TaskCheckbox
              isDisabled={isInspectionPlanStatusCompleted || isServiceCompleted}
              task={task}
              value="not_checked"
              selectedTasks={selectedTasks}
              setSelectedTasks={setSelectedTasks}
            />
          </HStack>
        </HStack>
        {!isLastItem && <Divider borderColor="gray.100" borderWidth="1px" />}
      </React.Fragment>
    );
  });

  return (
    <>
      <HStack justifyContent={'space-between'} width="100%" style={{ marginTop: 50 }}>
        <HStack>
          {icon && <Icon as={iconMap?.[`${icon}`]} boxSize={9} color={'blue.500'} />}
          <Text as={'b'} fontSize={18}>
            {name}
          </Text>
        </HStack>
        <HStack>
          <Icon as={TbThumbUp} boxSize={9} color={'green.500'} mr={-1.5} />
          <Icon as={TbThumbDown} boxSize={9} color={'red.500'} style={{ marginLeft: 35 }} />
          <Icon as={BsWrench} boxSize={7} color={'blue.500'} style={{ marginLeft: 35 }} />
          <Icon as={IoBanOutline} boxSize={7} color={'black'} style={{ marginLeft: 35, marginRight: 5 }} />
        </HStack>
      </HStack>
      <Divider borderColor="blue.500" borderWidth="1.1px" />
      {inspectionTasks}
    </>
  );
};

export default InspectionTask;
