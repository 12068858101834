import { useToast } from '@chakra-ui/react';
import { Results } from 'api/types';
import axios from 'axios';
import config from 'config';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

interface VerificationData {
  id: number;
  status: number;
  workshop_name: string;
}

export interface UplodedFiles {
  power_of_attorney_uuid: string | null;
  business_licence_uuids: Array<string | null>;
  identification_uuid: string | null;
}

async function store(verification_key: string, uploadedFiles: UplodedFiles): Promise<Results<VerificationData>> {
  const { data } = await axios.put(`${config.cpSpfApiBaseUrl}/verifications/${verification_key}`, uploadedFiles);
  return data;
}

export default function useUpdateSpfVerificationMutation(verification_key: string) {
  const { t } = useTranslation();
  const toast = useToast();

  return useMutation<Results<VerificationData>, any, UplodedFiles>((uploadedFiles) => store(verification_key, uploadedFiles), {
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error.response?.data?.data || error.message || error),
        t,
      });
    },
  });
}
