import { Global } from '@emotion/react';
import Soehne from 'assets/fonts/soehne/soehne-buch.woff2';
import SoehneBold from 'assets/fonts/soehne/soehne-halbfett.woff2';
import SoehneBreit from 'assets/fonts/soehne-breit/soehne-breit-buch.woff2';
import SoehneBreitBold from 'assets/fonts/soehne-breit/soehne-breit-halbfett.woff2';
import React from 'react';

export enum FONT_WEIGHTS {
  light = 200,
  normal = 400,
  bold = 700,
}

export enum FONTS {
  soehne = 'Soehne',
  soehneBreit = 'Soehne Breit',
}

const Fonts = () => (
  <Global
    styles={`
      /* Soehne */
      @font-face {
        font-family: ${FONTS.soehne};
        font-style: normal;
        font-weight: ${FONT_WEIGHTS.normal};
        font-display: swap;
        src: url(${Soehne}) format('woff2');
      }
      
       @font-face {
        font-family: ${FONTS.soehne};
        font-style: normal;
        font-weight: ${FONT_WEIGHTS.bold};
        font-display: swap;
        src: url(${SoehneBold}) format('woff2');
      }
      
      /* SoehneBreit */
      @font-face {
        font-family: ${FONTS.soehneBreit};
        font-style: normal;
        font-weight: ${FONT_WEIGHTS.normal};
        font-display: swap;
         src: url(${SoehneBreit}) format('woff2');
      }
      
      @font-face {
        font-family: ${FONTS.soehneBreit};
        font-style: normal;
        font-weight: ${FONT_WEIGHTS.bold};
        font-display: swap;
         src: url(${SoehneBreitBold}) format('woff2');
      }
      `}
  />
);

export default Fonts;
