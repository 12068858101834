import {
  Button,
  Grid,
  HStack,
  Icon,
  Square,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import Dropzone, { IInputProps, ILayoutProps } from 'react-dropzone-uploader';
import { useTranslation } from 'react-i18next';
import { AiOutlineFileText, IoTrash } from 'react-icons/all';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { FONT_WEIGHTS } from '../global/Fonts';

const Preview = (props: { meta: { [name: string]: any } }) => {
  const {
    meta: { name },
  } = props;

  return (
    <Stack direction={'row'} color="blue.500" spacing={2} alignItems={'center'}>
      <Icon as={AiOutlineFileText} boxSize="5" />
      <Text as="span" fontSize="md" fontWeight={FONT_WEIGHTS.bold}>
        {name}
      </Text>
    </Stack>
  );
};

const Input = ({
  accept,
  onFiles,
  getFilesFromEvent,
  extra,
  className,
  disabled,
}: IInputProps) => {
  const { t } = useTranslation();
  const { active } = extra;
  return (
    <Grid placeContent={'center'} gap="3" w={'full'} h={'full'} position={'relative'}>
      <Square
        size="10"
        bg={active ? 'blue.500' : 'bg-subtle'}
        borderRadius="lg"
        m={'auto'}
      >
        <Icon
          as={active ? FiChevronDown : FiChevronUp}
          boxSize="5"
          color={active ? 'white' : 'muted'}
        />
      </Square>
      <VStack spacing="1">
        <HStack spacing="1" whiteSpace="nowrap">
          <Button
            variant="link"
            color="blue.500"
            disabled={disabled}
            size="sm"
            position={'static'}
          >
            {/* We need the label component for the file upload to be triggered by the package*/}
            <label style={{ cursor: 'pointer' }}>
              {active
                ? t('components:dropzone.drop')
                : t('components:dropzone.click_to_upload')}
              <input
                style={{
                  opacity: 0,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                  cursor: 'pointer',
                }}
                className={className}
                type="file"
                disabled={disabled}
                accept={accept}
                onChange={async (e) => {
                  const chosenFiles = await getFilesFromEvent(e);
                  onFiles(chosenFiles as File[]);
                }}
              />
            </label>
          </Button>
          <Text fontSize="sm" color="muted" display={active ? 'none' : 'block'}>
            {t('components:dropzone.drag_drop')}
          </Text>
        </HStack>
        <Text fontSize={'sm'}> {t('components:dropzone.file_validation_hint')}</Text>
      </VStack>
    </Grid>
  );
};

const Layout = ({
  input,
  previews,
  dropzoneProps,
  files,
  extra: { maxFiles, active, reject },
}: ILayoutProps) => {
  const { t } = useTranslation();
  let borderColor = 'gray.200';
  let borderStyle = 'solid';

  if (reject) {
    borderColor = 'red.200';
  }

  if (active) {
    borderColor = 'blue.500';
    borderStyle = 'dashed';
  }

  const hasFiles = files.length > 0;

  if (hasFiles && !active) {
    borderColor = 'blue.500';
  }
  return (
    <Grid
      borderWidth={1}
      borderStyle={borderStyle}
      borderRadius="lg"
      borderColor={borderColor}
      minH={40}
      bg={active ? 'blue.100' : 'white'}
      transition={'all .2s ease-in'}
    >
      <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
      {hasFiles ? (
        <Stack alignItems={'center'} spacing={4}>
          {previews}

          <Button
            data-test-id={'dropzone-remove-file'}
            size={'sm'}
            variant={'link'}
            colorScheme={'red'}
            onClick={() => {
              //we do not remove each file individually because the dropzone does not refresh between events
              files.forEach((file) => file.remove());
            }}
            aria-label={'remove file'}
            leftIcon={<Icon as={IoTrash} boxSize="5" />}
          >
            {t('common:remove')}
          </Button>
        </Stack>
      ) : null}
    </Grid>
  );
};

//fix for rollup https://github.com/vitejs/vite/issues/2139
//@ts-ignore
const DropzoneComponent = Dropzone.default ? Dropzone.default : Dropzone;
export const DropzoneInput = (props: {
  // eslint-disable-next-line no-unused-vars
  onChange: (arg0: any) => void;
  maxFiles?: number;
  identifier: string;
  disabled?: boolean;
}) => {
  const { maxFiles = 1, disabled = false, onChange } = props;
  const handleChangeStatus = (file: object, status: string, allFiles: any) => {
    switch (status) {
      case 'done':
        if (maxFiles > 1 && allFiles.length > 1) {
          onChange(allFiles);
        } else {
          onChange(file);
        }
        break;
      case 'removed':
        onChange(undefined);
    }
  };
  return (
    <DropzoneComponent
      LayoutComponent={Layout}
      PreviewComponent={Preview}
      maxFiles={maxFiles}
      multiple={maxFiles > 1}
      onChangeStatus={handleChangeStatus}
      InputComponent={Input}
      submitButtonDisabled
      disabled={disabled}
      accept="image/png,image/jpg,image/jpeg,application/pdf"
      maxSizeBytes={1024 * 1024 * 10} //1024 * 1024 is 1MB
      addClassNames={{ input: props.identifier }}
    />
  );
};
