import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import VehicleUpdateModal from 'features/dashboard/VehicleUpdateModal';
import { displayRow } from 'helpers/general';
import useVehicleQuery from 'hooks/queries/useVehicleQuery';
import useVehicleModelsQuery from 'hooks/queries/workshop/useVehicleModelsQuery';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate } from 'react-router-dom';

import HoverableBox from './HoverableBox';

export const VehicleInfoBanner = ({ vin }: { vin: string }) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isRegistrationDateMissing, setIsRegistrationDateMissing] = useState(false);
  const [isFromIdentificationPage, setIsFromIdentificationPage] = useState(false);

  const formatDate = (dateString: string): string => {
    const parsedDate = parseISO(dateString);
    return format(parsedDate, 'dd.MM.yyyy');
  };
  const vehicleQuery = useVehicleQuery(vin);

  const hasInitialRegistrationDate = vehicleQuery.data?.data?.initial_registration_date;
  const formattedDate = hasInitialRegistrationDate ? formatDate(hasInitialRegistrationDate) : '';

  useEffect(() => {
    // @ts-ignore
    if (state?.fromIdentification) {
      setIsFromIdentificationPage(true);
      window.history.replaceState({}, '');
    }
  }, []);
  const isVehicleDetailsPage = location.pathname.includes('/vehicle');

  const vehicleData = vehicleQuery.data?.data;
  const queriesLoading = vehicleQuery.isLoading;

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (vehicleData?.initial_registration_date === null || vehicleData?.initial_registration_date === '') {
      setIsRegistrationDateMissing(true);
    }

    if (isRegistrationDateMissing || isOpen) {
      onClose();
    }
  }, [vehicleQuery.isSuccess]);

  return (
    <Box as="section" bg={'white'} my={-5}>
      <Container py={{ base: '4', md: '2.5' }} position="relative" maxW="unset">
        {queriesLoading ? (
          <Skeleton height="120px" width="150%" style={{ marginLeft: -50, marginRight: -50 }} />
        ) : (
          <Stack
            flexDirection={{ base: 'column', sm: 'row' }}
            justify="center"
            spacing={{ base: '3', md: '2' }}
            justifyContent={{ base: 'center', md: 'space-between' }}
            alignItems={'center'}
          >
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              justify="center"
              spacing={{ base: '3', md: '2' }}
              justifyContent={{ base: 'center', md: 'flex-start' }}
            >
              <Flex justifyContent="center" alignItems="center">
                <Image
                  pb={3}
                  mt={3}
                  mb={1}
                  mr={1}
                  height={100}
                  objectFit="contain"
                  src={vehicleData?.vehicle_type?.haynespro_image || '/images/no-car-image.jpg'}
                  alt={vehicleData?.vehicle_type?.haynespro_image ? 'car image' : 'car image default'}
                />
              </Flex>
              <HStack>
                <VStack justifyContent={'center'} alignItems={'flex-start'} pl={0}>
                  <HStack flexWrap={'wrap'}>
                    <Box mr={3}>
                      {displayRow({
                        label: t('forms:vin.label'),
                        content: vin,
                        customClass: 'pii',
                        fontSize: 'md',
                      })}
                    </Box>
                    {hasInitialRegistrationDate && (
                      <HoverableBox onOpen={onOpen} style={{ marginLeft: 20 }}>
                        {displayRow({
                          label: t('forms:vehicle_info.initial_registration_date'),
                          content: formattedDate,
                          customClass: 'pii',
                          fontSize: 'md',
                        })}
                      </HoverableBox>
                    )}

                    {!hasInitialRegistrationDate &&
                      displayRow({
                        label: t('forms:vehicle_info.initial_registration_date'),
                        content: (
                          <Button size="xs" onClick={onOpen} variant={'outline'}>
                            {t('forms:vehicle_info.specify_information')}
                          </Button>
                        ),
                        customClass: 'pii',
                        fontSize: 'md',
                      })}
                  </HStack>

                  <HStack wrap={'wrap'} justifyContent={'flex-start'}>
                    {vehicleData?.vehicle_type && (
                      <HoverableBox onOpen={onOpen}>
                        {displayRow({
                          fontSize: 'md',
                          label: t('forms:vehicle_info.model'),
                          content: (
                            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                              {vehicleData?.vehicle_type?.displayname}
                            </Text>
                          ),
                          isLoading: vehicleQuery.isLoading,
                        })}
                      </HoverableBox>
                    )}
                    {vehicleData?.vehicle_type && (
                      <HoverableBox onOpen={onOpen}>
                        {displayRow({
                          label: t('forms:vehicle_info.type'),
                          content: (
                            <Text
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >{`${vehicleData?.vehicle_type?.model} - ${vehicleData?.vehicle_type?.engine_code} / ${vehicleData?.vehicle_type?.output} kW`}</Text>
                          ),
                          fontSize: 'md',
                          isLoading: vehicleQuery.isLoading,
                        })}
                      </HoverableBox>
                    )}
                    {vehicleData?.vehicle_type &&
                      displayRow({
                        label: t('forms:vehicle_info.fuel'),
                        content: t(`forms:vehicle_info.fuel_type.${vehicleData.vehicle_type?.fuel_type}`),
                        fontSize: 'md',
                        isLoading: vehicleQuery.isLoading,
                      })}
                    <Box mr={2}>
                      {!vehicleData?.vehicle_type &&
                        displayRow({
                          fontSize: 'md',
                          label: t('forms:vehicle_info.model'),
                          content: (
                            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                              <Button size="xs" onClick={onOpen} variant={'outline'} ml={2}>
                                {t('forms:vehicle_info.specify_information')}
                              </Button>
                            </Text>
                          ),
                          isLoading: vehicleQuery.isLoading,
                        })}
                    </Box>

                    <Box style={{ marginRight: 10 }}>
                      {!vehicleData?.vehicle_type &&
                        displayRow({
                          label: t('forms:vehicle_info.type'),
                          content: (
                            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                              {t('common:unknown')}
                            </Text>
                          ),
                          fontSize: 'md',
                          isLoading: vehicleQuery.isLoading,
                        })}
                    </Box>
                    <Box style={{ marginRight: 10 }}>
                      {!vehicleData?.vehicle_type &&
                        displayRow({
                          label: t('forms:vehicle_info.fuel'),
                          content: t('common:unknown'),
                          fontSize: 'md',
                          isLoading: vehicleQuery.isLoading,
                        })}
                    </Box>
                  </HStack>
                </VStack>
              </HStack>
            </Stack>
            {!isVehicleDetailsPage && (
              <Button
                p={2}
                mr={5}
                style={{ marginTop: '-1.5px' }}
                onClick={() => navigate(`/app/w/vehicle?vin=${vin}`)}
                variant={'outline'}
                flexShrink={0}
              >
                {t('forms:vehicle_info.button_back')}
              </Button>
            )}
          </Stack>
        )}
      </Container>

      <Modal
        closeOnOverlayClick={false}
        isOpen={(isFromIdentificationPage && isRegistrationDateMissing) || isOpen}
        onClose={() => {
          setIsFromIdentificationPage(false);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW="fit-content">
          <ModalCloseButton mt={-3} />
          <ModalBody>
            <VehicleUpdateModal isModal onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
