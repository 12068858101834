import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import config from '../../../config';
import { useAxios } from '../../../context/AxiosContextProvider';

async function sendFeedback(axios: AxiosInstance, data: any): Promise<object> {
  return await axios.post(`${config.apiBaseUrl}/feedback`, data);
}

export default function useFeedbackMutation() {
  const axios = useAxios();
  const t = useTranslation();
  const toast = useToast();
  return useMutation((data: any) => sendFeedback(axios, data), {
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error,
        t,
      });
    },
  });
}
