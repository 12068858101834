import * as React from 'react';
import { Trans } from 'react-i18next';

import usePublicConfigQuery from '../../hooks/public/queries/usePublicConfigQuery';

export function CheckBoxDescription(props: {
  country: string;
  selectedLanguage: string;
  translationKey: string;
}) {
  const { country, selectedLanguage, translationKey } = props;
  const publicConfigQuery = usePublicConfigQuery(
    selectedLanguage,
    country === 'XI' ? 'GB' : country,
  );
  const localizedConfig = publicConfigQuery?.data?.data || {};
  const dataPrivacyUrl = localizedConfig?.data_privacy_url;
  const tacUrl = localizedConfig?.terms_and_conditions_url;
  return (
    <>
      <Trans
        i18nKey={translationKey}
        components={{
          a: (
            <a
              href={`${
                translationKey.includes('dataprivacy') ? `${dataPrivacyUrl}` : `${tacUrl}`
              }`}
              style={{ color: '#2596be', textDecoration: 'underline' }}
              target="_blank"
              rel="noreferrer"
            />
          ),
        }}
      />
    </>
  );
}
export default CheckBoxDescription;
