import { Heading, Stack } from '@chakra-ui/react';
import { get } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useUserContext } from '../../context/UserContextProvider';
import { displayRow } from '../../helpers/general';

const WorkshopInformation = () => {
  const userContext = useUserContext();
  const { t } = useTranslation();

  return (
    <Stack w={'100%'} data-test-id="workshop-information">
      <Stack w="full" spacing={2}>
        <Heading size={'xs'} marginBlockEnd={4}>
          {t('workshop:overview.title')}
        </Heading>
        <Stack spacing={2} pl={{ base: 0, lg: 4 }}>
          {displayRow({
            label: t('forms:firstname.label'),
            fontSize: 'md',
            content: get(userContext.workshop, 'firstname'),
            customClass: 'pii',
          })}
          {displayRow({
            label: t('forms:lastname.label'),
            fontSize: 'md',
            content: get(userContext.workshop, 'lastname'),
            customClass: 'pii',
          })}
          {displayRow({
            label: t('forms:email.label'),
            fontSize: 'md',
            content: get(userContext.workshop, 'email'),
            customClass: 'pii',
          })}
          {displayRow({
            label: t('forms:telephone.label'),
            fontSize: 'md',
            content: get(userContext.workshop, 'telephone'),
            customClass: 'pii',
          })}
          {displayRow({
            label: t('forms:name.label'),
            fontSize: 'md',
            content: get(userContext.workshop, 'name'),
            customClass: 'pii',
          })}
          {displayRow({
            label: t('forms:vat_number.label'),
            fontSize: 'md',
            content: get(userContext.workshop, 'vat_number'),
            customClass: 'pii',
          })}
          {displayRow({
            label: t('forms:language.label'),
            fontSize: 'md',
            content: t(
              `location:languages.${get(
                userContext.workshop,
                'language',
                '-',
              ).toUpperCase()}`,
            ),
            customClass: 'pii',
          })}
          {displayRow({
            label: t('forms:country.label'),
            fontSize: 'md',
            content: t(
              `location:countries.${get(
                userContext.workshop,
                'country',
                '-',
              ).toUpperCase()}`,
            ),
            customClass: 'pii',
          })}
          {displayRow({
            label: t('forms:city.label'),
            fontSize: 'md',
            content: get(userContext.workshop, 'city'),
            customClass: 'pii',
          })}

          {displayRow({
            label: t('forms:zipcode.label'),
            fontSize: 'md',
            content: get(userContext.workshop, 'zipcode', '-'),
            customClass: 'pii',
          })}

          {displayRow({
            label: t('forms:address.label'),
            fontSize: 'md',
            content: get(userContext.workshop, 'address', '-'),
            customClass: 'pii',
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default WorkshopInformation;
