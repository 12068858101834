import { isNil } from 'lodash';

import { PartialStateCode } from '../api/types';

export default function transformer(data: any, schema: string[]) {
  let result: PartialStateCode<string> = {};
  if (isNil(data)) {
    return result;
  }
  const existingKeys = Object.keys(data);

  schema.forEach((key: string) => {
    const value = data[key];
    if (existingKeys.includes(key) && value) {
      result[key] = value;
    }
  });

  return result;
}
