import {
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
} from '@chakra-ui/react';
import React, { SetStateAction, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useAsyncDebounce } from 'react-table';

// https://www.bacancytechnology.com/blog/react-table-tutorial

// Component for Global Filter
export function GlobalFilter(props: {
  globalFilter: any;
  setGlobalFilter(x: any): void;
  globalFilterPlaceholder?: string;
}) {
  const { globalFilter, setGlobalFilter, globalFilterPlaceholder } = props;
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 1000);
  return (
    <HStack justifyContent="space-between" w={'full'}>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={FiSearch} color="muted" boxSize="5" />
        </InputLeftElement>
        <Input
          value={value || ''}
          data-test-id="search-table"
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={globalFilterPlaceholder}
        />
      </InputGroup>
    </HStack>
  );
}

// Component for Default Column Filter
export function DefaultFilterForColumn(props: {
  column: { filterValue: string; setFilter: SetStateAction<any> };
}) {
  const {
    column: { filterValue, setFilter },
  } = props;
  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => {
        // Set undefined to remove the filter entirely
        setFilter(e.target.value || undefined);
      }}
      style={{ marginTop: '10px' }}
    />
  );
}

export type DropdownOption = {
  value: any;
  label: string;
};

export type DropdownOptionGrouped = {
  options: DropdownOption[];
  label: string;
};

// Component for Custom Select Filter
export function SelectColumnFilter(props: {
  filterValue?: string;
  column: { setFilter: SetStateAction<any>; id: string; Header: string };
  dropdownOptions: Array<DropdownOption>;
  onChange: SetStateAction<any>;
}) {
  const {
    filterValue,
    column: { setFilter, id, Header },
    dropdownOptions,
    onChange,
  } = props;
  const selectId = `filter-${id}-dropdown`;
  return (
    <Stack direction={'column'}>
      <FormLabel size="lg" htmlFor={selectId} pl="1" mb={0}>
        {Header}
      </FormLabel>
      <Select
        defaultValue={''}
        aria-label={'filter'}
        value={filterValue}
        id={selectId}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
          onChange(e.target.value || undefined);
        }}
      >
        {dropdownOptions?.map((option: DropdownOption, index: number) => {
          return (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </Select>
    </Stack>
  );
}
