import { useIsWorkshopBlocked } from 'helpers/general';
import PageMetatags from 'layout/PageMetatags';
import { isNil } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { LoadingSpinner } from '../../components/LoadingSpinner';
import useServiceRecordQuery from '../../hooks/queries/service/useServiceRecordQuery';
import useVehicleQuery from '../../hooks/queries/useVehicleQuery';
import ServiceRecordForm from './ServiceRecordForm';

const UpdateServiceRecordPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  let { id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const vin = urlParams.get('vin') || '';
  const serviceRecordId = Number(id);
  const isWorkshopBlocked = useIsWorkshopBlocked();
  const serviceRecordQuery = useServiceRecordQuery(serviceRecordId);
  const serviceRecordData = serviceRecordQuery.data?.data;
  // @ts-ignore

  const vehicleQuery = useVehicleQuery(vin);
  const vehicleData = vehicleQuery.data?.data;

  const navigate = useNavigate();
  if (isNaN(serviceRecordId)) {
    navigate('/404', { replace: true });
    return null;
  }

  const initialValues = {
    ...serviceRecordData,
    vehicle_id: serviceRecordData?.vehicle?.id,
    vin: serviceRecordData?.vehicle?.vin,
    max_age_in_days: vehicleData?.max_age_in_days,
    // data: Array.isArray(serviceRecordData?.data) ? {} : serviceRecordData?.data,
    data: serviceRecordData?.data || {},
  };

  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (serviceRecordQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <PageMetatags
        title={`${t('pages:service_record.form.update')} ${t('common:nr')}: ${id}'`}
      />
      <ServiceRecordForm
        title={t('pages:service_record.form.update')}
        isLoadingParent={isNil(serviceRecordQuery.data) || serviceRecordQuery.isFetching}
        initialValues={{ ...initialValues }}
        apiEndpointUrl={'/workshop/service-records/' + id}
        formVersionId={vehicleData?.form_version?.id as number}
        isUpdate
        // @ts-ignore
        // vehicle={serviceRecordData?.vehicle}
        vehicle={vehicleData}
        navigateUrl={'/app/w/vehicle?vin='}
      />
    </>
  );
};

export default UpdateServiceRecordPage;
