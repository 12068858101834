import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AlertModal from './AlertModal';

export const getDisableCostControlData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return {
    modalComponent: AlertModal,
    onClose: () => navigate(-1),
    onSubmit: noop,
    content: {
      header: t('addons:unavailable.header'),
      footer: { buttons: { hide: true } },
    },
    children: t('addons:unavailable.text'),
  };
};

export default getDisableCostControlData;
