import { useIsWorkshopBlocked } from 'helpers/general';
import PageMetatags from 'layout/PageMetatags';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { LoadingSpinner } from '../../components/LoadingSpinner';
import { useStep } from '../../helpers/useStep';
import useVehicleQuery from '../../hooks/queries/useVehicleQuery';
import { Stepper } from '../serviceRecord/Stepper';
import DownloadStep from './DownloadStep';
import SelectInspectionStep from './SelectInspectionStep';

const InspectionPlanPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [currentStep, { setStep }] = useStep({ maxStep: 1, initialStep: 0 });
  const [inspectionPlandId, setInspectionPlandId] = useState<null | number>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const vin = urlParams.get('vin') || '';
  const vehicleQuery = useVehicleQuery(vin);

  type Step = {
    title: number;
    stepHeader: string;
    stepDescription: string;
    content: JSX.Element;
  };

  const steps: Step[] = [
    {
      title: 1,
      stepHeader: t('forms:ip.step_two.header'),
      stepDescription: t('forms:ip.step_two.description'),
      content: (
        <SelectInspectionStep
          setStep={setStep}
          setInspectionPlandId={setInspectionPlandId}
          // @ts-ignore
          initialRegistrationDate={vehicleQuery?.data?.data?.initial_registration_date}
        />
      ),
    },
    {
      title: 2,
      stepHeader: t('forms:ip.download.header'),
      stepDescription: t('forms:ip.download.step_description'),

      content: <DownloadStep inspectionPlandId={inspectionPlandId} />,
    },
  ];
  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (vehicleQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <PageMetatags title={t('forms:ip.label')} />
      <Stepper
        isLoading={vehicleQuery.isFetching}
        currentStep={currentStep}
        content={steps[currentStep].content}
      />
    </>
  );
};

export default InspectionPlanPage;
