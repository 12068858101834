import { upperCase } from 'lodash';

import { PartialStateCode } from '../api/types';
import { DropdownOption } from '../components/Table/Filter';

const standardVatFormatting = (val: string) => upperCase(val).replace(/ /g, '');

export const SupportedCountriesVatFormatting = {
  AT: standardVatFormatting,
  IT: standardVatFormatting,
  CH: (val: string) => val, // upperCase(val).replace(/[^\w\s.]|_/g, ''),
  BE: standardVatFormatting,
  LU: standardVatFormatting,
  SE: standardVatFormatting,
  DK: standardVatFormatting,
  NO: standardVatFormatting,
  FI: standardVatFormatting,
};

export const formatTelephone = (phone: string, telePrefix: string | undefined) => {
  let trimmedNr = phone.replace(/ /g, '').replace(/[a-zA-Z]+/g, '');
  const telePrefixZero = '00' + telePrefix?.slice(1);

  if (telePrefix !== undefined && trimmedNr.includes(telePrefix)) {
    trimmedNr = trimmedNr.replace(telePrefix, '');
  }
  if (telePrefix !== undefined && trimmedNr.includes(telePrefixZero)) {
    trimmedNr = trimmedNr.replace(telePrefixZero, '');
  }
  if (trimmedNr.includes('+')) {
    trimmedNr = trimmedNr.replace('+', '');
  }
  return trimmedNr;
};

export const telephonePrefixesOptions = (
  telephonePrefixes: PartialStateCode<string>,
  t: any,
) => {
  let options: Array<DropdownOption> = [];
  Object.keys(telephonePrefixes).map((prefix) => {
    options.push({ label: telephonePrefixes[prefix], value: telephonePrefixes[prefix] });
  });
  return options;
};
