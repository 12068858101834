import { theme } from '@chakra-ui/pro-theme';
import { extendTheme } from '@chakra-ui/react';
import Color from 'color';
import fonts from 'foundations/fonts';

import components from './components';
import tokens from './tokens';

/**
 * For Use, where chakra vars are not available and base reference.
 * Add all Colors here and then use this const in theme config
 */
export const brandColors = {
  blue: {
    light: Color('#3F7DC1').mix(Color('white'), 0.4).hex(),
    50: Color('#3F7DC1').mix(Color('white'), 0.95).hex(),
    100: Color('#3F7DC1').mix(Color('white'), 0.9).hex(),
    200: Color('#3F7DC1').mix(Color('white'), 0.8).hex(),
    300: Color('#3F7DC1').mix(Color('white'), 0.7).hex(),
    400: Color('#3F7DC1').mix(Color('white'), 0.6).hex(),
    500: Color('#3F7DC1').hex(),
    600: Color('#3F7DC1').mix(Color('white'), 0.4).hex(),
    700: Color('#3F7DC1').mix(Color('white'), 0.3).hex(),
    800: Color('#3F7DC1').mix(Color('white'), 0.2).hex(),
    900: Color('#3F7DC1').mix(Color('white'), 0.1).hex(),
    default: '#3F7DC1',
  },
  gray: {
    darkest: Color('#F0F0F0').mix(Color('black'), 0.6).hex(),
    darker: Color('#F0F0F0').mix(Color('black'), 0.4).hex(),
    dark: Color('#F0F0F0').mix(Color('black'), 0.1).hex(),
    dimmed: '#E0E0E0',
    default: '#F0F0F0',
  },

  red: {
    default: '#E53E3E',
  },

  orange: {
    default: '#E95C0E',
  },

  white: {
    default: '#FFFFFF',
  },
};

const breakpoints = {
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '75em', // custom (1200px)
  xl: '80em', // 1280px
  '2xl': '96em', // 1536px
};

export const myTheme: Record<string, any> = extendTheme(
  {
    semanticTokens: {
      ...tokens,
    },
    fonts: {
      ...fonts,
    },
    breakpoints,
    sizes: {
      ...theme.sizes,
      container: {
        ...theme.sizes.container,
        xxl: '1920px',
      },
    },

    zIndices: {
      ...theme.zIndices,
      // modal: 1900,
    },
    colors: {
      ...theme.colors,

      black: {
        '50': Color('#000000').mix(Color('white'), 0.95).hex(),
        '100': Color('#000000').mix(Color('white'), 0.9).hex(),
        '200': Color('#000000').mix(Color('white'), 0.8).hex(),
        '300': Color('#000000').mix(Color('white'), 0.7).hex(),
        '400': Color('#000000').mix(Color('white'), 0.6).hex(),
        '500': Color('#000000').mix(Color('white'), 0.5).hex(),
        '600': Color('#000000').mix(Color('white'), 0.4).hex(),
        '700': Color('#000000').mix(Color('white'), 0.3).hex(),
        '800': Color('#000000').mix(Color('white'), 0.2).hex(),
        '900': Color('#000000').mix(Color('white'), 0.1).hex(),
      },

      blue: {
        ...brandColors.blue,
      },
      brand: {
        500: brandColors.blue.default,
        blue: {
          ...theme.colors.blue,
          ...brandColors.blue,

          500: brandColors.blue.default,
          700: brandColors.blue.default,
          dark: Color(brandColors.blue.default).mix(Color('black'), 0.7).hex(),
        },

        gray: {
          ...theme.colors.gray,
          ...brandColors.gray,
        },

        red: {
          ...theme.colors.red,
          ...brandColors.red,
        },

        orange: {
          ...brandColors.orange,
        },
      },

      error: {
        500: brandColors.red.default,
      },

      status: {
        error: brandColors.red.default,
        disabled: brandColors.gray.dark,
      },
    },

    components: { ...components(theme) },
  },

  theme,
);
