import { Button, Center, Grid, Image, Stack, Text, VStack } from '@chakra-ui/react';
import config from 'config';
import { useAxios } from 'context/AxiosContextProvider';
import { downloadFileFromUrl } from 'helpers/downloadFiles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MdDownload, MdOutlineCreate } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const ActionButton = ({
  label,
  icon,
  onClick,
  dataTestId,
  ...rest
}: {
  label: string;
  icon: React.ReactElement;
  onClick: () => void;
  dataTestId: string;
  [key: string]: any;
}) => (
  <Button
    minW={60}
    data-test-id={dataTestId}
    leftIcon={icon}
    colorScheme="blue"
    variant="solid"
    size="lg"
    onClick={onClick}
    justifyContent="center"
    alignItems="center"
    {...rest}
  >
    {label}
  </Button>
);

const DownloadStep = ({ inspectionPlandId }: { inspectionPlandId: number | null }) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const vin = urlParams.get('vin') || '';

  return (
    <Grid w="full" h="full" placeItems="center" p={4} gap={4}>
      <VStack>
        <Stack p={4}>
          <Center>
            <Image src="/images/alex-thumbs-up.svg" alt="Error Gif" w="70%" h="auto" />
          </Center>
          <Text>{t('forms:ip.download.description')}</Text>
        </Stack>
        <Stack direction={{ base: 'column', md: 'row' }}>
          <ActionButton
            label={t('common:download')}
            icon={<MdDownload size={25} style={{ marginRight: 15 }} />}
            onClick={() =>
              downloadFileFromUrl(
                axios,
                `${config.apiBaseUrl}/workshop/vehicles/${vin}/inspection-plans/${inspectionPlandId}/blank-download`,
                'Inspection Plan.pdf',
              )
            }
            dataTestId="download-inspection-plan"
            variant="outline"
            mr={5}
          />
          <ActionButton
            label={t('forms:ip.download.fillout')}
            icon={<MdOutlineCreate size={30} style={{ marginRight: 15 }} />}
            onClick={() => navigate(`/app/w/inspection-plan/${inspectionPlandId}?vin=${vin}`)}
            dataTestId="fillout-inspection-plan"
          />
        </Stack>
      </VStack>
    </Grid>
  );
};

export default DownloadStep;
