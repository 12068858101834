import { HStack, Text } from '@chakra-ui/react';
import React from 'react';

import config from '../config';
import { dateTimeTransformer } from '../helpers/dateTransformer';

export const VersionHint: React.FC = () => {
  return (
    <HStack>
      {config.buildTag && (
        <Text>
          <b>Tag:</b> {config.buildTag}
        </Text>
      )}
      {config.buildCommit && (
        <Text>
          <b>Commit:</b> {config.buildCommit}
        </Text>
      )}
      {config.buildBranch && (
        <Text>
          <b>Branch:</b> {config.buildBranch}
        </Text>
      )}
      <Text>
        <b>Time:</b>{' '}
        {config.buildTime !== '-' ? dateTimeTransformer(config.buildTime) : '-'}
      </Text>
    </HStack>
  );
};

export default VersionHint;
