import { BundleISP, OEDocu, OEPlusDSB, QRConnect } from '../components/illustrations';
import { InspectionPlan } from '../features/inspectionPlan/SelectInspectionStep';
import { Subscription, WorkshopData } from '../hooks/queries/workshop/useWorkshopQuery';

export enum BILLING_CYCLE {
  YEARLY = 'year',
  MONTHLY = 'month',
}

export const enum CHARGE_KEYS {
  UPDATE_WORKSHOP_DATA = 'update_workshop_data',
  SERVICE_HISTORY_REQUEST = 'service_history_request',
  SERVICE_RECORD = 'service_record',
  UPDATE_SERVICE_RECORD = 'update_service_record',
  DELETE_SERVICE_RECORD = 'delete_service_record',
  FASTLANE = 'fastlane',
  SETUP_FEE = 'setup_fee',
}

export const enum ADDON_KEYS {
  INSPECTION_PLANS_ADDON = 'inspection_plans',
  PLUS_DSB = 'oe_plus_dsb',
  QR_CONNECT = 'qr_connect',
  OE_DOCS = 'oe_docs',
}

export const ADDON_ICONS = {
  [ADDON_KEYS.INSPECTION_PLANS_ADDON]: BundleISP,
  [ADDON_KEYS.OE_DOCS]: OEDocu,
  [ADDON_KEYS.QR_CONNECT]: QRConnect,
  [ADDON_KEYS.PLUS_DSB]: OEPlusDSB,
};

export enum STATUS {
  CREATED = 'created',
  SUBMITTED = 'submitted',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  CLARIFICATION = 'clarification',
  CANCELED = 'canceled',
  DELETED = 'deleted',
  REQUESTED_DELETION = 'requested_deletion',
}

export interface Charge {
  id: string | CHARGE_KEYS;
  price: number;
}

export interface ISignupForm {
  firstname: string;
  lastname: string;
  role: string|null;
  email: string;
  telephone: string;
  name: string;
  vat_number: string | undefined | null;
  country: string;
  language: string;
  city: string;
  zipcode: string;
  address: string;
  consent_termsandconditions: any;
  consent_dataprivacy: any;
  consent_newsletter: any;
  telephone_prefix?: string;
  distributor_id: number | null;
  business_type?: string | null;
  registration_form_key?: string | null;
}

export interface SubscriptionAddon {
  id: string;
  name: string;
  trial: boolean;
  trial_available: boolean;
  trial_ends?: string;
  description: string;
  price: {
    [BILLING_CYCLE.MONTHLY]: Price;
    [BILLING_CYCLE.YEARLY]: Price;
  };
}

export interface ActivatedAddon {
  trial_available: boolean;
  trial_ends?: string;
}

export interface Discount {
  percentage?: number;
  fixed_amount: number;
  amount: number;
  period: number;
  period_unit: string;
  type: string;
}

export interface Price {
  amount: number;
  discount?: Discount;
}
export interface SubscriptionPlan {
  id: number;
  slug: string;
  charges?: { [key: string]: Charge };
  is_pay_per_use: number;
  monthly_shr_quota: number;
  monthly_sr_quota: number;
  name: string;
  order: number;
  pre_selected: boolean;
  yearly_savings: number;
  price: {
    [BILLING_CYCLE.MONTHLY]: Price;
    [BILLING_CYCLE.YEARLY]: Price;
  };
}

export type Vehicle = {
  id: number;
  vin:string;
  make:string;
  vehicle_type:string;
};

export interface Promotion {
  name: string;
  slug: string;
  country: string;
  text: string;
  start: string;
  end: string;
  location: [string];
}

export interface StatusCodes {
  workshop: PartialStateCode<number>;
  workshop_verification_request: PartialStateCode<number>;
  workshop_verification_request_reason: PartialStateCode<number>;
  workshop_update_request: PartialStateCode<number>;
  workshop_update_request_reasons: PartialStateCode<number>;
  service_history_request: PartialStateCode<number>;
  service_history_request_reasons: PartialStateCode<number>;
  service_record: PartialStateCode<number>;
  service_record_reasons: PartialStateCode<number>;
}

export type PartialStateCode<T> = {
  // @ts-ignore
  [key in T]: any;
};

export type VehicleType = {
  data: { [key: string]: any };
  current_page: number;
  id: string;
  squish_vin: string;
  model: string;
  fullname: string;
  made_from: string;
  made_until: string;
  engine_code: string;
  capacity: number;
  output: number;
  fuel_type: string;
  haynespro_car_type_id: number;
  haynespro_image: string;
};

export type VehicleModel = {
  hp_id: number;
  name: string;
  fullname: string;
  engine_code: string | number | null;
  output: string | null;
};

export type ServiceActivity = {
  inspection_plan_id: number;
  id: string;
  uuid: string;
  type: string;
  date: string;
  vehicle_id: number;
  workshop_id: number;
  service_record_id: number;
  service_history_request_id: number;
  service: Service;
  inspection_plan: InspectionPlan;
  service_record: ServiceRecord;
  service_history_request: ServiceHistory;
  meta_data: {
    email?: string;
    documents?: Array<{
      id: number;
      name: string;
    }>;
  };
};

export type PaginatedArrayResults<T> = {
  meta: {
    next_page_url: string;
    last_page: number;
    current_page: number;
    from: number;
    links: Array<{
      url: string | null;
      label: string;
      active: boolean;
    }>;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
  data: Array<T>;
  status: number;
  message: string | null;
};

export type PaginatedResults<T> = Omit<PaginatedArrayResults<T>, 'data'> & {
  data: T;
};

export type ArrayResults<T> = Omit<PaginatedArrayResults<T>, 'meta'>;

export type Results<T> = Omit<ArrayResults<T>, 'data'> & {
  data: T;
};

export type DsbFormVersion = {
  id: number;
  dsb_form_id: number;
  version: string;
  published_at: string;
};
export type Manufacturer = {
  id: number;
  name: string;
  slug: string;
  wmi: string;
  region: string;
  make_id: number;
  created_at: string;
  updated_at: string;
  make: {
    id: number;
    name: string;
    slug: string;
    default_portal_id: number;
    default_dsb_form_id: number;
    haynes_pro_make_id: number;
    created_at: string;
    updated_at: string;
  };
};

export type ShowVehicle = {
  id: null | number;
  vin: string;
  vehicle_type_id: number | null;
  created_at: null | string;
  updated_at: null | string;
  manufacturer_id: number | null;
  initial_registration_date: string | null;
  max_age_in_days: null | number;
  hp_model_id: string | null;
  hp_type_id: string | null;
  form_version: null | string;
  features: {
    create_inspection_plan: string;
    create_service_record: string;
    fill_inspection_plan: string;
    request_service_history: string;
    send_documents: string;
  };
  manufacturer: Manufacturer;
  vehicle_type: {
    id: number;
    squish_vin: string;
    model: string;
    fuel_type: string;
    displayname: string;
    haynespro_image: string;
    haynespro_car_type_id: string;
    manufacturer_id: number;
    made_from: string;
    made_until: string;
    engine_code: string;
    capacity: string;
    output: string;
    fullname: string;
  };
  account: null | Account;
  presumable_hp_model_id: null | number;
  completed_services: Array<Service>;
  active_service: Service | null;
};

export type Accounts = {
  id: null | number;
  status: number;
  portal: string;
  portal_requires_vat: boolean;
  workshop_id: number;
  workshop_name: string;
  username: null | string;
  user_id: null | number;
  estimate: null | string;
  can_access_dsb: boolean;
  account_created_at: null | string;
  created_at: null | string;
  updated_at: null | string;
};

export interface Account {
  status: number;
  notify_when_ready: boolean;
  dsb_form_id: number;
  can_access_dsb: boolean;
  id: number;
  max_age_in_days: number;
  name: string;
  portal_id: number;
  slug: string;
}

export interface Announcement {
  translation_key: string;
  start: string;
  end: string;
  country: Array<string>;
}

export interface Addon {
  id: string;
  price: {
    month: {
      amount: number;
      discount: string | null;
      currency: string;
    };
    year: {
      amount: number;
      discount: string | null;
      currency: string;
    };
  };
  trial_available: boolean;
}

export type Country = {
  ip: string,
  country: string
}

export type Config = {
  countries: string[];
  languages: { [country: string]: string[] };
  telephone_prefixes: LooseObject;
  zipcode_length: LooseObject;
  terms_and_conditions_url: string;
  data_privacy_url: string;
  dpa_url: string;
  support_telephone: string;
  support_email: string;
};

export interface CostOverview {
  id: string;
  date: Date;
  total?: number;
}

export type FileObject = {
  name: string;
  path: string;
  uuid: string;
  disk: string;
  mime_type: string;
  extension: string;
  size: number;
  original_name: string;
};

export type GroupField = {
  icon: string;
  fields: Array<{ type: string }>;
};

export interface Group {
  general: GroupField;
  filter: GroupField;
  brake_system: GroupField;
  liquids: GroupField;
  oil_and_gearbox: GroupField;
  additional_work: GroupField;
}

export interface LooseObject {
  [key: string]: any;
}

export interface WorkshopContextInterface {
  workshop: WorkshopData;
  subscription?: Subscription;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  login: () => void;
  logout: () => Promise<void>;
  error?: Error | null;
  config: Config | LooseObject;
  token: string | undefined;
  statusCodes: StatusCodes;
  user: User | undefined;
}

export type Methods = 'head' | 'options' | 'put' | 'post' | 'patch' | 'delete' | 'get';

export type TourMetaData = {
  seen_starter_tour: boolean;
  seen_vehicle_general: boolean;
  seen_service_record: boolean;
  seen_service_history_request: boolean;
  seen_inspection_plan: boolean;
  seen_send_documents: boolean;
};
export type User = {
  id: number;
  auth0_id: string;
  firstname: string;
  lastname: string;
  email: string;
  type: string;
  workshop_id: number;
  show_welcome_message: boolean;
  created_at: any;
  updated_at: any;
  deleted_at: any;
  auth: object;
  timezone: string;
  tour_meta_data: TourMetaData | null;
};

export type Document = {
  id: number;
  created_at: string;
  updated_at: string;
  type: string;
  comment: string;
  service_id: number;
  service_record_id: number;
  service_history_request_id: number;
  workshop_verification_id: number;
  workshop_update_request_id: number;
};

export type Message = {
  text: string;
  created_at: string;
  author: string;
  user_id: number;
  workshop_user_id: number;
  document?: Document;
};

export type Conversation = {
  id: number;
  key: string;
  user_id: number;
  messages: Array<Message>;
};

export interface ServiceRecord {
  dsb_form_version_id: number;
  // service_activity: ServiceActivity;
  // max_age_in_days: number;
  // id: number;
  // date: string; //date
  // vin?: string;
  // uuid?: string;
  // form: string;
  // status: number;
  // mileage: number;
  // fastlane: boolean;
  // user_readable_id: string;
  // status_reason?: number;
  // partner_id?: number;
  // file_id?: number;
  // file: FileObject;
  // data: object & { invoice_id: number; inspection_plan_id: number };
  qr_code_identifier: string | null;
  // additional_notes?: string;
  // order_reference: string;
  // vehicle: Vehicle;
  // idsb: boolean;
  // documents: Array<Document>;
  // conversation: Conversation;
  // legacy: boolean;
  fastlane: number;
  mileage: number;
  status_reason: number;
  id: number;
  user_readable_id: string;
  status: number;
  vehicle: {
    id: number;
    vin: string;
    make: string;
    vehicle_type: string;
    manufacturer: Manufacturer;
    has_dsb: boolean;
  };
  date: string;
  submitted_at: string;
  created_at: string;
  highlighted: boolean;
}

export interface ServiceHistory {
  id: null | number;
  ip: string;
  status: number;
  status_reason: null | number;
  charge_status: number;
  consent_charge: boolean;
  systempartner_id: null | number;
  workshop_user_id: null | number;
  legacy: any;
  wordpress_order_id: null | number;
  vehicle_id: number;
  created_at: string | null;
  updated_at: string | null;
}

export type Service = {
  id: number;
  workshop_id: number;
  vehicle: Vehicle;
  vehicle_id: number;
  created_at: string;
  completed_at: string;
  documents: Array<Document>;
  service_activities: Array<ServiceActivity>;
  features: {
    vehicle_identification: string;
    service_history_request: string;
    inspection_plan: string;
    service_record: string;
    uploaded_documents: string;
    sent_documents: string;
  };
};

export interface UpdateBillingEmailForm {
  billing_email: string;
}

export interface Invoices {
  id: string;
  date: Date;
  total?: number;
  url: string;
}

export interface Distributor {
  id: number;
  name: string;
  logo_url: string;
  prepaid_plan: {
    id: number;
    slug: string;
    order: number;
    monthly_sr_quota: number;
    monthly_shr_quota: number;
    is_pay_per_use: number;
    created_at: Date;
    updated_at: Date;
    price: Price;
    charges: { [key: string]: Charge };
    yearly_savings: number;
    pre_selected: boolean;
    available_addons: SubscriptionAddon[];
  };
}

export interface DataForEndpoint {
  qr_code_identifier: string;
}

export interface ConversationMessageShow {
  id: number;
  created_at: string;
  updated_at: string;
  text: string;
  translated_text: string;
  document: Document;
  author: string;
  workshop_user_id: number;
  user_id: number;
}

export interface ConversationShow {
  id: number;
  created_at: string;
  updated_at: string;
  key: string;
  messages: ConversationMessageShow[];
}

export interface SystemPartner {
  id: number;
  name: string;
  logo_url: string;
}
