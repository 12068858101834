import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import { FONT_WEIGHTS } from '../global/Fonts';

export const Link: React.FC<LinkProps & RouterLinkProps> = (props) => {
  return (
    <ChakraLink
      color="accent"
      fontWeight={FONT_WEIGHTS.bold}
      as={RouterLink}
      {...props}
    />
  );
};

export interface IExternalLinkProps extends LinkProps {
  children?: React.ReactNode;
}
export const ExternalLink: React.FC<IExternalLinkProps> = (props) => {
  return (
    <ChakraLink color="accent" isExternal fontWeight={FONT_WEIGHTS.bold} {...props} />
  );
};

export default Link;
