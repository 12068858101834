import { Button, Flex, Icon, Stack, Text, Tooltip } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCar } from 'react-icons/all';
import { MdArrowDownward } from 'react-icons/md';

import { Vehicle } from '../../api/types';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { DataTable } from '../../components/Table/DataTable';
import { useUserContext } from '../../context/UserContextProvider';
import useAddonsQuery from '../../hooks/queries/useAddonsQuery';
import useIdentifiedVehiclesQuery from '../../hooks/queries/useIdentifiedVehiclesQuery';
import { ContentCard } from '../../layout/ContentCard';
import Link from '../../layout/Link';

const IdentifiedVehiclesCard = () => {
  const minSizeOfVehicleModelName = 30;
  const userContext = useUserContext();
  const { t } = useTranslation();
  const addonsQuery = useAddonsQuery();
  const addonsData = addonsQuery.data?.data || [];
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const identifiedVehiclesQuery = useIdentifiedVehiclesQuery(page, itemsPerPage);
  const identifiedVehiclesData = identifiedVehiclesQuery.data?.data || [];

  const currentPage = identifiedVehiclesQuery.data?.meta?.current_page
    ? identifiedVehiclesQuery.data?.meta?.current_page - 1
    : 1;

  useEffect(() => {
    setPage(1);
  }, [itemsPerPage]);
  const columns = React.useMemo(
    () => [
      {
        Header: t('forms:vin.label'),
        accessor: (row: Vehicle) => (
          <Link className="pii" to={`/app/w/vehicle?vin=${row.vin}`}>
            {row.vin}
          </Link>
        ),
      },
      {
        Header: t('forms:make.label'),
        accessor: (row: Vehicle) => <>{row.make}</>,
      },
      {
        Header: t('forms:model.label'),
        accessor: (row: Vehicle) => {
          const vehicleTypeName = row?.vehicle_type;
          return (
            vehicleTypeName 
              ? (
                vehicleTypeName.length < minSizeOfVehicleModelName 
                  ? vehicleTypeName 
                  : <Tooltip label={vehicleTypeName}>
                      <Text>{vehicleTypeName.slice(0, minSizeOfVehicleModelName)}...</Text>
                    </Tooltip>
                ) 
              : '-'
          );
        },
      },
      {
        id: 'button',
        accessor: (row: { vin: string; index: number }) => (
          <Flex>
            <Button
              leftIcon={<MdArrowDownward />}
              ml={'auto'}
              as={Link}
              to={`/app/w/vehicle?vin=${row.vin}`}
              variant="primary"
              size="sm"
              data-test-id={'open-' + row.index}
              _hover={{ textDecoration: 'none' }}
            >
              {t('common:details')}
            </Button>
          </Flex>
        ),
      },
    ],
    [identifiedVehiclesData, userContext.workshop, addonsData],
  );
  if (identifiedVehiclesQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <ContentCard
      isLoading={addonsQuery.isFetching && identifiedVehiclesQuery?.isFetching}
      icon={<Icon as={BiCar} boxSize={12} m={1} />}
      minH="fit-content"
      header={t('components:latest_vehicles_card.header')}
      contentDescription={t('components:latest_vehicles_card.description')}
      data-test-id="identified-vehicles-card"
      className={'tour-latest-vehicles-card'}
    >
      <Stack direction={['column', 'row']} w={'100%'}>
        <Stack direction="column" w={'100%'}>
          {identifiedVehiclesData.length === 0 ? (
            <Text>{t('components:latest_vehicles_card.no_content')}</Text>
          ) : (
            <DataTable
              data-test-id="identified-vehicles-table"
              hasFilters={false}
              columns={columns}
              loading={addonsQuery.isFetching}
              data={identifiedVehiclesData}
              // @ts-ignore
              total={identifiedVehiclesQuery.data?.meta?.last_page || 1}
              currentPage={currentPage}
              setPage={setPage}
              perPage={itemsPerPage}
              setPerPage={setItemsPerPage}
              isMoreThenOnePage={
                // @ts-ignore
                Boolean(identifiedVehiclesQuery.data?.meta?.next_page_url) ||
                currentPage > 0
              }
            />
          )}
        </Stack>
      </Stack>
    </ContentCard>
  );
};

export default IdentifiedVehiclesCard;
