export function formatCurrency(currency: number): string {
  return ` ${(currency / 100).toLocaleString('de-AT', {
    style: 'currency',
    currency: 'EUR',
  })}`;
}

export function formatMultiCurrency(value: number, currency: string | undefined) {
  if (currency === 'EUR') {
    return ` ${(value / 100).toLocaleString('de-AT', {
      style: 'currency',
      currency: 'EUR',
    })}`;
  } else if (currency === 'GBP') {
    return ` ${(value / 100).toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
    })}`;
  } else if (currency === 'CZK') {
    return ` ${(value / 100).toLocaleString('cs-CZ', {
      style: 'currency',
      currency: 'CZK',
    })}`;
  }
}

export function getFormatedCurrency(currency: number): {
  decimal: number;
  float: number;
} {
  return {
    decimal: Math.trunc(currency / 100),
    float: Math.round((currency / 100 - Math.trunc(currency / 100)) * 100),
  };
}

export function formatMultiCurrencyIntl(
  value: number,
  currency: string | undefined,
): string {
  if (currency === 'EUR') {
    return `${new Intl.NumberFormat('de-AT', {
      style: 'currency',
      currency: 'EUR',
    }).format(value / 100)}`;
  } else if (currency === 'GBP') {
    return `${new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    }).format(value / 100)}`;
  } else if (currency === 'CZK') {
    return `${new Intl.NumberFormat('cs-Cz', {
      style: 'currency',
      currency: 'CZK',
    }).format(value / 100)}`;
  }
}
