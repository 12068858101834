import {
  Button,
  Center,
  Grid,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Card } from '../Card';
import { PublicPageLayout } from '../PublicPageLayout';

function ErrorFallback({
  resetError,
}: {
  error: any;
  componentStack: any;
  resetError: any;
}) {
  const { t } = useTranslation();
  return (
    <PublicPageLayout>
      <Grid w="full" h="full" placeItems="center" data-test-id="error-boundary">
        <Card p={{ base: 2, lg: 20 }} textAlign="center">
          <Stack spacing={8}>
            <Heading as="h2" size="md" p={2} textAlign="center">
              {t('errors:boundary.title')}
            </Heading>
            <Text size={'sm'}>
              <Trans i18nKey="errors:boundary.description" />
            </Text>
            <Center mb={8}>
              <Image src="/images/accident.svg" alt="Error Gif" w={'50%'} h={'auto'} />
            </Center>
            <Text size={'sm'}>
              <Trans i18nKey="errors:boundary.cta" />
            </Text>
            <HStack alignSelf={'center'}>
              <Button variant={'primary'} onClick={resetError}>
                {t('errors:boundary.back')}
              </Button>
              <Button variant={'outline'} as={'a'} href={'/app/logout'}>
                {t('errors:boundary.logout')}
              </Button>
            </HStack>
          </Stack>
        </Card>
      </Grid>
    </PublicPageLayout>
  );
}

export const GlobalErrorBoundary = (props: any) => {
  return (
    <ErrorBoundary
      fallback={(errorProps) => <ErrorFallback {...errorProps} />}
      {...props}
    />
  );
};

export default GlobalErrorBoundary;
