import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import useVehicleQuery from 'hooks/queries/useVehicleQuery';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import config from '../../../config';
import { useAxios } from '../../../context/AxiosContextProvider';

async function create(axios: AxiosInstance, inspectionPlandId: string): Promise<object> {
  return await axios.post(`${config.apiBaseUrl}/workshop/service-records/from-inspection-plan`, { inspection_plan_id: parseInt(inspectionPlandId) });
}

export default function useCreateServiceRecordFromInspectionPlan(vin: string, inspectionPlandId: string) {
  const axios = useAxios();
  const t = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(() => create(axios, inspectionPlandId), {
    onSuccess: () => {
      queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
    },
    onError: (err: any) => {
      const { status, message } = err.response.data;
      toast({
        title: message,
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
    },
  });
}
